import merge from "deepmerge";
import prod from "./production";
import dev from "./development";

const env = process.env.REACT_APP_ENV;
let conf = dev;
switch (env) {
  case "production":
    conf = { ...merge(conf, prod) };
    break;
  case "dev":
  default:
    conf = { ...merge(conf, dev) };
    break;
}

export default conf;