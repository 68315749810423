import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from '@mui/material/Tooltip';
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

// Material Dashboard 2 React components
import MDBox from "../../../MDBox";
import MDTypography from "../../../MDTypography";
// import MDInput from "../../../MDInput";

import NotificationItem from "../../../NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
} from "./styles";
import { MINIMUM_HIDE_SIDENAV } from "../../../../constants/appSettings.constant";
import { hideMiniSidenav } from "../../../../reducers/app";
import { logout } from "../../../../reducers/auth";
import { AUTHENTICATED_ROUTE, AUTHENTICATED_OTHER_ROUTE, UNAUTHENTICATED_ROUTE } from "../../../../constants/routes";
import { hasAccess } from "../../../../helpers/access";

function DashboardNavbar() {
  let dispatch = useDispatch();
  const navigate = useNavigate();

  const route = useLocation().pathname;
  const user = useSelector(state => state.auth.user)
  const allRoutes = {
    ...AUTHENTICATED_ROUTE, ...AUTHENTICATED_OTHER_ROUTE, ...UNAUTHENTICATED_ROUTE
  }
  const findActiveRoute = Object.values(allRoutes).find(a => {
    const basicRoute = a.route.split("/")[1]
    return a.route === route || (route.includes(basicRoute) && route.split("/").length === a.route.split("/").length)
  })
  const [openMenu, setOpenMenu] = useState(false);

  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        icon={<Icon><PersonOutlineIcon /></Icon>}
        title="My Profile"
        onClick={() => {
          handleCloseMenu()
          navigate(AUTHENTICATED_OTHER_ROUTE.profile.route)
        }}
      />
      <NotificationItem
        icon={<Icon><LogoutIcon /></Icon>}
        title="Sign Out"
        onClick={() => {
          handleCloseMenu()
          dispatch(logout())
          navigate(UNAUTHENTICATED_ROUTE.signIn.route)
        }}
      />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { text }, functions: { rgba } }) => ({
    color: () => {
      return rgba(text.main, 0.6);
    },
  });

  return (
    <AppBar
      position="absolute"
      color="inherit"
      sx={(theme) => navbar(theme)}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme)}>
          <MDBox
            display={window.innerWidth < MINIMUM_HIDE_SIDENAV ? "inline-block" : "none"}
            lineHeight={0}
            py={1.5}
            pl={1.5}
            color="inherit"
            sx={{ cursor: "pointer" }}
            onClick={() => dispatch(hideMiniSidenav(false))}
          >
            <Icon fontSize="default"><MenuIcon /></Icon>
          </MDBox>
          {findActiveRoute && findActiveRoute.name && (
            <MDTypography
              variant="h6"
              fontWeight="bold"
              textTransform="capitalize"
              color="dark"
              noWrap
            >
              {findActiveRoute.name}
            </MDTypography>
          )}
        </MDBox>
        <MDBox sx={(theme) => navbarRow(theme)}>
          {/* <MDBox pr={1}>
            <MDInput label="Search here" />
          </MDBox> */}
          <MDBox color={"inherit"}>
            {
              (AUTHENTICATED_OTHER_ROUTE.shippers.userRoleAbilityKeys && AUTHENTICATED_OTHER_ROUTE.shippers.userRoleAbilityKeys.length > 0 && hasAccess(AUTHENTICATED_OTHER_ROUTE.shippers.userRoleAbilityKeys, user)) && (
                <Tooltip title="Our Shippers">
                  <IconButton
                    size="small"
                    disableRipple
                    color="inherit"
                    sx={navbarIconButton}
                    variant="contained"
                    onClick={() => navigate(AUTHENTICATED_OTHER_ROUTE.shippers.route)}
                  >
                    <Icon sx={iconsStyle}>
                      <LocalShippingIcon />
                    </Icon>
                  </IconButton>
                </Tooltip>
              )
            }
            <IconButton
              size="small"
              disableRipple
              color="inherit"
              sx={navbarIconButton}
              aria-controls="profile-menu"
              aria-haspopup="true"
              variant="contained"
              onClick={handleOpenMenu}
            >
              <Icon sx={iconsStyle}>
                <AccountCircleIcon />
              </Icon>
            </IconButton>
            {renderMenu()}
          </MDBox>
        </MDBox>
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
};

export default DashboardNavbar;
