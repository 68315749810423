export const STATUS = {
  new: {
    key: 'new',
    value: 'new',
    text: 'New',
    permission: 'update',
    color: 'info',
  },
  paid: {
    key: 'paid',
    value: 'paid',
    text: 'Paid',
    permission: 'pay',
    color: 'success',
  },
  voided: {
    key: 'voided',
    value: 'voided',
    text: 'Voided',
    permission: 'void',
    color: 'error',
  },
}

export const FINAL_STATUSES = [
  STATUS.paid.value, STATUS.voided.value
]
export const STATUS_ALLOW_DELETE = [
  STATUS.new.value
]
