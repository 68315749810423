import axios from "axios";
import moment from "moment";
import FileSaver from "file-saver";
import SessionStoreService from "../services/session-store-service";

export function compareSort(a, b, sortType) {
  if (a === null) a = 0;
  if (b === null) b = 0;
  if (a < b) {
    if (sortType === "desc") {
      return 1;
    }
    return -1;
  }
  if (a > b) {
    if (sortType === "desc") {
      return -1;
    }
    return 1;
  }
  return 0;
}

export function displayDateFromUnix(
  unixtime,
  displayDate = true,
  displayTime = true,
  displaySec = false
) {
  if (unixtime === 0) return "";
  var dateConverted = new Date(unixtime * 1000);
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var year = dateConverted.getFullYear();
  var month = months[dateConverted.getMonth()];
  var date = dateConverted.getDate();
  var hour = dateConverted.getHours();
  if (hour < 10) hour = "0" + hour;
  var min = dateConverted.getMinutes();
  if (min < 10) min = "0" + min;
  var sec = dateConverted.getSeconds();
  if (sec < 10) sec = "0" + sec;
  var time = "";
  if (displayDate) time = time + date + " " + month + " " + year;
  if (displayTime) {
    if (time !== "") time = time + " ";
    time = time + hour + ":" + min;
    if (displaySec) time = time + ":" + sec;
  }
  return time;
}

export function formatDateTime(date, displayDate = true, displayTime = true, displayYear = true) {
  if (!date) {
    return date;
  }
  let returnDate = "";
  if (displayDate) {
    returnDate += moment(date).format(displayYear ? "DD MMM YYYY" : 'MMM DD')
  }
  if (displayTime) {
    if (returnDate !== "") returnDate += " "
    returnDate += moment(date).format("HH:mm")
  }
  return returnDate;
}

export function isValidURL(string) {
  var res = string.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
  );
  if (res == null) {
    return false;
  } else {
    return true;
  }
}

export function generateRandomString(stringLength = 8) {
  var text = "";
  var possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (var i = 0; i < stringLength; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
}

export function capitalizeFirstLetter(str) {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const formatter = {
  currency(value, currency, options = {}) {
    if (!value) return value;
    if (!currency) currency = "$";
    const normalisedValue = this.normalised(value, options);
    return currency + " " + normalisedValue;
  },

  fullCurrency(
    value,
    currency = null,
    totalDecimal = 2,
    fillEmptyDecimal = false
  ) {
    if (currency === null) currency = "$";
    if (value === null) return null;
    if (currency !== "" && !currency.endsWith(" ")) {
      currency = currency + " ";
    }
    if (typeof value !== "undefined" && Number(value) === 0) {
      return currency + value;
    }
    if (!value) return value;
    const decRound = Math.pow(10, totalDecimal);
    value = Math.round(value * decRound) / decRound;
    let normalisedValue = this.numberToCommaString(value);
    if (normalisedValue.indexOf(".") > -1) {
      let numSplit = normalisedValue.split(".");
      if (numSplit.length === 2) {
        if (numSplit[1].length < totalDecimal && fillEmptyDecimal) {
          for (let i = numSplit[1].length; i < totalDecimal; i++) {
            numSplit[1] = numSplit[1] + "0";
          }
        }
        if (Number(numSplit[1]) === 0) {
          normalisedValue = numSplit[0];
        } else {
          normalisedValue = numSplit.join(".");
        }
      }
    } else if (fillEmptyDecimal && totalDecimal > 0) {
      normalisedValue += ".";
      for (let i = 0; i < totalDecimal; i++) {
        normalisedValue = normalisedValue + "0";
      }
    }
    return currency + " " + normalisedValue;
  },

  normalised(value, options = {}) {
    if (!value) return value;

    if (value.toString().indexOf(".") > -1) {
      value = Math.round(value);
    }

    const { digitToDisplay = 0 } = options;
    const numDigits = value.toString().length - digitToDisplay;
    const suffixes = ["", "K", "M", "B", "T"];
    let suffixIndex = Math.floor((numDigits - 1) / 3);
    if (suffixIndex > suffixes.length - 1) {
      suffixIndex = suffixes.length - 1;
    } else if (suffixIndex < 0) {
      suffixIndex = 0;
    }

    let normalisedValue = value / Math.pow(1000, suffixIndex);
    normalisedValue = Math.round(normalisedValue * 100) / 100;
    return (
      this.numberToCommaString(normalisedValue) + " " + suffixes[suffixIndex]
    );
  },

  numberToCommaString(number) {
    if (!number) return number;
    number = number.toString();
    if (number.indexOf(".") > -1) {
      let numSplit = number.split(".");
      let ret = number;
      if (numSplit.length > 0) {
        ret = numSplit[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        ret += ".";
        numSplit.splice(0, 1);
        ret += numSplit.join("");
      }
      return ret;
    }
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },

  numberToFrequency(number) {
    if (!Number.isInteger(number)) return number;
    let result = number;
    switch (number) {
      case 1:
        result = "once";
        break;
      case 2:
        result = "twice";
        break;
      default:
        result = number + " times";
        break;
    }
    return result;
  },
};

function getAuthorizationHeader() {
  let auth = SessionStoreService.getAuthorization();
  let token = null;
  if (typeof auth === "string") {
    token = JSON.parse(auth);
  } else {
    token = auth;
  }
  if (token && token.accessToken) {
    let seconds = new Date().getTime() / 1000;
    if (token.expiresAt <= seconds) {
      return Promise.resolve(false);
      // return new Promise((resolve, reject) => {
      //   AuthManager.reauthenticate().then(user => {
      //     if (user) {
      //       resolve({
      //         'Authorization': 'Bearer ' + user.accessToken
      //       })
      //     }
      //     resolve(false)
      //   })
      // })
    } else {
      return Promise.resolve({
        Authorization: "Bearer " + token.accessToken,
      });
    }
  } else {
    return Promise.resolve({});
  }
}

export function getAxios(url, params = {}, headers = {}, additionalConfig = {}) {
  headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...headers,
  };
  return new Promise((resolve, reject) => {
    getAuthorizationHeader().then((authHeader) => {
      if (!authHeader) {
        resolve({ isSuccess: false, status: 401, data: [] });
        return;
      }
      headers = {
        ...authHeader,
        ...headers,
      };
      axios
        .get(url, { headers, params, ...additionalConfig })
        .then((response) => {
          resolve({
            isSuccess: true,
            status: response.status,
            data: response.data,
            headers: response.headers,
          });
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.status &&
            err.response.status === 401
          ) {
            reject({
              isSuccess: false,
              status: err.response.status,
              data: err.response.data,
            });
          } else if (err && err.response) {
            reject({
              isSuccess: false,
              status: err.response.status,
              data: err.response.data,
            });
          } else {
            let responseData = {
              errors: [],
              fields: null,
              message: "Unknown Error",
              status: "error",
            };
            reject({
              isSuccess: false,
              status: 400,
              data: responseData,
            });
          }
        });
    });
  });
}

export function postAxios(url, data = null, method = "post", headers = {}, additionalConfig = {}) {
  headers = {
    ...headers,
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  return new Promise((resolve, reject) => {
    getAuthorizationHeader().then((authHeader) => {
      if (!authHeader) {
        resolve({ isSuccess: false, status: 401, data: [] });
        return;
      }
      headers = {
        ...headers,
        ...authHeader,
      };
      let params = { method, headers, url, ...additionalConfig };
      if (data) {
        params = { ...params, data }
      }
      axios(params)
        .then((response) => {
          resolve({
            isSuccess: true,
            status: response.status,
            data: response.data,
            headers: response.headers,
          });
        })
        .catch((err) => {
          // message string, statusCode string, erros object
          // handle all error message to have a standard format to be passed to UI. Currently, the API returns different result for each error
          if (
            err &&
            err.response &&
            err.response.status &&
            err.response.status === 401
          ) {
            reject({
              isSuccess: false,
              status: err.response.status,
              data: err.response.data,
            });
          } else if (
            err &&
            err.response &&
            err.response.status &&
            (err.response.status === 422 || err.response.status === 400)
          ) {
            reject({
              isSuccess: false,
              status: err.response.status,
              data: err.response.data,
            });
          } else if (err && err.response) {
            reject({
              isSuccess: false,
              status: err.response.status,
              data: err.response.data,
            });
          } else {
            let responseData = {
              errors: [],
              fields: null,
              message: "Unknown Error",
              status: "error",
            };
            reject({
              isSuccess: false,
              status: 400,
              data: responseData,
            });
          }
        });
    });
  });
}

export function serializeQueryString(obj) {
  if (!obj) return "";
  return Object.keys(obj)
    .reduce(function (a, k) {
      if (Array.isArray(obj[k])) {
        for (var val of obj[k]) {
          a.push(k + "[]=" + encodeURIComponent(val));
        }
      } else if (obj[k] !== null && obj[k] !== undefined) {
        a.push(k + "=" + encodeURIComponent(obj[k]));
      }
      return a;
    }, [])
    .join("&");
}

export function validateEmail(email) {
  // eslint-disable-next-line
  const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
  return expression.test(String(email).toLowerCase());
}

export function stripHtml(html) {
  // Create a new div element
  var temporalDivElement = document.createElement("div");
  // Set the HTML content with the providen
  temporalDivElement.innerHTML = html;
  // Retrieve the text property of the element (cross-browser support)
  return temporalDivElement.textContent || temporalDivElement.innerText || "";
}

export function compareDate(compare, start, end) {
  const compareDate = moment(compare);
  const startDate = moment(start);
  const endDate = moment(end);
  return compareDate.isBetween(startDate, endDate, "days", "[]");
}

export function getUniqueArray(arr) {
  return arr.reduce((acc, current) => {
    const findIndex = acc.findIndex((item) => item.id === current.id);
    if (findIndex === -1) {
      return acc.concat([current]);
    } else {
      if (typeof current === "object") {
        acc[findIndex] = {
          ...acc[findIndex],
          ...current,
        };
      }
      return acc;
    }
  }, []);
}

export function compareObject(obj1, obj2) {
  // Loop through properties in object 1
  for (let p in obj1) {
    // Check property exists on both objects
    if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) return false;

    switch (typeof obj1[p]) {
      // Deep compare objects
      case "object":
        if (!compareObject(obj1[p], obj2[p])) return false;
        break;
      // Compare function code
      case "function":
        if (
          typeof obj2[p] === "undefined" ||
          (p !== "compare" && obj1[p].toString() !== obj2[p].toString())
        )
          return false;
        break;
      // Compare values
      default:
        if (obj1[p] !== obj2[p]) return false;
    }
  }

  // Check object 2 for any extra properties
  for (let p in obj2) {
    if (typeof obj1[p] === "undefined") return false;
  }
  return true;
}

export function getFormData(formData, data, previousKey = null) {
  if (data instanceof Object) {
    Object.keys(data).forEach((key) => {
      const value = data[key];
      if (
        value instanceof Object &&
        !Array.isArray(value) &&
        !(value instanceof File)
      ) {
        return getFormData(formData, value, key);
      }
      if (previousKey) {
        key = `${previousKey}[${key}]`;
      }
      if (Array.isArray(value)) {
        value.forEach((val) => {
          formData.append(`${key}[]`, val);
        });
      } else {
        if (value !== null) {
          formData.append(key, value);
        }
      }
    });
  }
  return formData;
}

export function shuffleArray(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

export function htmlEntityDecode(message) {
  var element = document.createElement("div");
  element.innerHTML = message;
  return element.innerHTML;
}

export function hideEmail(email) {
  return email.replace(/(.{2})(.*)(?=@)/, function (gp1, gp2, gp3) {
    for (let i = 0; i < gp3.length; i++) {
      gp2 += "*";
    }
    return gp2;
  });
}

export function generateGreetings() {
  const currentHour = moment().format("HH");

  if (currentHour >= 3 && currentHour < 12) {
    return "Good Morning";
  } else if (currentHour >= 12 && currentHour < 15) {
    return "Good Afternoon";
  } else if (currentHour >= 15 && currentHour < 20) {
    return "Good Evening";
  } else if (currentHour >= 20 && currentHour < 3) {
    return "Good Night";
  } else {
    return "Hello"
  }
}

export function displayName({ firstName, lastName, companyName }) {
  if (companyName) return companyName.trim()
  let name = ""
  if (firstName) name += firstName
  if (lastName) name += " " + lastName
  return name.trim()
}

export function displayDimension({ width, height, length }, unit = "cm") {
  return `${formatter.numberToCommaString(length)} x ${formatter.numberToCommaString(width)} x ${formatter.numberToCommaString(height)} ${unit}`
}

export function downloadBlobFile(body, contentType, name = 'file.txt') {
  const blob = new Blob([body], { type: contentType })
  FileSaver.saveAs(blob, name);
}

export function falsyButNotZero(value) {
  return !value && value !== 0
}

export function trueOrZero(value) {
  return value || value === 0
}

export const checkOverflow = (el) => {
  const curOverflow = el.style.overflow

  if (!curOverflow || curOverflow === 'visible') {
    el.style.overflow = 'hidden'
  }

  const isOverflowing =
    el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight

  el.style.overflow = curOverflow

  return isOverflowing
}
