// Material Dashboard 2 React Base Styles
import colors from "../../base/colors";

// Material Dashboard 2 React Helper Function

const { white } = colors;

const chip = {
  styleOverrides: {
    root: {
      "&.MuiChip-filledSuccess": {
        color: white.main,
      },
      "&.MuiChip-filledWarning": {
        color: white.main,
      }
    },
  },
};

export default chip;
