import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import MDInputTimeout from "../MDInputTimeout";
import MDBox from "../MDBox";
import MDAutocomplete from "../MDAutocomplete";
import MDTypography from "../MDTypography";
import MDButton from "../MDButton";
import { resetDropdown, getAll as getAllItem, getByIdDropdown as getItemById } from '../../reducers/item';
import ItemForm from '../../pages/Item/ItemForm';
import { hasAccess } from '../../helpers/access';

function ItemSelector({ userId, label, name, onChange, value, allowAdd, ...rest }) {
  let dispatch = useDispatch()

  const data = useSelector(state => state.item.dropdown)
  const [inputValue, setInputValue] = useState('')
  const [dataValue, setDataValue] = useState(null)
  const [createModal, setCreateModal] = useState(false)

  const user = useSelector(state => state.auth.user)
  if (allowAdd) {
    allowAdd = hasAccess(["item.create"], user)
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const item = await getItemById(value)
        setDataValue(item)
      } catch (e) {
        setDataValue(null)
      }
    }
    if (value) fetchData();
  }, [value, dispatch])
  useEffect(() => {
    let params = {
      q: inputValue,
      status: "1",
      page: 1,
      limit: 100,
    }
    if (userId) {
      params = {
        ...params,
        userId,
      }
    }
    dispatch(resetDropdown())
    dispatch(getAllItem(params, true))
  }, [inputValue, userId, dispatch]);

  const handleChange = (e, result) => {
    setDataValue(result)
    if (typeof onChange !== 'undefined') {
      onChange({
        target: {
          name,
          value: result?.id,
          data: result,
        }
      })
    }
  }

  const handleCreate = (newData) => {
    setDataValue(newData)
    if (typeof onChange !== 'undefined') {
      onChange({
        target: {
          name,
          value: newData?.id,
          data: newData,
        }
      })
    }
  }

  let options = data.data
  if (dataValue && dataValue.id) {
    options = options.filter(o => o.id !== dataValue.id)
    options = [dataValue, ...options]
  }
  return (
    <React.Fragment>
      <MDAutocomplete
        options={options}
        filterOptions={(x) => x}
        autoComplete
        includeInputInList
        filterSelectedOptions
        autoHighlight
        getOptionLabel={(option) => `${option.name} (${option.merchantSku})`}
        renderOption={(props, option) => (
          <MDBox component="li" {...props}>
            {`${option.name} (${option.merchantSku})`}
          </MDBox>
        )}
        value={dataValue}
        onChange={handleChange}
        isOptionEqualToValue={(option, selected) => {
          return option.id === selected.id
        }}
        renderInput={(params) => (
          <MDInputTimeout
            {...params}
            label={label}
            name={name}
            fullWidth
            disabled={!!rest.disabled}
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
            inputProps={{
              ...params.inputProps,
            }}
          />
        )}
        {...rest}
      />
      {allowAdd && (
        <div style={{ textAlign: 'right' }}>
          <MDTypography variant="caption" fontWeight="medium" color="dark">
            Item not found? <MDButton variant="text" size="small" color="primary" onClick={() => setCreateModal(true)}>
              Create one
            </MDButton>
          </MDTypography>
        </div>
      )}
      {createModal && (
        <ItemForm hideClient data={{ userId }} onClose={() => setCreateModal(false)} onCreate={handleCreate} />
      )}
    </React.Fragment>
  );
}

ItemSelector.defaultProps = {
  allowAdd: false
}

ItemSelector.propTypes = {
  userId: PropTypes.number,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
  allowAdd: PropTypes.bool,
}

export default ItemSelector