import { API_URL } from "../constants/api";
import { getAxios, postAxios } from "../helpers";

class ReviewPlanItemService {

  getAll(reviewPlanId, params = null) {
    let url = API_URL.REVIEW_PLAN_ITEM.GET_ALL.replace(":reviewPlanId", reviewPlanId);
    return getAxios(url, params);
  }

  getById(reviewPlanId, id) {
    const url = API_URL.REVIEW_PLAN_ITEM.GET_BY_ID.replace(":reviewPlanId", reviewPlanId).replace(":id", id);
    return getAxios(url);
  }

  create(reviewPlanId, params) {
    const url = API_URL.REVIEW_PLAN_ITEM.CREATE.replace(":reviewPlanId", reviewPlanId);
    return postAxios(url, params);
  }

  updateById(reviewPlanId, id, params) {
    const url = API_URL.REVIEW_PLAN_ITEM.UPDATE_BY_ID.replace(":reviewPlanId", reviewPlanId).replace(":id", id);
    return postAxios(url, params, "put");
  }

  deleteById(reviewPlanId, id) {
    const url = API_URL.REVIEW_PLAN_ITEM.DELETE_BY_ID.replace(":reviewPlanId", reviewPlanId).replace(":id", id);
    return postAxios(url, {}, "delete");
  }

}

export default new ReviewPlanItemService();
