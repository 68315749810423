import { Upload } from "@mui/icons-material";
import AppAvatar from "components/AppAvatar/Avatar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import React, { useRef, useState } from "react";
import fileUploadService from "services/file-upload-service";

function AppUpdateAvatar({ allowEdit = false, url, name, onChange }) {
  const fileInputField = useRef(null);
  const [isUploading, setIsUploading]= useState(false);

  const handleNewFileUpload = async (e) => {
    const { files: newFiles } = e.target;
    if (newFiles.length) {
      setIsUploading(true);
      const file = newFiles[0];
      const uploadedUrl = await fileUploadService.upload(file, 'avatar', file.name);
      onChange(uploadedUrl);
      setIsUploading(false);
    }
  };

  return <div className="app-avatar__holder">
    <AppAvatar name={name} url={url} />
    {
      allowEdit && <MDButton
        onClick={() => {
          fileInputField.current.click();
        }}
        size="small"
        disabled={isUploading}
        color="secondary"
        variant="gradient">
        {isUploading ? 'Uploading...' : 'Change Avatar'}
      </MDButton>
    }
    <input
      type="file"
      ref={fileInputField}
      title=""
      value=""
      hidden
      accept="image/png, image/jpeg"
      onChange={handleNewFileUpload}
    />
  </div>
}

export default AppUpdateAvatar