const config = {
  site: {
    domain: "localhost",
    URL: "http://localhost:3000", // dont give "/" at the end
  },

  api: {
    ENDPOINT: "http://localhost:8081/api/v1/",
    HEADER_CLIENT_KEY: "",
    HEADER_CLIENT_SECRET: "",
    CONTENT_ENDPOINT: "",
  },
};

export default config;