// @ts-nocheck
import React, { useState } from "react";
// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import MDInput from "../../components/MDInput";
import MDInputPassword from "../../components/MDInputPassword";
import MDAlert from "../../components/MDAlert";
import MDSnackbar from "../../components/MDSnackbar";

import { UNAUTHENTICATED_ROUTE } from "../../constants/routes";

import { validateEmail } from "../../helpers";
import { register, verify } from "../../reducers/auth";

function SignUp() {
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [successMessage, setSuccessMessage] = useState(null)
  const [verifyMessage, setVerifyMessage] = useState(null)
  const [errorOtp, setErrorOtp] = useState(null)
  const [otp, setOtp] = useState("")
  const [input, setInput] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  })
  const [displayOtp, setDisplayOtp] = useState(false)

  const handleChangeInput = (e) => {
    setErrorMessage(null)
    setInput(prev => {
      return {
        ...prev,
        [e.target.name]: e.target.value
      }
    })
  }
  const handleSubmit = async () => {
    setErrorMessage(null)
    if (input.email === "" || !validateEmail(input.email)) {
      setErrorMessage("please input a valid email")
      return
    }
    if (input.password !== input.confirmPassword) {
      setErrorMessage("confirm password does not match with the password")
      return
    }
    try {
      setLoading(true)
      const resp = await register(input)
      setSuccessMessage(resp.data.message)
      setDisplayOtp(true)
      setLoading(false)
    } catch (e) {
      setErrorMessage(e.data.message)
      setLoading(false)
    }
  }
  const handleVerify = async () => {
    setErrorOtp(false)
    setVerifyMessage(null)
    if (otp.trim() === "") {
      setErrorOtp(true)
      return
    }
    try {
      setLoading(true)
      const resp = await verify(input.email, otp)
      setVerifyMessage(resp.data.message)
      setLoading(false)
    } catch (e) {
      setErrorOtp(e.data.message)
      setLoading(false)
    }
  }

  return (
    <>
      {errorMessage && (
        <MDSnackbar
          color="error"
          title={errorMessage}
          open
          close={() => setErrorMessage(null)}
        />
      )}

      <Card>
        <MDBox pt={4} pb={3} px={3}>
          <MDTypography variant="h5" fontWeight="bold" color="dark" mt={1} mb={2}>
            Tell us your information to join our platform
          </MDTypography>
          <MDBox component="form" role="form" autoComplete="off" noValidate>
            <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
              <Grid item md={12} lg={6} xl={6} sm={12} mb={2}>
                <MDInput required type="text" disabled={displayOtp || loading} label="First name" fullWidth value={input?.firstName || ""} name="firstName" onChange={handleChangeInput} />
              </Grid>
              <Grid item md={12} lg={6} xl={6} sm={12} mb={2}>
                <MDInput type="text" disabled={displayOtp || loading} label="Last name" fullWidth value={input?.lastName || ""} name="lastName" onChange={handleChangeInput} />
              </Grid>
            </Grid>
            <MDBox mb={2}>
              <MDInput required type="email" disabled={displayOtp || loading} error={input.email !== "" && !validateEmail(input.email)} label="Email" fullWidth value={input?.email || ""} name="email" onChange={handleChangeInput} />
            </MDBox>
            <MDBox mb={2}>
              <MDInputPassword required disabled={displayOtp || loading} label="Password" fullWidth value={input?.password || ""} name="password" onChange={handleChangeInput} />
            </MDBox>
            <MDBox mb={2}>
              <MDInputPassword required error={input.password !== input.confirmPassword} disabled={displayOtp || loading} label="Confirm password" fullWidth value={input?.confirmPassword || ""} name="confirmPassword" onChange={handleChangeInput} />
              {
                input.password !== input.confirmPassword && (
                  <MDTypography variant="caption" fontWeight="medium" color="error">
                    confirm password does not match with the password
                  </MDTypography>
                )
              }
            </MDBox>
            {successMessage && (
              <MDAlert color="success">
                <MDTypography variant="body2" color="white">
                  {successMessage}
                </MDTypography>
              </MDAlert>
            )}
            {displayOtp && (
              <MDBox mb={2}>
                <MDInput error={errorOtp !== null} disabled={verifyMessage || loading} type="text" label="Enter your OTP" fullWidth value={otp} onChange={(e) => {
                  setOtp(e.target.value)
                  setErrorOtp(null)
                }} />
                {errorOtp && (
                  <MDTypography variant="caption" fontWeight="medium" color="error">
                    {errorOtp}
                  </MDTypography>
                )}
              </MDBox>
            )}
            {verifyMessage ? (
              <>
                <MDBox mb={1}>
                  <MDAlert color="success">
                    <MDTypography variant="body2" color="white">
                      {verifyMessage}
                    </MDTypography>
                  </MDAlert>
                </MDBox>
                <MDBox mt={3} mb={1} textAlign="center">
                  <MDTypography variant="button" color="text">
                    <MDTypography
                      component={Link}
                      to={UNAUTHENTICATED_ROUTE.signIn.route}
                      variant="button"
                      color="info"
                      fontWeight="medium"
                      textGradient
                    >
                      Sign in
                    </MDTypography>
                  </MDTypography>
                </MDBox>
              </>
            ) : (
              <>
                <MDBox mb={1}>
                  {
                    displayOtp ? (
                      <MDButton variant="gradient" disabled={loading} color="primary" fullWidth onClick={handleVerify} >
                        verify my email
                      </MDButton>
                    ) : (
                      <MDButton variant="gradient" disabled={loading} color="primary" fullWidth onClick={handleSubmit}>
                        sign up
                      </MDButton>
                    )
                  }
                </MDBox>
                <MDBox mt={3} mb={1} textAlign="center">
                  <MDTypography variant="button" color="text">
                    Already have an account?{" "}
                    <MDTypography
                      component={Link}
                      to={UNAUTHENTICATED_ROUTE.signIn.route}
                      variant="button"
                      color="info"
                      fontWeight="medium"
                      textGradient
                    >
                      Sign in
                    </MDTypography>
                  </MDTypography>
                </MDBox>
              </>
            )}
          </MDBox>
        </MDBox>
      </Card>
    </>
  );
}

SignUp.propTypes = {
}

export default SignUp