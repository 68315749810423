// @ts-nocheck
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import parsePhoneNumber from 'libphonenumber-js'

// react-router-dom components

// @mui material components
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Chip from '@mui/material/Chip';
import AddIcon from '@mui/icons-material/Add';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UnpublishedIcon from '@mui/icons-material/Unpublished';

// Material Dashboard 2 React components
import MDTablePagination from "../../components/MDTablePagination";
import MDInputTimeout from "../../components/MDInputTimeout";
import MDInput from "../../components/MDInput";
import MDBox from "../../components/MDBox";
import MDFab from "../../components/MDFab";
import MDTypography from "../../components/MDTypography";
import MDActionMenu from "../../components/MDActionMenu";
import MDAlertDialog from "../../components/MDAlertDialog";
import MDDisplayAddress from "../../components/MDDisplayAddress";

import { deleteById as deleteClientById, getAll as getAllClient } from "../../reducers/client";
import { hasAccess } from "../../helpers/access";
import ClientForm from "./ClientForm";
import { formatDateTime, displayName } from "../../helpers";
import ClientName from "components/ClientName/ClientName";
import { DownloadTwoTone, Inventory, Storage } from "@mui/icons-material";
import ClientService from "../../services/client-service";
import { setGlobalSnackbar } from "../../reducers/app";


function Client() {
  let dispatch = useDispatch()
  const user = useSelector(state => state.auth.user)
  const data = useSelector(state => state.client.list)

  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState("")
  const [status, setStatus] = useState("1")
  const [pset, setPset] = useState("all")
  const [openForm, setOpenForm] = useState(null)
  const [openDelete, setOpenDelete] = useState(null)

  const { limit, currentPage } = data.paginator
  const getData = useCallback((page = currentPage, newLimit = limit) => {
    let params = {
      q: search,
      limit: newLimit,
      page,
      status,
    }
    if (pset !== 'all') {
      params = {
        ...params,
        pset
      }
    }
    setLoading(true)
    dispatch(getAllClient(params))
    setLoading(false)
  }, [search, status, pset, limit, currentPage, dispatch])
  useEffect(() => {
    let params = {
      q: search,
      limit,
      page: 1,
      status,
    }
    if (pset !== 'all') {
      params = {
        ...params,
        pset
      }
    }
    setLoading(true)
    dispatch(getAllClient(params))
    setLoading(false)
  }, [search, status, pset, limit, dispatch])

  const handleCreate = () => {
    getData()
  }

  const handleChangePaginator = (paginator) => {
    getData(paginator.currentPage, paginator.limit)
  }
  const handleClickUpdate = (data) => () => {
    setOpenForm(data)
  }
  const handleClickDelete = (data) => () => {
    setOpenDelete(data)
  }
  const handleSubmitDelete = () => {
    if (openDelete && openDelete.id) {
      return dispatch(deleteClientById(openDelete.id))
    }
    return null
  }
  const handleRequestPullFBAReport = async (id) => {
    try {
      const resp = await ClientService.pullFBAReport(id)
      dispatch(setGlobalSnackbar({
        open: true,
        title: resp.data.message,
        color: 'success',
        autoHide: true
      }))
    } catch (error) {
      console.log('error', error)
      dispatch(setGlobalSnackbar({
        open: true,
        title: error.data.message,
        color: 'error',
        autoHide: true
      }))
    }
  }

  const handleRequestPullMasterSheet = async (id) => {
    console.log('pulling mastershe')
    try {
      const resp = await ClientService.pullMasterSheet(id)
      dispatch(setGlobalSnackbar({
        open: true,
        title: resp.data.message,
        color: 'success',
        autoHide: true
      }))
    } catch (error) {
      console.log('error', error)
      dispatch(setGlobalSnackbar({
        open: true,
        title: error.data.message,
        color: 'error',
        autoHide: true
      }))
    }
  }

  return (
    <MDBox p={2} bgColor="white">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
          <MDInputTimeout disabled={loading} value={search} onChange={(e) => setSearch(e.target.value)} label="Search" fullWidth />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
          <MDInput select disabled={loading} label="Status" value={status} fullWidth onChange={(e) => setStatus(e.target.value)}>
            <MenuItem value="1">
              Active
            </MenuItem>
            <MenuItem value="0">
              Inactive
            </MenuItem>
          </MDInput>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
          <MDInput select disabled={loading} label="Password Set" value={pset} fullWidth onChange={(e) => setPset(e.target.value)}>
            <MenuItem value="all">
              All
            </MenuItem>
            <MenuItem value="1">
              Has password
            </MenuItem>
            <MenuItem value="0">
              No password
            </MenuItem>
          </MDInput>
        </Grid>
        <Grid item xs={12}>
          <MDTablePagination paginator={data.paginator} onChange={handleChangePaginator}>
            <Table stickyHeader sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <MDTypography variant="button" fontWeight="bold">ID</MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="button" fontWeight="bold">Name</MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="button" fontWeight="bold">Contacts</MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="button" fontWeight="bold">Address</MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="button" fontWeight="bold">Last Login</MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="button" fontWeight="bold">Status</MDTypography>
                  </TableCell>
                  {hasAccess(["client.update", "client.delete"], user) && (
                    <TableCell align="right" />
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.data.map(d => {
                  let actionItems = []

                  if (hasAccess(["client.update"], user)) {
                    actionItems.push({
                      key: "pull_fba_report",
                      text: "Pull FBA Reports",
                      icon: <Inventory color="warning" />,
                      onClick: () => handleRequestPullFBAReport(d.id)
                    })
                    actionItems.push({
                      key: "pull_master_sheet",
                      text: "Pull Master Sheet",
                      icon: <Storage color="warning" />,
                      onClick: () => handleRequestPullMasterSheet(d.id)
                    })
                  }

                  return (
                    <TableRow key={`data-${d.id}`} sx={{ verticalAlign: "top" }}>
                      <TableCell>
                        #{d.id}
                      </TableCell>
                      <TableCell>
                        {/* <MDTypography variant="body2">{displayName(d)}</MDTypography> */}
                        <ClientName client={d} />
                      </TableCell>
                      <TableCell>
                        {d.email && (
                          <MDTypography variant="body2">
                            <Icon><EmailIcon /></Icon> {d.email}
                          </MDTypography>
                        )}
                        {d.phone && (
                          <MDTypography variant="body2">
                            <Icon><LocalPhoneIcon /></Icon> {parsePhoneNumber("+" + d.phone).formatInternational()}
                          </MDTypography>
                        )}
                      </TableCell>
                      <TableCell>
                        <MDDisplayAddress variant="button" data={d} />
                      </TableCell>
                      <TableCell padding="checkbox" size="small">
                        <Chip
                          size="small"
                          color={d.isPasswordSet ? "success" : "error"}
                          label={d.isPasswordSet ? "Has password" : "No password"}
                          icon={d.isPasswordSet ? <CheckCircleIcon /> : <UnpublishedIcon />}
                        />

                        <div className="no-wrap" style={{marginTop: '5px'}}>
                          {d.lastLoginAt && formatDateTime(d.lastLoginAt)}
                        </div>
                      </TableCell>
                      <TableCell padding="checkbox" size="small">
                        <Chip
                          size="small"
                          color={d.isActive ? "success" : "error"}
                          label={d.isActive ? "Active" : "Inactive"}
                          icon={d.isActive ? <CheckCircleIcon /> : <UnpublishedIcon />}
                        />
                      </TableCell>
                      {hasAccess(["client.update", "client.delete"], user) && (
                        <TableCell align="right" padding="checkbox" size="small">
                          {d.id !== user.id && (
                            <MDActionMenu
                              items={actionItems}
                              handleUpdate={hasAccess(["client.update"], user) ? handleClickUpdate(d) : null}
                              handleDelete={hasAccess(["client.delete"], user) ? handleClickDelete(d) : null}
                            />
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </MDTablePagination>
          {hasAccess(["client.create"], user) && (
            <MDFab color="primary" onClick={() => setOpenForm({})}>
              <AddIcon />
            </MDFab>
          )}
          {openForm !== null && (
            <ClientForm data={openForm} onClose={() => setOpenForm(null)} onCreate={handleCreate} />
          )}
          {openDelete && (
            <MDAlertDialog onClose={() => setOpenDelete(null)} title="Delete Client?" content={
              <>
                <MDBox>
                  <MDTypography variant="h5">{displayName(openDelete)}</MDTypography>
                  <MDTypography variant="body2">will be deleted permanently. You need to reassign client to any active review plans, packing lists, items, invoices, etc that use this.</MDTypography>
                </MDBox>
              </>
            }
              onSubmit={handleSubmitDelete}
              submitText="Delete"
            />
          )}
        </Grid>
      </Grid>
    </MDBox>
  );
}

Client.propTypes = {
}

export default Client