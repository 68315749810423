// @ts-nocheck
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import PropTypes from "prop-types";

// react-router-dom components

// @mui material components
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import MDSnackbar from "../../components/MDSnackbar";
import MDInput from "../../components/MDInput";
import MDTypography from "../../components/MDTypography";
import ClientSelector from "../../components/DataSelector/ClientSelect";
import ReviewPlanSelector from "../../components/DataSelector/ReviewPlanSelect";
import ShipperSelector from "../../components/DataSelector/ShipperSelect";

import { create, updateById } from "../../reducers/packingList"
import { setGlobalSnackbar } from "../../reducers/app";
import { STATUS as RP_STATUS } from "../../constants/reviewplan.constant";

function PackingListForm({ hideClient, data, userId, onClose, onCreate }) {
  let dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const [selectedUserId, setUserId] = useState(userId || null)
  const [input, setInput] = useState({
    reviewPlanId: data.reviewPlanId || null,
    shipperId: data.shipperId || null,
    shipDate: data.shipDate || moment().format("YYYY-MM-DD"),
    externalShippingCode: data.externalShippingCode || null,
  })

  const handleClose = (e, reason = '') => {
    if (reason === 'escapeKeyDown' || reason === 'backdropClick') {
      return
    }
    onClose();
  };

  const handleChangeInput = (e) => {
    setErrorMessage(null)
    setInput(prev => {
      return {
        ...prev,
        [e.target.name]: e.target.value
      }
    })
  }

  const handleSubmit = async () => {
    setErrorMessage(null)
    try {
      setLoading(true)
      let resp = null
      let params = {
        ...input,
      }
      if (data.id) {
        resp = await dispatch(updateById(data.id, params))
      } else {
        resp = await create(params)
        if (onCreate) {
          onCreate(resp)
        }
      }
      dispatch(setGlobalSnackbar({
        open: true,
        title: resp.data.message,
        color: 'success',
        autoHide: true
      }))
      handleClose()
    } catch (e) {
      setLoading(false)
      setErrorMessage(e.data.message)
    }
  }

  return (
    <Dialog onClose={handleClose} open fullWidth maxWidth="xs" disableEscapeKeyDown>
      {errorMessage && (
        <MDSnackbar
          color="error"
          title={errorMessage}
          open
          close={() => setErrorMessage(null)}
        />
      )}
      <DialogTitle>{data.id ? "Update" : "Create"} Packing List</DialogTitle>
      <DialogContent>
        <MDBox mt={1} component="form" role="form" autoComplete="off" noValidate>
          {data.id && (
            <MDBox mb={2}>
              <MDTypography variant="body2" fontWeight="medium" color="dark">
                Internal shipping code: {data.internalShippingCode}
              </MDTypography>
            </MDBox>
          )}
          {hideClient ? (
            <MDBox mb={2}>
              <ClientSelector required disabled label="Client" fullWidth value={selectedUserId || null} name="selectedUserId" onChange={(e) => setUserId(e.target.value)} />
            </MDBox>
          ) : (
            <MDBox mb={2}>
              <ClientSelector required disabled={loading} label="Client" fullWidth value={selectedUserId || null} name="selectedUserId" onChange={(e) => setUserId(e.target.value)} />
            </MDBox>
          )}
          {selectedUserId && (
            <MDBox mb={2}>
              <ReviewPlanSelector userId={selectedUserId} required disabled={loading} status={RP_STATUS.approved.value} label={`Receive plan (${RP_STATUS.approved.text} only)`} fullWidth value={input?.reviewPlanId || null} name="reviewPlanId" onChange={handleChangeInput} />
            </MDBox>
          )}
          {input?.reviewPlanId && (
            <React.Fragment>
              <MDBox mb={2}>
                <ShipperSelector required disabled={loading} label="Shipper" fullWidth value={input?.shipperId || null} name="shipperId" onChange={handleChangeInput} allowAdd />
              </MDBox>
              <MDBox mb={2}>
                <MobileDatePicker
                  label="Ship date"
                  inputFormat="DD MMM YYYY"
                  value={input?.shipDate}
                  disabled={loading}
                  renderInput={(params) => <MDInput fullWidth {...params} />}
                  onChange={(value) => handleChangeInput({ target: { name: 'shipDate', value } })}
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput disabled={loading} label="External Shipping Code" fullWidth value={input?.externalShippingCode || ""} name="externalShippingCode" onChange={handleChangeInput} />
              </MDBox>
              {!data.id && (
                <MDBox mb={2}>
                  <MDTypography variant="caption" fontWeight="medium" color="dark">
                    * Internal shipping code will be generated after submission.
                  </MDTypography>
                </MDBox>
              )}
            </React.Fragment>
          )}
        </MDBox>
      </DialogContent>
      <DialogActions>
        <MDButton variant="gradient" disabled={loading} color="secondary" onClick={handleClose}>
          Cancel
        </MDButton>
        <MDButton variant="gradient" disabled={loading} color="primary" onClick={handleSubmit}>
          Submit
        </MDButton>
      </DialogActions>

    </Dialog>
  );
}

PackingListForm.defaultProps = {
  userId: null,
  data: {
    id: null,
    reviewPlanId: "",
    shipperId: "",
    shipDate: "",
    externalShippingCode: "",
    internalShippingCode: "",
  },
  hideClient: false
};

PackingListForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  userId: PropTypes.number,
  onCreate: PropTypes.func,
  data: PropTypes.object,
  hideClient: PropTypes.bool,
}

export default PackingListForm