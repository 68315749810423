// @ts-nocheck
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import NumberFormat from 'react-number-format';

// react-router-dom components

// @mui material components
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import InputAdornment from '@mui/material/InputAdornment';
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDInput from "../../components/MDInput";
import MDButton from "../../components/MDButton";
import MDSnackbar from "../../components/MDSnackbar";
import MDTypography from "../../components/MDTypography";
import ClientSelector from "../../components/DataSelector/ClientSelect";

import { create, updateById } from "../../reducers/item"
import { setGlobalSnackbar } from "../../reducers/app";
import { hasAccess } from "../../helpers/access";

function ItemForm({ data, onClose, onCreate }) {
  let dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const user = useSelector(state => state.auth.user)

  let userId = data.userId || ""
  if (!hasAccess(["item.setUser"], user)) {
    userId = user.id
  }
  const [input, setInput] = useState({
    userId,
    name: data.name || "",
    merchantSku: data.merchantSku || "",
    asin: data.asin || "",
    isActive: data.id ? data.isActive ? "1" : "0" : "1",
    unit: data.unit || "",
    qtyPerUnit: data.qtyPerUnit || "",
    labelingOwner: data.labelingOwner || "",
    prepOwner: data.prepOwner || "",
    weight: data.weight || "",
    width: data.dimension?.width || "",
    height: data.dimension?.height || "",
    length: data.dimension?.length || "",
    minDaysOfSupply: data.minDaysOfSupply || null,
  })

  const handleClose = (e, reason = '') => {
    if (reason === 'escapeKeyDown' || reason === 'backdropClick') {
      return
    }
    onClose();
  };

  const handleChangeInput = (e) => {
    setErrorMessage(null)
    setInput(prev => {
      return {
        ...prev,
        [e.target.name]: e.target.value
      }
    })
  }

  const handleSubmit = async () => {
    setErrorMessage(null)
    try {
      setLoading(true)
      let resp = null
      let params = {
        ...input,
        isActive: input.isActive === "1",
        minDaysOfSupply: input.minDaysOfSupply ? parseInt(input.minDaysOfSupply) : null,
      }
      if (data.id) {
        resp = await dispatch(updateById(data.id, params))
      } else {
        resp = await create(params)
        if (onCreate) {
          onCreate(resp.data.data)
        }
      }
      dispatch(setGlobalSnackbar({
        open: true,
        title: resp.data.message,
        color: 'success',
        autoHide: true
      }))
      handleClose()
    } catch (e) {
      setLoading(false)
      setErrorMessage(e.data.message)
    }
  }

  return (
    <Dialog onClose={handleClose} open fullWidth maxWidth="sm" disableEscapeKeyDown>
      {errorMessage && (
        <MDSnackbar
          color="error"
          title={errorMessage}
          open
          close={() => setErrorMessage(null)}
        />
      )}
      <DialogTitle>{data.id ? "Update" : "Create"} Item</DialogTitle>
      <DialogContent>
        <MDBox mt={1} component="form" role="form" autoComplete="off" noValidate>
          {hasAccess(["item.setUser"], user) && (
            <MDBox mb={2}>
              <ClientSelector required disabled={loading || data.id} label="Client" fullWidth value={input?.userId || null} name="userId" onChange={handleChangeInput} />
            </MDBox>
          )}
          <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
            <Grid item md={12} lg={12} xl={12} sm={12} mb={2}>
              <MDInput required type="text" disabled={loading || data.id} label="Name" fullWidth value={input?.name || ""} name="name" onChange={handleChangeInput} />
            </Grid>
          </Grid>
          <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
            <Grid item md={12} lg={6} xl={6} sm={12} mb={2}>
              <MDInput required type="text" disabled={loading || data.id} label="Merchant SKU" fullWidth value={input?.merchantSku || ""} name="merchantSku" onChange={handleChangeInput} />
            </Grid>
            <Grid item md={12} lg={6} xl={6} sm={12} mb={2}>
              <MDInput type="text" disabled={loading || data.id} label="ASIN" fullWidth value={input?.asin || ""} name="asin" onChange={handleChangeInput} />
            </Grid>
          </Grid>
          <Grid container spacing={1} justifyContent="center" alignItems="start" height="100%">
            <Grid item md={12} lg={6} xl={6} sm={12} mb={2}>
              <MDInput select disabled={loading} label="Status" value={input?.isActive} fullWidth name="isActive" onChange={handleChangeInput}>
                <MenuItem value="1">
                  Active
                </MenuItem>
                <MenuItem value="0">
                  Inactive
                </MenuItem>
              </MDInput>
            </Grid>
            <Grid item md={12} lg={6} xl={6} sm={12} mb={2}>
              <MDInput type="number" disabled={loading} label="Minimum Days of supply (default 120)" fullWidth value={input?.minDaysOfSupply || ""} name="minDaysOfSupply" onChange={handleChangeInput} />
            </Grid>
          </Grid>
          <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
            <Grid item md={12} lg={6} xl={6} sm={12} mb={2}>
              <MDInput required type="text" disabled={loading} label="Labeling Owner" fullWidth value={input?.labelingOwner || ""} name="labelingOwner" onChange={handleChangeInput} />
            </Grid>
            <Grid item md={12} lg={6} xl={6} sm={12} mb={2}>
              <MDInput type="text" disabled={loading} label="Prep Owner" fullWidth value={input?.prepOwner || ""} name="prepOwner" onChange={handleChangeInput} />
            </Grid>
          </Grid>
          <Grid container spacing={1} justifyContent="center" alignItems="start" height="100%">
            <Grid item md sm={6} mb={2}>
              <NumberFormat
                disabled={loading}
                label="Case Quantity"
                fullWidth
                required
                name="qtyPerUnit"
                value={input?.qtyPerUnit || ""}
                customInput={MDInput}
                thousandSeparator={true}
                onValueChange={({ floatValue }) => handleChangeInput({ target: { name: "qtyPerUnit", value: floatValue } })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MDTypography variant="body2" fontWeight="regular" color="text">
                        1 {input?.unit || ""} =
                      </MDTypography>
                    </InputAdornment>
                  ),
                }}
              />
              <MDTypography variant="caption" fontWeight="regular" color="text">
                i.e: "40" = 40 units per case
              </MDTypography>
            </Grid>
            <Grid item md={12} lg={6} xl={6} sm={12} mb={2}>
              <NumberFormat
                  disabled={loading}
                  label="Weight"
                  fullWidth
                  required
                  name="weight"
                  value={input?.weight || ""}
                  customInput={MDInput}
                  thousandSeparator={true}
                  onValueChange={({ floatValue }) => handleChangeInput({ target: { name: "weight", value: floatValue } })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <MDTypography variant="body2" fontWeight="regular" color="text">
                          Pounds
                        </MDTypography>
                      </InputAdornment>
                    ),
                  }}
                />
            </Grid>
          </Grid>
          <Card>
            <MDBox p={2}>
              <MDTypography variant="body2" fontWeight="bold" color="dark" mb={2}>
                Dimension
              </MDTypography>
              <Grid container spacing={1} justifyContent="start" alignItems="center" height="100%">
                <Grid item sm xs={12} mb={2}>
                  <NumberFormat
                    disabled={loading}
                    label="Length"
                    fullWidth
                    required
                    name="length"
                    value={input?.length || ""}
                    customInput={MDInput}
                    thousandSeparator={true}
                    onValueChange={({ floatValue }) => handleChangeInput({ target: { name: "length", value: floatValue } })}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <MDTypography variant="body2" fontWeight="regular" color="text">
                            Inches
                          </MDTypography>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item sm xs={12} mb={2}>
                  <NumberFormat
                    disabled={loading}
                    label="Width"
                    fullWidth
                    required
                    name="width"
                    value={input?.width || ""}
                    customInput={MDInput}
                    thousandSeparator={true}
                    onValueChange={({ floatValue }) => handleChangeInput({ target: { name: "width", value: floatValue } })}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <MDTypography variant="body2" fontWeight="regular" color="text">
                            Inches
                          </MDTypography>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item sm xs={12} mb={2}>
                  <NumberFormat
                    disabled={loading}
                    label="Height"
                    fullWidth
                    required
                    name="height"
                    value={input?.height || ""}
                    customInput={MDInput}
                    thousandSeparator={true}
                    onValueChange={({ floatValue }) => handleChangeInput({ target: { name: "height", value: floatValue } })}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <MDTypography variant="body2" fontWeight="regular" color="text">
                          Inches
                          </MDTypography>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </MDBox>
          </Card>
        </MDBox>
      </DialogContent>
      <DialogActions>
        <MDButton variant="gradient" disabled={loading} color="secondary" onClick={handleClose}>
          Cancel
        </MDButton>
        <MDButton variant="gradient" disabled={loading} color="primary" onClick={handleSubmit}>
          Submit
        </MDButton>
      </DialogActions>

    </Dialog>
  );
}

ItemForm.defaultProps = {
  data: {
    id: null,
    userId: "",
    name: "",
    merchantSku: "",
    asin: "",
    unit: "",
    qtyPerUnit: "",
    weight: "",
    width: "",
    height: "",
    length: "",
    isActive: "1",
    minDaysOfSupply: null,
  }
};

ItemForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func,
  data: PropTypes.object,
}

export default ItemForm