import { API_URL } from "../constants/api";
import { getAxios, postAxios } from "../helpers";

class ItemPriceService {

  getAll(itemId, params = null) {
    let url = API_URL.ITEM_PRICE.GET_ALL.replace(":itemId", itemId);
    return getAxios(url, params);
  }

  getById(itemId, id) {
    const url = API_URL.ITEM_PRICE.GET_BY_ID.replace(":itemId", itemId).replace(":id", id);
    return getAxios(url);
  }

  create(itemId, params) {
    const url = API_URL.ITEM_PRICE.CREATE.replace(":itemId", itemId);
    return postAxios(url, params);
  }

  updateById(itemId, id, params) {
    const url = API_URL.ITEM_PRICE.UPDATE_BY_ID.replace(":itemId", itemId).replace(":id", id);
    return postAxios(url, params, "put");
  }

  deleteById(itemId, id) {
    const url = API_URL.ITEM_PRICE.DELETE_BY_ID.replace(":itemId", itemId).replace(":id", id);
    return postAxios(url, {}, "delete");
  }

}

export default new ItemPriceService();
