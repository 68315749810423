const APP_INIT = "app/APP_INIT";
const APP_INIT_SUCCESS = "app/APP_INIT_SUCCESS";
const SET_MINI_SIDENAV = "app/SET_MINI_SIDENAV";
const SET_GLOBAL_SNACKBAR = "app/SET_GLOBAL_SNACKBAR";

const initialState = {
  init: false,
  miniSidenav: false,
  globalSnackbar: {
    open: false,
    title: null,
    content: null,
    bgWhite: false,
    color: "info",
    autoHide: false,
  }
}
const app = (state = { ...initialState }, action) => {
  switch (action.type) {
    case APP_INIT:
      return state;
    case APP_INIT_SUCCESS:
      return {
        ...state,
        init: true,
      };
    case SET_MINI_SIDENAV:
      return {
        ...state,
        miniSidenav: action.payload,
      };
    case SET_GLOBAL_SNACKBAR:
      return {
        ...state,
        globalSnackbar: {
          ...state.globalSnackbar,
          ...action.payload
        },
      };
    default:
      return state;
  }
};
export default app;

export const initialize = () => (dispatch) => {
  dispatch({ type: APP_INIT_SUCCESS });
};

export const hideMiniSidenav = (sidenav) => (dispatch) => {
  dispatch({ type: SET_MINI_SIDENAV, payload: sidenav });
};

export const setGlobalSnackbar = (options) => (dispatch) => {
  dispatch({ type: SET_GLOBAL_SNACKBAR, payload: options });
};
