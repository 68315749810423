import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for MDAutocomplete
import MDAutocompleteRoot from "./MDAutocompleteRoot";

const MDAutocomplete = forwardRef(({ error, success, disabled, ...rest }, ref) => (
  <MDAutocompleteRoot {...rest} ref={ref} ownerState={{ error, success, disabled }} />
));

// Setting default values for the props of MDAutocomplete
MDAutocomplete.defaultProps = {
  error: false,
  success: false,
  disabled: false,
};

// Typechecking props for the MDAutocomplete
MDAutocomplete.propTypes = {
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default MDAutocomplete;
