import { DEFAULT_PAGINATOR } from "../constants/api";
import InvoiceService from "../services/invoice-service";

const GET_ALL_DASHBOARD = "invoice/GET_ALL_DASHBOARD";
const GET_ALL = "invoice/GET_ALL";
const RESET_DROPDOWN = "invoice/RESET_DROPDOWN";
const GET_ALL_DROPDOWN = "invoice/GET_ALL_DROPDOWN";
const GET_BY_ID = "invoice/GET_BY_ID";
const UPDATE = "invoice/UPDATE";
const DELETE = "invoice/DELETE";

const initalState = {
  dashboard: {
    data: [],
    paginator: DEFAULT_PAGINATOR,
  },
  list: {
    data: [],
    paginator: DEFAULT_PAGINATOR,
  },
  detail: null,
  dropdown: {
    data: [],
    paginator: DEFAULT_PAGINATOR,
  }
};

const invoice = (state = { ...initalState }, { type, payload }) => {
  let findIdx = -1
  switch (type) {
    case GET_ALL_DASHBOARD:
      return {
        ...state,
        dashboard: {
          data: payload.data,
          paginator: payload.paginator
        },
      };
    case GET_ALL:
      return {
        ...state,
        list: {
          data: payload.data,
          paginator: payload.paginator
        },
      };
    case GET_ALL_DROPDOWN:
      return {
        ...state,
        dropdown: {
          data: [...state.dropdown.data, ...payload.data],
          paginator: payload.paginator
        },
      };
    case GET_BY_ID:
      return {
        ...state,
        detail: payload.data
      };
    case RESET_DROPDOWN:
      return {
        ...state,
        dropdown: {
          data: [],
          paginator: DEFAULT_PAGINATOR
        }
      };
    case UPDATE:
      if (state.detail && state.detail.id === payload.data.id) {
        state.detail = {
          ...state.detail,
          ...payload.data,
        }
      }
      findIdx = state.list.data.findIndex(d => d.id === payload.data.id)
      if (findIdx > -1) {
        state.list.data[findIdx] = {
          ...state.list.data[findIdx],
          ...payload.data
        }
      }
      findIdx = state.dropdown.data.findIndex(d => d.id === payload.data.id)
      if (findIdx > -1) {
        state.dropdown.data[findIdx] = {
          ...state.dropdown.data[findIdx],
          ...payload.data
        }
      }
      findIdx = state.dashboard.data.findIndex(d => d.id === payload.data.id)
      if (findIdx > -1) {
        state.dashboard.data[findIdx] = {
          ...state.dashboard.data[findIdx],
          ...payload.data
        }
      }
      return {
        ...state,
      };
    case DELETE:
      if (state.detail && state.detail.id === payload.data.id) {
        state.detail = null
      }
      findIdx = state.list.data.findIndex(d => d.id === payload.data.id)
      if (findIdx > -1) {
        state.list.data.splice(findIdx, 1)
        state.list.paginator = {
          ...state.list.paginator,
          totalItems: state.list.paginator.totalItems - 1,
          itemTo: state.list.paginator.itemTo - 1,
        }
      }
      findIdx = state.dropdown.data.findIndex(d => d.id === payload.data.id)
      if (findIdx > -1) {
        state.dropdown.data.splice(findIdx, 1)
        state.dropdown.paginator = {
          ...state.dropdown.paginator,
          totalItems: state.dropdown.paginator.totalItems - 1,
          itemTo: state.dropdown.paginator.itemTo - 1,
        }
      }
      findIdx = state.dashboard.data.findIndex(d => d.id === payload.data.id)
      if (findIdx > -1) {
        state.dashboard.data.splice(findIdx, 1)
        state.dashboard.paginator = {
          ...state.dashboard.paginator,
          totalItems: state.dashboard.paginator.totalItems - 1,
          itemTo: state.dashboard.paginator.itemTo - 1,
        }
      }
      return {
        ...state,
      };
    default:
      return state;
  }
};
export default invoice;

export const getAll = (params, type = 'all') => async (dispatch) => {
  const resp = await InvoiceService.getAll(params);
  if (resp.status && resp.status === 200) {
    if (type === 'dropdown') {
      dispatch({ type: GET_ALL_DROPDOWN, payload: resp.data });
    } else if (type === 'dashboard') {
      dispatch({ type: GET_ALL_DASHBOARD, payload: resp.data });
    } else {
      dispatch({ type: GET_ALL, payload: resp.data });
    }
  }
  return resp
};

export const getByIdDropdown = async (id) => {
  const resp = await InvoiceService.getById(id);
  if (resp.status && resp.status === 200) {
    return resp.data.data
  }
  return null
};

export const getById = (id) => async (dispatch) => {
  dispatch({ type: GET_BY_ID, payload: { data: null } });
  const resp = await InvoiceService.getById(id);
  if (resp.status && resp.status === 200) {
    dispatch({ type: GET_BY_ID, payload: resp.data });
  }
  return resp
};

export const generateNumber = async (invoiceDate) => {
  const resp = await InvoiceService.generateNumber(invoiceDate);
  if (resp.status && resp.status === 200) {
    return resp.data.data
  }
  return null
};

export const resetDropdown = () => async (dispatch) => {
  dispatch({ type: RESET_DROPDOWN });
};

export const updateById = (id, params) => async (dispatch) => {
  const resp = await InvoiceService.updateById(id, params);
  if (resp.status && resp.status === 200) {
    dispatch({ type: UPDATE, payload: resp.data });
  }
  return resp
};

export const create = (params) => {
  return InvoiceService.create(params);
};

export const deleteById = (id) => async (dispatch) => {
  const resp = await InvoiceService.deleteById(id);
  if (resp.status && resp.status === 200) {
    dispatch({ type: DELETE, payload: resp.data });
  }
  return resp
};

export const download = (id) => {
  return InvoiceService.download(id);
};

export const sendToClient = async (id) => {
  const resp = await InvoiceService.sendToClient(id);
  return resp
};
export const doVoid = (id) => async (dispatch) => {
  const resp = await InvoiceService.doVoid(id);
  if (resp.status && resp.status === 200) {
    dispatch({ type: UPDATE, payload: resp.data });
  }
  return resp
};
export const markPaid = (id) => async (dispatch) => {
  const resp = await InvoiceService.markPaid(id);
  if (resp.status && resp.status === 200) {
    dispatch({ type: UPDATE, payload: resp.data });
  }
  return resp
};