export const STATUS = {
  new: {
    key: 'new',
    value: 'new',
    text: 'New',
    permission: 'update',
    color: 'info',
  },
  clientReview: {
    key: 'clientReview',
    value: 'clientReview',
    text: 'In review',
    permission: "sendToClient",
    color: 'warning',
  },
  approved: {
    key: 'approved',
    value: 'approved',
    text: 'Approved',
    permission: "approve",
    color: 'success',
  },
  canceled: {
    key: 'canceled',
    value: 'canceled',
    text: 'Canceled',
    permission: "cancel",
    color: 'error',
  },
}

export const FINAL_STATUSES = [
  STATUS.approved.value, STATUS.canceled.value
]
export const STATUS_ALLOW_DELETE = [
  STATUS.new.value
]
