// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import parsePhoneNumber from 'libphonenumber-js'

// react-router-dom components

// @mui material components
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import LockResetIcon from '@mui/icons-material/LockReset';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDDisplayAddress from "../../components/MDDisplayAddress";

import { getMyProfile } from "../../reducers/auth"
import ProfileUserForm from "./ProfileUserForm";
import ChangePasswordForm from "./ChangePasswordForm";
import { displayName, formatDateTime } from "../../helpers";
import { ROLE } from "../../constants/user.constant";

const userInfoLabel1 = [
  {
    key: 'firstName',
    text: 'First name'
  },
  {
    key: 'email',
    text: 'Email'
  },
  {
    key: 'phone',
    text: 'Phone'
  },
]

function displayUserInfo(userInfoLabel, user) {
  return userInfoLabel.map(u => {
    const variant = "body2"
    let fontWeight = "regular"
    let value = user[u.key]
    if (u.key === 'firstName') {
      fontWeight = "bold"
      value = displayName(user)
    } else if (u.key === 'phone' && value) {
      value = parsePhoneNumber("+" + value).formatInternational()
    }
    return (
      <MDBox display="flex" pr={2} key={`user-info-label-${u.key}`}>
        <MDTypography variant={variant} fontWeight={fontWeight} color="text">
          {value || ""}
        </MDTypography>
      </MDBox>
    )
  })
}
function ProfileUserSection() {
  let dispatch = useDispatch()
  const user = useSelector(state => state.auth.user)
  const [editModal, setEditModal] = useState(false)
  const [changePasswordModal, setChangePasswordModal] = useState(false)

  useEffect(() => {
    dispatch(getMyProfile())
  }, [dispatch])

  return (
    <MDBox bgColor="white" p={2}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <MDTypography variant="subtitle1" fontWeight="medium" textTransform="capitalize">
          My Profile
        </MDTypography>
        <MDTypography variant="body2" color="secondary">
          <Tooltip title="Change password" placement="top">
            <IconButton
              size="small"
              disableRipple
              color="inherit"
              variant="contained"
              onClick={() => setChangePasswordModal(true)}
            >
              <Icon>
                <LockResetIcon />
              </Icon>
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit profile" placement="top">
            <IconButton
              size="small"
              disableRipple
              color="inherit"
              variant="contained"
              onClick={() => setEditModal(true)}
            >
              <Icon>
                <EditIcon />
              </Icon>
            </IconButton>
          </Tooltip>
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6}>
            <MDBox display="flex">
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                Role: &nbsp;
              </MDTypography>
              {user.roles.map(r => {
                return (
                  <MDTypography key={`roles-${r}`} variant="button" fontWeight="regular" color="text" mr={2}>
                    {ROLE[r].text}
                  </MDTypography>
                )
              })}
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <MDBox display="flex">
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                Last login: &nbsp;
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text">
                {formatDateTime(user.lastLoginAt)}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6}>
            <MDBox display="flex" py={1}>
              <MDTypography underline variant="subtitle2" fontWeight="medium" textTransform="capitalize">
                Contact:
              </MDTypography>
            </MDBox>
            {displayUserInfo(userInfoLabel1, user)}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <MDBox display="flex" py={1}>
              <MDTypography underline variant="subtitle2" fontWeight="medium" textTransform="capitalize">
                Address:
              </MDTypography>
            </MDBox>
            <MDDisplayAddress data={user} />
          </Grid>
        </Grid>
      </MDBox>

      {editModal && (
        <ProfileUserForm onClose={() => setEditModal(false)} />
      )}
      {changePasswordModal && (
        <ChangePasswordForm onClose={() => setChangePasswordModal(false)} />
      )}
    </MDBox>
  );
}

ProfileUserSection.propTypes = {
}

export default ProfileUserSection