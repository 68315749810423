import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import CloseIcon from '@mui/icons-material/Close';

// Material Dashboard 2 React components
import MDBox from "../../MDBox";
import MDTypography from "../../MDTypography";

// Material Dashboard 2 React example components
import SidenavCollapse from "./SidenavCollapse";

// Custom styles for the Sidenav
import SidenavRoot from "./SidenavRoot";

import config from "../../../configs"
import { AUTHENTICATED_ROUTE } from "../../../constants/routes";
import { hideMiniSidenav } from "../../../reducers/app";
import { MINIMUM_HIDE_SIDENAV } from "../../../constants/appSettings.constant";
import { hasAccess } from "../../../helpers/access";

const brand = config.site.URL + "/logo.png"
function Sidenav({ color, ...rest }) {
  let dispatch = useDispatch()
  const user = useSelector(state => state.auth.user)
  const miniSidenav = useSelector(state => state.app.miniSidenav)
  const location = useLocation();
  const pathName = location.pathname
  let textColor = "white";

  const closeSidenav = () => dispatch(hideMiniSidenav(true));

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      dispatch(hideMiniSidenav(window.innerWidth < MINIMUM_HIDE_SIDENAV));
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [location, dispatch]);

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = Object.values(AUTHENTICATED_ROUTE).filter(r => !r.hidden).map(({ type, name, icon, title, noCollapse, key, href, route, userRoleAbilityKeys, activeSidenav }) => {
    let returnValue;
    const active = pathName.startsWith(route)
    if (userRoleAbilityKeys && userRoleAbilityKeys.length > 0) {
      if (!hasAccess(userRoleAbilityKeys, user)) return null
    }

    if (type === "collapse") {
      returnValue = href ? (
        <Link
          href={href}
          key={key}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavCollapse
            name={name}
            icon={icon}
            active={active}
            noCollapse={noCollapse}
          />
        </Link>
      ) : (
        <NavLink key={key} to={route}>
          <SidenavCollapse name={name} icon={icon} active={active} miniSidenav={miniSidenav} />
        </NavLink>
      );
    } else if (type === "title") {
      returnValue = (
        <MDTypography
          key={key}
          color={textColor}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {title}
        </MDTypography>
      );
    } else if (type === "divider") {
      returnValue = (
        <Divider
          key={key}
          light={false}
        />
      );
    }

    return returnValue;
  });

  if (!user) return
  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ miniSidenav }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}><CloseIcon /></Icon>
          </MDTypography>
        </MDBox>
        <MDBox component={NavLink} to="/" display="flex" alignItems="center">
          <MDBox component="img" src={brand} width="120px" />
        </MDBox>
      </MDBox>
      <Divider light={false} />
      <List>{renderRoutes}</List>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
};

export default Sidenav;
