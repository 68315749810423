// @ts-nocheck
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import NumberFormat from 'react-number-format';
import moment from "moment";

// react-router-dom components

// @mui material components
import Grid from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import InputAdornment from '@mui/material/InputAdornment';
import Divider from "@mui/material/Divider";
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import MDInput from "../../components/MDInput";
import MDSnackbar from "../../components/MDSnackbar";
import MDTypography from "../../components/MDTypography";
import ItemSelector from "../../components/DataSelector/ItemSelect";

import { create, updateById } from "../../reducers/reviewPlanItem"
import { setGlobalSnackbar } from "../../reducers/app";
import { formatter } from "../../helpers";

function ReviewPlanItemForm({ reviewPlan, data, onClose, onChange }) {
  let dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const [input, setInput] = useState({
    itemId: data.itemId || "",
    expirationDate: moment(data.expirationDate) || moment(),
    quantity: data.quantity || "",
    pricePerUnit: data.pricePerUnit || "",
    discountPercentage: data.discountPercentage || "",
    discount: data.discount || "",
    notes: data.notes || "",
  })

  const handleClose = (e, reason = '') => {
    if (reason === 'escapeKeyDown' || reason === 'backdropClick') {
      return
    }
    onClose();
  };

  const handleChangeInput = (e) => {
    setErrorMessage(null)
    setInput(prev => {
      if (e.target.name === 'itemId' && e.target.data && e.target.data?.price?.price) {
        prev.pricePerUnit = e.target.data?.price?.price || prev.pricePerUnit
      }
      return {
        ...prev,
        [e.target.name]: e.target.value
      }
    })
  }

  const handleSubmit = async () => {
    setErrorMessage(null)
    try {
      setLoading(true)
      let resp = null
      let params = {
        ...input,
        expirationDate: input.expirationDate ? moment(input.expirationDate).format("YYYY-MM-DD") : null,
        discount: input.discount !== "" || Number(input.discount) > 0 ? input.discount : null,
        discountPercentage: input.discountPercentage !== "" || Number(input.discount) > 0 ? input.discountPercentage : null,
      }
      if (data.id) {
        resp = await dispatch(updateById(reviewPlan.id, data.id, params))
      } else {
        resp = await create(reviewPlan.id, params)
      }
      if (onChange) {
        onChange()
      }
      dispatch(setGlobalSnackbar({
        open: true,
        title: resp.data.message,
        color: 'success',
        autoHide: true
      }))
      handleClose()
    } catch (e) {
      setLoading(false)
      setErrorMessage(e.data.message)
    }
  }

  let total = Number(input?.pricePerUnit) * Number(input?.quantity)
  if (isNaN(total)) {
    total = 0
  }
  let totalDiscount = 0
  if (input?.discountPercentage && !isNaN(input?.discountPercentage)) {
    totalDiscount = Number(input?.discountPercentage) / 100 * total
  }
  let fixedDiscount = 0
  if (input?.discount && !isNaN(input?.discount)) {
    fixedDiscount = input?.discount
  }
  return (
    <Dialog onClose={handleClose} open fullWidth maxWidth="xs" disableEscapeKeyDown>
      {errorMessage && (
        <MDSnackbar
          color="error"
          title={errorMessage}
          open
          close={() => setErrorMessage(null)}
        />
      )}
      <DialogTitle>{data.id ? "Update" : "Create"} Item on Review Plan #{reviewPlan.id}</DialogTitle>
      <DialogContent>
        <MDBox mt={1} component="form" role="form" autoComplete="off" noValidate>
          <MDBox mb={2}>
            <ItemSelector userId={reviewPlan.userId} required disabled={loading} label="Item" fullWidth value={input?.itemId || null} name="itemId" onChange={handleChangeInput} allowAdd />
          </MDBox>
          <Grid container spacing={1} height="100%">
            <Grid item md={12} lg={6} xl={6} sm={12} mb={2}>
              <MobileDatePicker
                label="Expiration date"
                inputFormat="DD MMM YYYY"
                value={input?.expirationDate || ""}
                disabled={loading}
                renderInput={(params) => <MDInput fullWidth {...params} />}
                onChange={(value) => handleChangeInput({ target: { name: 'expirationDate', value } })}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} justifyContent="center" alignItems="start" height="100%">
            <Grid item md={12} lg={6} xl={6} sm={12} mb={2}>
              <NumberFormat
                disabled={loading}
                label="Quantity"
                fullWidth
                required
                decimalScale={0}
                name="quantity"
                value={input?.quantity || ""}
                customInput={MDInput}
                thousandSeparator={true}
                onValueChange={({ floatValue }) => handleChangeInput({ target: { name: "quantity", value: floatValue } })}
              />
            </Grid>
            <Grid item md={12} lg={6} xl={6} sm={12} mb={2}>
              <NumberFormat
                disabled={loading}
                label="Price per Unit"
                fullWidth
                required
                name="pricePerUnit"
                value={input?.pricePerUnit || ""}
                customInput={MDInput}
                thousandSeparator={true}
                onValueChange={({ floatValue }) => handleChangeInput({ target: { name: "pricePerUnit", value: floatValue } })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MDTypography variant="body2" fontWeight="regular" color="text">
                        $
                      </MDTypography>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} justifyContent="center" alignItems="start" height="100%">
            <Grid item md={12} lg={6} xl={6} sm={12} mb={2}>
              <NumberFormat
                disabled={loading}
                label="Discount"
                fullWidth
                decimalScale={0}
                name="discountPercentage"
                value={input?.discountPercentage || ""}
                customInput={MDInput}
                thousandSeparator={true}
                onValueChange={({ floatValue }) => handleChangeInput({ target: { name: "discountPercentage", value: floatValue } })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <MDTypography variant="body2" fontWeight="regular" color="text">
                        %
                      </MDTypography>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item md={12} lg={6} xl={6} sm={12} mb={2}>
              <NumberFormat
                disabled={loading}
                label="Fixed Discount"
                fullWidth
                name="discount"
                value={input?.discount || ""}
                customInput={MDInput}
                thousandSeparator={true}
                onValueChange={({ floatValue }) => handleChangeInput({ target: { name: "discount", value: floatValue } })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MDTypography variant="body2" fontWeight="regular" color="text">
                        $
                      </MDTypography>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <MDBox mb={2}>
            <MDBox display="flex" alignItems="center" color="white" justifyContent="space-between">
              <div>
                <MDTypography variant="body2" fontWeight="regular" color="dark">
                  Total:
                </MDTypography>
              </div>
              <div>
                <MDTypography variant="body2" fontWeight="regular" color="dark">
                  {formatter.fullCurrency(total)}
                </MDTypography>
              </div>
            </MDBox>
            <MDBox display="flex" alignItems="center" color="white" justifyContent="space-between">
              <div>
                <MDTypography variant="body2" fontWeight="regular" color="dark">
                  Total discount in percentage: {input?.discountPercentage ? `(${formatter.numberToCommaString(input?.discountPercentage)} %)` : ''}
                </MDTypography>
              </div>
              <div>
                <MDTypography variant="body2" fontWeight="regular" color="dark">
                  {formatter.fullCurrency(totalDiscount)}
                </MDTypography>
              </div>
            </MDBox>
            <MDBox display="flex" alignItems="center" color="white" justifyContent="space-between">
              <div>
                <MDTypography variant="body2" fontWeight="regular" color="dark">
                  Fixed discount:
                </MDTypography>
              </div>
              <div>
                <MDTypography variant="body2" fontWeight="regular" color="dark">
                  {formatter.fullCurrency(fixedDiscount)}
                </MDTypography>
              </div>
            </MDBox>
            <Divider light={false} sx={{ marginTop: 1, marginBottom: 1 }} />
            <MDBox display="flex" alignItems="center" color="white" justifyContent="space-between">
              <div>
                <MDTypography variant="body2" fontWeight="bold" color="dark">
                  Total price after discounts:
                </MDTypography>
              </div>
              <div>
                <MDTypography variant="body2" fontWeight="bold" color="dark">
                  {formatter.fullCurrency(total - totalDiscount - fixedDiscount)}
                </MDTypography>
              </div>
            </MDBox>
          </MDBox>

          <MDBox mb={2}>
            <MDInput multiline maxRows={4} disabled={loading} label="notes" value={input?.notes || ""} fullWidth name="notes" onChange={handleChangeInput} />
          </MDBox>
        </MDBox>
      </DialogContent>
      <DialogActions>
        <MDButton variant="gradient" disabled={loading} color="secondary" onClick={handleClose}>
          Cancel
        </MDButton>
        <MDButton variant="gradient" disabled={loading} color="primary" onClick={handleSubmit}>
          Submit
        </MDButton>
      </DialogActions>

    </Dialog>
  );
}

ReviewPlanItemForm.defaultProps = {
  reviewPlan: {
    id: null,
    userId: null,
  },
  data: {
    id: null,
    itemId: "",
    expirationDate: "",
    quantity: "",
    pricePerUnit: "",
    discountPercentage: "",
    discount: "",
    notes: "",
  }
};

ReviewPlanItemForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  data: PropTypes.object,
  reviewPlan: PropTypes.object.isRequired,
}

export default ReviewPlanItemForm