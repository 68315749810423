import { API_URL } from "../constants/api";
import { getAxios, postAxios } from "../helpers";

class InvoiceService {

  getAll(params = null) {
    let url = API_URL.INVOICE.GET_ALL;
    return getAxios(url, params);
  }

  getById(id) {
    const url = API_URL.INVOICE.GET_BY_ID.replace(":id", id);
    return getAxios(url);
  }

  generateNumber(invoiceDate) {
    const url = API_URL.INVOICE.GENERATE_NUMBER;
    return postAxios(url, { invoiceDate });
  }

  create(params) {
    const url = API_URL.INVOICE.CREATE;
    return postAxios(url, params);
  }

  updateById(id, params) {
    const url = API_URL.INVOICE.UPDATE_BY_ID.replace(":id", id);
    return postAxios(url, params, "put");
  }

  deleteById(id) {
    const url = API_URL.INVOICE.DELETE_BY_ID.replace(":id", id);
    return postAxios(url, {}, "delete");
  }

  download(id) {
    const url = API_URL.INVOICE.DOWNLOAD.replace(":id", id);
    return getAxios(url, null, { "Content-Type": "application/pdf" }, { responseType: "blob" });
  }
  sendToClient(id) {
    const url = API_URL.INVOICE.SEND_TO_CLIENT.replace(":id", id);
    return postAxios(url, null);
  }
  doVoid(id) {
    const url = API_URL.INVOICE.VOID.replace(":id", id);
    return postAxios(url, null);
  }
  markPaid(id) {
    const url = API_URL.INVOICE.MARK_PAID.replace(":id", id);
    return postAxios(url, null);
  }

}

export default new InvoiceService();
