// @ts-nocheck
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// react-router-dom components

// @mui material components
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Chip from '@mui/material/Chip';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import PriceChangeIcon from '@mui/icons-material/PriceChange';

// Material Dashboard 2 React components
import MDTablePagination from "../../components/MDTablePagination";
import MDInputTimeout from "../../components/MDInputTimeout";
import MDInput from "../../components/MDInput";
import MDBox from "../../components/MDBox";
import MDFab from "../../components/MDFab";
import MDTypography from "../../components/MDTypography";
import MDActionMenu from "../../components/MDActionMenu";
import MDAlertDialog from "../../components/MDAlertDialog";

import { deleteById as deleteItemById, getAll as getAllItem } from "../../reducers/item";
import { hasAccess } from "../../helpers/access";
import ItemForm from "./ItemForm";
import ItemPrice from "./ItemPrice";
import ClientSelector from "../../components/DataSelector/ClientSelect";
import { formatter, displayName, displayDimension, formatDateTime } from "../../helpers";
import ClientName from "components/ClientName/ClientName";


function Item() {
  let dispatch = useDispatch()
  const user = useSelector(state => state.auth.user)
  const data = useSelector(state => state.item.list)

  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState("")
  const [status, setStatus] = useState("1")
  const [userId, setUserId] = useState(null)
  const [openForm, setOpenForm] = useState(null)
  const [openDelete, setOpenDelete] = useState(null)
  const [openManagePrice, setOpenManagePrice] = useState(null)

  const { limit, currentPage } = data.paginator
  const getData = useCallback((page = currentPage, newLimit = limit) => {
    let params = {
      q: search,
      limit: newLimit,
      page,
      status,
    }
    if (userId) {
      params = {
        ...params,
        userId
      }
    }
    setLoading(true)
    dispatch(getAllItem(params))
    setLoading(false)
  }, [search, limit, currentPage, status, userId, dispatch])
  useEffect(() => {
    let params = {
      q: search,
      limit,
      page: 1,
      status,
    }
    if (userId) {
      params = {
        ...params,
        userId
      }
    }
    setLoading(true)
    dispatch(getAllItem(params))
    setLoading(false)
  }, [search, limit, status, userId, dispatch])

  const handleCreate = () => {
    getData()
  }

  const handleChangePaginator = (paginator) => {
    getData(paginator.currentPage, paginator.limit)
  }
  const handleClickUpdate = (data) => () => {
    setOpenForm(data)
  }
  const handleClickDelete = (data) => () => {
    setOpenDelete(data)
  }
  const handleManagePrice = (data) => () => {
    setOpenManagePrice(data)
  }
  const handleSubmitDelete = () => {
    if (openDelete && openDelete.id) {
      return dispatch(deleteItemById(openDelete.id))
    }
    return null
  }

  return (
    <MDBox p={2} bgColor="white">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
          <MDInputTimeout disabled={loading} value={search} onChange={(e) => setSearch(e.target.value)} label="Search" fullWidth />
        </Grid>
        {hasAccess(["item.setUser"], user) && (
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <ClientSelector disabled={loading} value={userId} onChange={(e) => setUserId(e.target.value)} label="Client" fullWidth />
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
          <MDInput select disabled={loading} label="Status" value={status} fullWidth onChange={(e) => setStatus(e.target.value)}>
            <MenuItem value="1">
              Active
            </MenuItem>
            <MenuItem value="0">
              Inactive
            </MenuItem>
          </MDInput>
        </Grid>
        <Grid item xs={12}>
          <MDTablePagination paginator={data.paginator} onChange={handleChangePaginator}>
            <Table stickyHeader sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  {hasAccess(["item.setUser"], user) && (
                    <TableCell>
                      <MDTypography variant="button" fontWeight="bold">Client</MDTypography>
                    </TableCell>
                  )}
                  <TableCell>
                    <MDTypography variant="button" fontWeight="bold">Name</MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="button" fontWeight="bold">SKU</MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="button" fontWeight="bold">ASIN</MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="button" fontWeight="bold">Unit</MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="button" fontWeight="bold">Qty per Unit</MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="button" fontWeight="bold">Weight (gr)</MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="button" fontWeight="bold">Dimension</MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="button" fontWeight="bold">Current Price</MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="button" fontWeight="bold">Status</MDTypography>
                  </TableCell>
                  {hasAccess(["item.update", "item.delete"], user) && (
                    <TableCell align="right" />
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.data.map(d => {
                  let actionItems = []
                  if (hasAccess(["itemPrice.view"], user)) {
                    actionItems.push({
                      key: "set_price",
                      text: "Manage Price",
                      icon: <PriceChangeIcon color="success" />,
                      onClick: handleManagePrice(d)
                    })
                  }
                  return (
                    <TableRow key={`data-${d.id}`}>
                      {hasAccess(["item.setUser"], user) && (
                        <TableCell>
                          {d.user ? (
                            <React.Fragment>
                              <ClientName client={d.user} showEmail />
                              {/* <div>
                                <MDTypography variant="body2">{displayName(d.user)}</MDTypography>
                              </div>
                              <div>
                                <MDTypography variant="button">{d.user.email}</MDTypography>
                              </div> */}
                            </React.Fragment>
                          ) : "-"}
                        </TableCell>
                      )}
                      <TableCell>
                        <MDTypography variant="body2">{d.name || ""}</MDTypography>
                      </TableCell>
                      <TableCell>
                        <MDTypography variant="body2">{d.merchantSku || ""}</MDTypography>
                      </TableCell>
                      <TableCell>
                        <MDTypography variant="body2">{d.asin || "-"}</MDTypography>
                      </TableCell>
                      <TableCell>
                        <MDTypography variant="body2">{d.unit || ""}</MDTypography>
                      </TableCell>
                      <TableCell align="right">
                        <MDTypography variant="body2">{d.qtyPerUnit ? formatter.numberToCommaString(d.qtyPerUnit) : ""}</MDTypography>
                      </TableCell>
                      <TableCell align="right">
                        <MDTypography variant="body2">{d.weight ? formatter.numberToCommaString(d.weight) : "-"}</MDTypography>
                      </TableCell>
                      <TableCell align="right">
                        {d.dimension && (
                          <MDTypography variant="body2">{displayDimension(d.dimension)}</MDTypography>
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {d.price && (
                          <React.Fragment>
                            <div>
                              <MDTypography variant="body2">{formatter.fullCurrency(d.price.price)}</MDTypography>
                            </div>
                            <div style={{ lineHeight: 1 }}>
                              <MDTypography variant="caption">Effective since</MDTypography>
                            </div>
                            <div style={{ lineHeight: 1 }}>
                              <MDTypography variant="caption">{formatDateTime(d.price.effectiveDate, true, false)}</MDTypography>
                            </div>
                          </React.Fragment>
                        )}
                      </TableCell>
                      <TableCell padding="checkbox" size="small">
                        <Chip
                          size="small"
                          color={d.isActive ? "success" : "error"}
                          label={d.isActive ? "Active" : "Inactive"}
                          icon={d.isActive ? <CheckCircleIcon /> : <UnpublishedIcon />}
                        />
                      </TableCell>
                      {hasAccess(["item.update", "item.delete"], user) && (
                        <TableCell align="right" padding="checkbox" size="small">
                          <MDActionMenu
                            items={actionItems}
                            handleUpdate={hasAccess(["item.update"], user) ? handleClickUpdate(d) : null}
                            handleDelete={hasAccess(["item.delete"], user) ? handleClickDelete(d) : null}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </MDTablePagination>
          {hasAccess(["item.create"], user) && (
            <MDFab color="primary" onClick={() => setOpenForm({})}>
              <AddIcon />
            </MDFab>
          )}
          {openForm !== null && (
            <ItemForm data={openForm} onClose={() => setOpenForm(null)} onCreate={handleCreate} />
          )}
          {openDelete && (
            <MDAlertDialog onClose={() => setOpenDelete(null)} title="Delete Item?" content={
              <>
                <MDBox>
                  <MDTypography variant="h5">{openDelete.name || ""}</MDTypography>
                  <MDTypography variant="body2">will be deleted permanently if there is no review plan that use this item.</MDTypography>
                </MDBox>
              </>
            }
              onSubmit={handleSubmitDelete}
              submitText="Delete"
            />
          )}
          {openManagePrice !== null && (
            <ItemPrice item={openManagePrice} onClose={() => setOpenManagePrice(null)} onChange={handleCreate} />
          )}
        </Grid>
      </Grid>
    </MDBox>
  );
}

Item.propTypes = {
}

export default Item