// @ts-nocheck
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

// react-router-dom components

// @mui material components
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import MDDisplayAddress from "../../components/MDDisplayAddress";
import MDTypography from "../../components/MDTypography";

import { getById } from "../../reducers/shipper"

function ShipperDetail({ data, onClose }) {
  let dispatch = useDispatch()

  const shipper = useSelector(state => state.shipper.detail) || data

  useEffect(() => {
    if (data.id) dispatch(getById(data.id))
  }, [data.id, dispatch])

  if (!shipper.id) return null
  return (
    <Dialog onClose={onClose} open fullWidth maxWidth="xs" disableEscapeKeyDown>
      <DialogTitle>Shipper Detail</DialogTitle>
      <DialogContent>
        <MDBox mt={1}>
          <MDBox mb={2}>
            <MDTypography variant="body2">{shipper.name}</MDTypography>
          </MDBox>
          <MDDisplayAddress data={data} />
        </MDBox>
      </DialogContent>
      <DialogActions>
        <MDButton variant="gradient" color="secondary" onClick={onClose}>
          Close
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

ShipperDetail.defaultProps = {
  data: {
    id: null,
    name: "",
    phone: "",
    addressFieldOne: "",
    addressFieldTwo: "",
    city: "",
    district: "",
    state: "",
    country: "",
    postalCode: "",
    user: {}
  },
};

ShipperDetail.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object,
}

export default ShipperDetail