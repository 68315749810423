import config from "../configs"

const API_BASE_URL = config.api.ENDPOINT

export const DEFAULT_PAGINATOR = {
  totalItems: 0,
  totalPages: 1,
  itemFrom: 0,
  itemTo: 0,
  currentPage: 1,
  limit: 30,
  nextPage: null,
  previousPage: null,
};

export const API_URL = {
  AUTH: {
    LOGIN: API_BASE_URL + 'auth/login',
    REGISTER: API_BASE_URL + 'auth/register',
    VERIFY: API_BASE_URL + 'auth/verify',
    SEND_RESET_PASSWORD_EMAIL: API_BASE_URL + 'auth/reset-password/send-email',
    CHECK_OTP_RESET_PASSWORD: API_BASE_URL + 'auth/reset-password/check-otp',
    RESET_PASSWORD: API_BASE_URL + 'auth/reset-password',
    GET_MY_PROFILE: API_BASE_URL + 'auth/me',
    UPDATE_MY_PROFILE: API_BASE_URL + 'auth/me',
    CHANGE_PASSWORD: API_BASE_URL + 'auth/change-password',
  },
  SHIPPER: {
    GET_ALL: API_BASE_URL + 'shippers',
    GET_BY_ID: API_BASE_URL + 'shippers/:id',
    CREATE: API_BASE_URL + 'shippers',
    UPDATE_BY_ID: API_BASE_URL + 'shippers/:id',
    DELETE_BY_ID: API_BASE_URL + 'shippers/:id',
  },
  USER: {
    GET_ALL: API_BASE_URL + 'users',
    GET_BY_ID: API_BASE_URL + 'users/:id',
    CREATE: API_BASE_URL + 'users',
    UPDATE_BY_ID: API_BASE_URL + 'users/:id',
    DELETE_BY_ID: API_BASE_URL + 'users/:id',
  },
  CLIENT: {
    GET_ALL: API_BASE_URL + 'clients',
    GET_BY_ID: API_BASE_URL + 'clients/:id',
    CREATE: API_BASE_URL + 'clients',
    UPDATE_BY_ID: API_BASE_URL + 'clients/:id',
    DELETE_BY_ID: API_BASE_URL + 'clients/:id',
    PULL_FBA_REPORT: API_BASE_URL + 'clients/:id/pull-fba-reports',
    PULL_MASTER_SHEET: API_BASE_URL + 'clients/:id/pull-master-sheet',
  },
  RECEIVER: {
    GET_ALL: API_BASE_URL + 'receivers',
    GET_BY_ID: API_BASE_URL + 'receivers/:id',
    CREATE: API_BASE_URL + 'receivers',
    UPDATE_BY_ID: API_BASE_URL + 'receivers/:id',
    DELETE_BY_ID: API_BASE_URL + 'receivers/:id',
  },
  ITEM: {
    GET_ALL: API_BASE_URL + 'items',
    GET_BY_ID: API_BASE_URL + 'items/:id',
    CREATE: API_BASE_URL + 'items',
    UPDATE_BY_ID: API_BASE_URL + 'items/:id',
    DELETE_BY_ID: API_BASE_URL + 'items/:id',
  },
  ITEM_PRICE: {
    GET_ALL: API_BASE_URL + 'items/:itemId/prices',
    GET_BY_ID: API_BASE_URL + 'items/:itemId/prices/:id',
    CREATE: API_BASE_URL + 'items/:itemId/prices',
    UPDATE_BY_ID: API_BASE_URL + 'items/:itemId/prices/:id',
    DELETE_BY_ID: API_BASE_URL + 'items/:itemId/prices/:id',
  },
  REVIEW_PLAN: {
    GET_ALL: API_BASE_URL + 'review-plans',
    GET_BY_ID: API_BASE_URL + 'review-plans/:id',
    CREATE: API_BASE_URL + 'review-plans',
    UPDATE_BY_ID: API_BASE_URL + 'review-plans/:id',
    DELETE_BY_ID: API_BASE_URL + 'review-plans/:id',
    DOWNLOAD: API_BASE_URL + 'review-plans/:id/download',
    DOWNLOAD_EXCEL: API_BASE_URL + 'review-plans/:id/download-excel',
    DOWNLOAD_ORDER_SHEET: API_BASE_URL + 'review-plans/:id/download-order-sheet',
    SEND_TO_CLIENT: API_BASE_URL + 'review-plans/:id/send-to-client',
    APPROVE: API_BASE_URL + 'review-plans/:id/approve',
    REJECT: API_BASE_URL + 'review-plans/:id/reject',
  },
  REVIEW_PLAN_ITEM: {
    GET_ALL: API_BASE_URL + 'review-plans/:reviewPlanId/items',
    GET_BY_ID: API_BASE_URL + 'review-plans/:reviewPlanId/items/:id',
    CREATE: API_BASE_URL + 'review-plans/:reviewPlanId/items',
    UPDATE_BY_ID: API_BASE_URL + 'review-plans/:reviewPlanId/items/:id',
    DELETE_BY_ID: API_BASE_URL + 'review-plans/:reviewPlanId/items/:id',
  },
  PACKING_LIST: {
    GET_ALL: API_BASE_URL + 'packing-lists',
    GET_BY_ID: API_BASE_URL + 'packing-lists/:id',
    CREATE: API_BASE_URL + 'packing-lists',
    UPDATE_BY_ID: API_BASE_URL + 'packing-lists/:id',
    DELETE_BY_ID: API_BASE_URL + 'packing-lists/:id',
    DOWNLOAD: API_BASE_URL + 'packing-lists/:id/download',
    SEND_TO_CLIENT: API_BASE_URL + 'packing-lists/:id/send-to-client',
    CHANGE_STATUS: API_BASE_URL + 'packing-lists/:id/change-status',
  },
  INVOICE: {
    GET_ALL: API_BASE_URL + 'invoices',
    GET_BY_ID: API_BASE_URL + 'invoices/:id',
    CREATE: API_BASE_URL + 'invoices',
    UPDATE_BY_ID: API_BASE_URL + 'invoices/:id',
    DELETE_BY_ID: API_BASE_URL + 'invoices/:id',
    GENERATE_NUMBER: API_BASE_URL + 'invoices/generate-number',
    DOWNLOAD: API_BASE_URL + 'invoices/:id/download',
    SEND_TO_CLIENT: API_BASE_URL + 'invoices/:id/send-to-client',
    VOID: API_BASE_URL + 'invoices/:id/void',
    MARK_PAID: API_BASE_URL + 'invoices/:id/pay',
  },
  FILE_UPLOAD: {
    STORE: API_BASE_URL + 'file-upload',
  },
  FBA_REPORT: {
    GET_ALL: API_BASE_URL + 'fba-reports',
  },
  INVENTORY_PREDICTION: {
    GET_ALL: API_BASE_URL + 'inventory-predictions',
    CREATE: API_BASE_URL + 'inventory-predictions',
    UPDATE_BY_ID: API_BASE_URL + 'inventory-predictions/:id',
  }
}