// @ts-nocheck
import React from "react";
import PropTypes from "prop-types";

// react-router-dom components

// @mui material components

// Material Dashboard 2 React components
import MDTypography from "../MDTypography"

import { getCountryName, getStateName } from "../../helpers/country-state";

function MDDisplayAddress({ data, variant }) {
  let stateCountry = ""
  if (data.state && data.country) {
    stateCountry += getStateName(data.country, data.state) || ""
    if (stateCountry !== "") stateCountry += ", "
    stateCountry += getCountryName(data.country)
  } else if (data.country) {
    stateCountry += getCountryName(data.country)
  }
  return (
    <>
      {data.addressFieldOne && (
        <div>
          <MDTypography variant={variant} fontWeight="regular" color="text">
            {data.addressFieldOne || ""}
          </MDTypography>
        </div>
      )}
      {data.addressFieldTwo && (
        <div>
          <MDTypography variant={variant} fontWeight="regular" color="text">
            {data.addressFieldTwo || ""}
          </MDTypography>
        </div>
      )}
      {data.city && (
        <div>
          <MDTypography variant={variant} fontWeight="regular" color="text">
            {data.city || ""}
          </MDTypography>
        </div>
      )}
      {data.district && (
        <div>
          <MDTypography variant={variant} fontWeight="regular" color="text">
            District: {data.district || ""}
          </MDTypography>
        </div>
      )}
      {stateCountry !== "" && (
        <div>
          <MDTypography variant={variant} fontWeight="regular" color="text">
            {stateCountry}
          </MDTypography>
        </div>
      )}
      {data.postalCode && (
        <div>
          <MDTypography variant={variant} fontWeight="regular" color="text">
            {data.postalCode || ""}
          </MDTypography>
        </div>
      )}
    </>
  );
}


MDDisplayAddress.defaultProps = {
  variant: "body2"
};

// Typechecking props for the MDPagination
MDDisplayAddress.propTypes = {
  data: PropTypes.object,
  variant: PropTypes.string,
};

export default MDDisplayAddress