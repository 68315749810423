import { USER_ROLE_ABILITY } from "../constants/user.constant"

export function hasAccess(keys, user) {
  if (keys.length === 0) return true
  if (user.roles.length === 0) return true
  let allow = false
  for (let k of keys) {
    const key = k.split(".")
    if (!USER_ROLE_ABILITY[key[0]]) {
      continue
    }
    let roles = []
    if (key.length > 1) {
      if (!USER_ROLE_ABILITY[key[0]][key[1]]) continue
      roles = USER_ROLE_ABILITY[key[0]][key[1]]
    } else {
      for (let r of Object.values(USER_ROLE_ABILITY[key[0]])) {
        if (r.length === 0) continue
        roles = [...roles, ...r]
      }
    }
    for (let role of roles) {
      if (user.roles.includes(role.value)) {
        allow = true
        break
      }
    }
    if (allow) break
  }
  return allow
}

export function hasTodoDashboardAccess(userToDoDashboard, user) {
  if (user.roles.length === 0) return true
  let allow = false
  const roles = userToDoDashboard.roles
  for (let role of roles) {
    if (user.roles.includes(role.value)) {
      allow = true
      break
    }
  }
  return allow
}