/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import CloseIcon from '@mui/icons-material/Close';
import Divider from "@mui/material/Divider";
import Fade from "@mui/material/Fade";

// Material Dashboard 2 React components
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";

// Custom styles for the MDSnackbar
import MDSnackbarIconRoot from "./MDSnackbarIconRoot";


function MDSnackbar({ color, icon, title, content, autoHide, close, bgWhite, ...rest }) {

  let titleColor;
  let dividerColor;

  if (bgWhite) {
    titleColor = color;
    dividerColor = false;
  } else if (color === "light") {
    titleColor = "dark";
    dividerColor = false;
  } else {
    titleColor = "white";
    dividerColor = true;
  }

  return (
    <Snackbar
      TransitionComponent={Fade}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      {...(autoHide ? { autoHideDuration: 6000, onClose: close } : {})}
      {...rest}
      {...(close ? {
        action: (
          <IconButton size="small" aria-label="close" color="inherit" onClick={close}>
            <Icon fontSize="small">
              <CloseIcon />
            </Icon>
          </IconButton>
        )
      } : {})}
    >
      <MDBox
        variant={bgWhite ? "contained" : "gradient"}
        bgColor={bgWhite ? "white" : color}
        minWidth="21.875rem"
        maxWidth="100%"
        shadow="md"
        borderRadius="md"
        p={1}
        sx={{
          backgroundColor: ({ palette }) =>
            palette[color] || palette.white.main,
        }}
      >
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          color="dark"
          p={1.5}
        >
          <MDBox display="flex" alignItems="center" lineHeight={0}>
            {icon && (
              <MDSnackbarIconRoot fontSize="small" ownerState={{ color, bgWhite }}>
                {icon}
              </MDSnackbarIconRoot>
            )}
            <MDTypography
              variant="button"
              fontWeight="medium"
              color={titleColor}
              textGradient={bgWhite}
            >
              {title}
            </MDTypography>
          </MDBox>
          {close && (
            <MDBox display="flex" alignItems="center" lineHeight={0}>
              <Icon
                sx={{
                  color: ({ palette: { dark, white } }) =>
                    (bgWhite) || color === "light" ? dark.main : white.main,
                  fontWeight: ({ typography: { fontWeightBold } }) => fontWeightBold,
                  cursor: "pointer",
                  marginLeft: 2,
                  transform: "translateY(-1px)",
                }}
                onClick={close}
              >
                <CloseIcon />
              </Icon>
            </MDBox>
          )}
        </MDBox>
        {
          content && (
            <>
              <Divider sx={{ margin: 0 }} light={dividerColor} />
              <MDBox
                p={1.5}
                sx={{
                  fontSize: ({ typography: { size } }) => size.sm,
                  color: ({ palette: { white, text } }) => {
                    let colorValue = bgWhite || color === "light" ? text.main : white.main;

                    return colorValue;
                  },
                }}
              >
                {content}
              </MDBox>
            </>
          )
        }
      </MDBox>
    </Snackbar>
  );
}

// Setting default values for the props of MDSnackbar
MDSnackbar.defaultProps = {
  bgWhite: false,
  color: "info",
  autoHide: false,
};

// Typechecking props for MDSnackbar
MDSnackbar.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  icon: PropTypes.node,
  title: PropTypes.string.isRequired,
  content: PropTypes.node,
  close: PropTypes.func,
  bgWhite: PropTypes.bool,
  autoHide: PropTypes.bool,
};

export default MDSnackbar;
