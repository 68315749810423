import { useSelector } from "react-redux";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "../../MDBox";
import DashboardNavbar from "./DashboardNavbar";

function DashboardLayout({ children }) {
  const miniSidenav = useSelector(state => state.app.miniSidenav)

  return (
    <>
      <MDBox
        sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
          py: 2,
          px: 0,
          position: "relative",

          [breakpoints.up("xl")]: {
            marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
            transition: transitions.create(["margin-left", "margin-right"], {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.standard,
            }),
          },
        })}
      >
        <DashboardNavbar />
        <MDBox mt={8}>
          <MDBox mb={3}>
            {children}
          </MDBox>
        </MDBox>
      </MDBox>
    </>
  );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
