// @ts-nocheck
import React from "react";
// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "../components/MDBox";
import MDTypography from "../components/MDTypography";
import MDButton from "../components/MDButton";

import { useNavigate } from "react-router-dom";

function Page404({ showBackButton = false }) {
  const navigate = useNavigate()
  return (
    <MDBox pt={3}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="bold" color="white" mt={1}>
            404
          </MDTypography>
        </MDBox>
        <MDBox textAlign="center" pb={4}>
          <MDTypography variant="h4" fontWeight="bold" color="dark" mt={1}>
            Page Not Found
          </MDTypography>
        </MDBox>
        {
          showBackButton && (
            <MDBox mb={4} textAlign="center">
              <MDButton variant="gradient" color="primary" onClick={() => navigate("/")}>
                Back
              </MDButton>
            </MDBox>
          )
        }
      </Card>
    </MDBox>
  );
}

Page404.propTypes = {
}

export default Page404