import { API_URL } from "../constants/api";
import { getAxios, postAxios } from "../helpers";

class ShipperService {

  getAll(params = null) {
    let url = API_URL.SHIPPER.GET_ALL;
    return getAxios(url, params);
  }

  getById(id) {
    const url = API_URL.SHIPPER.GET_BY_ID.replace(":id", id);
    return getAxios(url);
  }

  create(params) {
    const url = API_URL.SHIPPER.CREATE;
    return postAxios(url, params);
  }

  updateById(id, params) {
    const url = API_URL.SHIPPER.UPDATE_BY_ID.replace(":id", id);
    return postAxios(url, params, "put");
  }

  deleteById(id) {
    const url = API_URL.SHIPPER.DELETE_BY_ID.replace(":id", id);
    return postAxios(url, {}, "delete");
  }

}

export default new ShipperService();
