// @ts-nocheck
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

// react-router-dom components

// @mui material components
import Grid from "@mui/material/Grid";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import MDTypography from "../../components/MDTypography";
import MDTablePagination from "../../components/MDTablePagination";
import MDActionMenu from "../../components/MDActionMenu";
import MDAlertDialog from "../../components/MDAlertDialog";

import { resetAll as resetAllItemPrice, deleteById as deleteItemPriceById, getAll as getAllItemPrice } from "../../reducers/itemPrice"
import { hasAccess } from "../../helpers/access";
import { formatDateTime, formatter } from "../../helpers";
import ItemPriceForm from "./ItemPriceForm";

function ItemPrice({ item, onClose, onChange }) {
  let dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [openForm, setOpenForm] = useState(null)
  const [openDelete, setOpenDelete] = useState(null)
  const user = useSelector(state => state.auth.user)
  const data = useSelector(state => state.itemPrice.list)

  const { limit, currentPage } = data.paginator
  const getData = useCallback((page = currentPage, newLimit = limit) => {
    let params = {
      limit: newLimit,
      page,
    }
    setLoading(true)
    dispatch(getAllItemPrice(item.id, params))
    setLoading(false)
  }, [item.id, limit, currentPage, dispatch])
  useEffect(() => {
    let params = {
      limit,
      page: 1,
    }
    setLoading(true)
    dispatch(getAllItemPrice(item.id, params))
    setLoading(false)
  }, [item.id, limit, dispatch])
  useEffect(() => {
    dispatch(resetAllItemPrice())
  }, [item.id, dispatch])

  const handleCreate = () => {
    getData()
    if (onChange) onChange()
  }

  const handleChangePaginator = (paginator) => {
    getData(paginator.currentPage, paginator.limit)
  }
  const handleClickUpdate = (data) => () => {
    setOpenForm(data)
  }
  const handleClickDelete = (data) => () => {
    setOpenDelete(data)
  }
  const handleSubmitDelete = () => {
    if (openDelete && openDelete.id) {
      const resp = dispatch(deleteItemPriceById(item.id, openDelete.id))
      if (onChange) onChange()
      return resp
    }
    return null
  }

  const handleClose = (e, reason = '') => {
    if (reason === 'escapeKeyDown' || reason === 'backdropClick') {
      return
    }
    onClose();
  };

  if (!hasAccess(["itemPrice.view"], user)) return null

  return (
    <React.Fragment>
      <Dialog onClose={handleClose} open fullWidth maxWidth="xs" disableEscapeKeyDown>
        <DialogTitle>Prices for: {item.name}</DialogTitle>
        <DialogContent>
          {hasAccess(["itemPrice.create"], user) && (
            <Grid container spacing={1}>
              <Grid item xs={12} sx={{ textAlign: 'right' }}>
                <MDButton variant="gradient" color="primary" onClick={() => setOpenForm({})}>
                  Add
                </MDButton>
              </Grid>
            </Grid>
          )}
          <MDBox mt={1} component="form" role="form" autoComplete="off" noValidate>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <MDTablePagination paginator={data.paginator} onChange={handleChangePaginator}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <MDTypography variant="button" fontWeight="bold">Effective Date</MDTypography>
                        </TableCell>
                        <TableCell>
                          <MDTypography variant="button" fontWeight="bold">Price</MDTypography>
                        </TableCell>
                        {hasAccess(["itemPrice.update", "itemPrice.delete"], user) && (
                          <TableCell align="right" />
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.data.map(d => {
                        return (
                          <TableRow key={`data-price-${d.id}`}>
                            <TableCell>
                              <MDTypography variant="body2">{formatDateTime(d.effectiveDate, true, false)}</MDTypography>
                            </TableCell>
                            <TableCell align="right">
                              <MDTypography variant="body2">{d.price ? formatter.fullCurrency(d.price) : ""}</MDTypography>
                            </TableCell>
                            {hasAccess(["itemPrice.update", "itemPrice.delete"], user) && (
                              <TableCell align="right" padding="checkbox" size="small">
                                <MDActionMenu
                                  handleUpdate={hasAccess(["itemPrice.update"], user) ? handleClickUpdate(d) : null}
                                  handleDelete={hasAccess(["itemPrice.delete"], user) ? handleClickDelete(d) : null}
                                />
                              </TableCell>
                            )}
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </MDTablePagination>
              </Grid>
            </Grid>
          </MDBox>
        </DialogContent>
        <DialogActions>
          <MDButton variant="gradient" disabled={loading} color="secondary" onClick={handleClose}>
            Close
          </MDButton>
        </DialogActions>
      </Dialog>
      {openForm !== null && (
        <ItemPriceForm item={item} data={openForm} onClose={() => setOpenForm(null)} onCreate={handleCreate} onUpdate={onChange} />
      )}
      {openDelete && (
        <MDAlertDialog onClose={() => setOpenDelete(null)} title="Delete Item?" content={
          <>
            <MDBox>
              <MDTypography variant="h5">{openDelete.name || ""}</MDTypography>
              <MDTypography variant="body2">will be deleted permanently if there is no review plan that use this item.</MDTypography>
            </MDBox>
          </>
        }
          onSubmit={handleSubmitDelete}
          submitText="Delete"
        />
      )}
    </React.Fragment>
  );
}

ItemPrice.propTypes = {
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  item: PropTypes.object.isRequired,
}

export default ItemPrice