// @ts-nocheck
import React, { useState } from "react";
import PropTypes from "prop-types";
import qs from "query-string"

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import MDInput from "../../components/MDInput";
import MDInputPassword from "../../components/MDInputPassword";
import MDAlert from "../../components/MDAlert";
import MDSnackbar from "../../components/MDSnackbar";

import { UNAUTHENTICATED_ROUTE } from "../../constants/routes";
import { checkOtpResetPassword, resetPassword, sendResetPasswordEmail } from "../../reducers/auth";
import { validateEmail } from "../../helpers";


function ForgotPassword({ isSet }) {
  const queryData = qs.parse(window.location.search)
  const [loading, setLoading] = useState(false)
  const [errorEmail, setErrorEmail] = useState(null)
  const [errorOtp, setErrorOtp] = useState(null)
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [email, setEmail] = useState(queryData.email || "")
  const [emailSubmitted, setEmailSubmitted] = useState(isSet)
  const [otpSubmitted, setOtpSubmitted] = useState(isSet)
  const [otp, setOtp] = useState(queryData.code || "")

  const [resetPasswordError, setResetPasswordError] = useState(null)
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(null)

  const handleSubmitEmail = async () => {
    if (!validateEmail(email)) {
      setErrorEmail(true)
      return
    }
    try {
      setLoading(true)
      await sendResetPasswordEmail(email)
      setEmailSubmitted(true)
      setLoading(false)
    } catch (e) {
      setErrorEmail(e.data.message)
      setLoading(false)
    }
  }
  const handleSubmitOtp = async () => {
    setErrorOtp(false)
    if (otp.trim() === "") {
      setErrorOtp(true)
      return
    }
    try {
      setLoading(true)
      await checkOtpResetPassword(email, otp)
      setOtpSubmitted(true)
      setLoading(false)
    } catch (e) {
      setErrorOtp(e.data.message)
      setLoading(false)
    }
  }
  const handleSubmitResetPassword = async () => {
    setResetPasswordSuccess(null)
    setResetPasswordError(null)
    try {
      setLoading(true)
      const resp = await resetPassword(email, password, otp)
      setResetPasswordSuccess(resp.data.message)
      setLoading(false)
    } catch (e) {
      setResetPasswordError(e.data.message)
      setLoading(false)
    }
  }

  return (
    <>
      {resetPasswordError && (
        <MDSnackbar
          color="error"
          title={resetPasswordError}
          open
          close={() => setResetPasswordError(null)}
        />
      )}

      <Card>
        <MDBox pt={4} pb={3} px={3}>
          <MDTypography variant="h5" fontWeight="bold" color="dark" mt={1} mb={2}>
            {otpSubmitted ? "Set your password" : "Tell us your email to reset your password"}
          </MDTypography>

          {resetPasswordSuccess ? (
            <MDAlert color="success">
              <MDTypography variant="body2" color="white">
                {resetPasswordSuccess}
              </MDTypography>
            </MDAlert>
          ) : (
            <MDBox component="form" role="form" autoComplete="off" noValidate>
              <MDBox mb={2}>
                <MDInput required error={errorEmail !== null} type="email" disabled={emailSubmitted || loading} label="Email" fullWidth value={email} onChange={(e) => {
                  setEmail(e.target.value)
                  if (e.target.value === "" || validateEmail(e.target.value)) {
                    setErrorEmail(null)
                  } else {
                    setErrorEmail("input a valid email")
                  }
                }} />
                {
                  errorEmail && (
                    <MDTypography variant="caption" fontWeight="medium" color="error">
                      {errorEmail}
                    </MDTypography>
                  )
                }
              </MDBox>
              {emailSubmitted && !otpSubmitted && (
                <MDBox mb={2}>
                  <MDInput required error={errorOtp !== null} disabled={loading} type="text" label="Enter your OTP" fullWidth value={otp} onChange={(e) => {
                    setOtp(e.target.value)
                    setErrorOtp(null)
                  }} />
                  {
                    errorOtp && (
                      <MDTypography variant="caption" fontWeight="medium" color="error">
                        {errorOtp}
                      </MDTypography>
                    )
                  }
                </MDBox>
              )}
              {emailSubmitted && otpSubmitted && (
                <>
                  <MDBox mb={2}>
                    <MDInputPassword required disabled={loading} label="New password" fullWidth value={password} onChange={(e) => {
                      setPassword(e.target.value)
                    }} />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInputPassword error={password !== confirmPassword} required disabled={loading} label="Confirm new password" fullWidth value={confirmPassword} onChange={(e) => {
                      setConfirmPassword(e.target.value)
                    }} />
                    {
                      password !== confirmPassword && (
                        <MDTypography variant="caption" fontWeight="medium" color="error">
                          confirm new password does not match with the new password
                        </MDTypography>
                      )
                    }
                  </MDBox>
                </>
              )}
              <MDBox mb={1}>
                {
                  !emailSubmitted ? (
                    <MDButton variant="gradient" disabled={loading} color="primary" fullWidth onClick={handleSubmitEmail}>
                      request OTP
                    </MDButton>
                  ) : !otpSubmitted ? (
                    <MDButton disabled={otp === "" || loading} variant="gradient" color="primary" fullWidth onClick={handleSubmitOtp}>
                      submit
                    </MDButton>
                  ) : (
                    <MDButton variant="gradient" disabled={password === "" || password !== confirmPassword || loading} color="primary" fullWidth onClick={handleSubmitResetPassword}>
                      {isSet ? "set my password" : "reset my password"}
                    </MDButton>
                  )
                }
              </MDBox>
            </MDBox>
          )}

          <MDBox mt={3} mb={1} textAlign="center">
            <MDTypography variant="button" color="text">
              <MDTypography
                component={Link}
                to={UNAUTHENTICATED_ROUTE.signIn.route}
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                Go to Sign In Page
              </MDTypography>
            </MDTypography>
          </MDBox>
        </MDBox>
      </Card>
    </>
  );
}

ForgotPassword.defaultProps = {
  isSet: false,
};

ForgotPassword.propTypes = {
  isSet: PropTypes.bool,
}

export default ForgotPassword