// @ts-nocheck
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import qs from "query-string"
import moment from "moment";

// react-router-dom components

// @mui material components
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import InputAdornment from "@mui/material/InputAdornment";
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import MoneyOffCsredIcon from '@mui/icons-material/MoneyOffCsred';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloseIcon from '@mui/icons-material/Close';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

// Material Dashboard 2 React components
import MDTablePagination from "../../components/MDTablePagination";
import MDInputTimeout from "../../components/MDInputTimeout";
import MDInput from "../../components/MDInput";
import MDBox from "../../components/MDBox";
import MDFab from "../../components/MDFab";
import MDTypography from "../../components/MDTypography";
import MDActionMenu from "../../components/MDActionMenu";
import MDAlertDialog from "../../components/MDAlertDialog";
import MDButton from "../../components/MDButton";
import ClientSelector from "../../components/DataSelector/ClientSelect";
import ReviewPlanSelector from "../../components/DataSelector/ReviewPlanSelect";

import { download, deleteById as deleteInvoiceById, getAll as getAllInvoice, sendToClient as sendInvoiceToClient, doVoid as doVoidInvoice, markPaid as markPaidInvoice } from "../../reducers/invoice";
import { hasAccess } from "../../helpers/access";
import { STATUS, FINAL_STATUSES, STATUS_ALLOW_DELETE } from "../../constants/invoice.constant";
import { STATUS as RP_STATUS } from "../../constants/reviewplan.constant";
import { STATUS as PL_STATUS } from "../../constants/packinglist.constant";
import { AUTHENTICATED_ROUTE } from "../../constants/routes";
import InvoiceForm from "./InvoiceForm";
import { displayName, downloadBlobFile, formatDateTime, formatter } from "../../helpers";
import { invoiceAmountDetail } from "../../helpers/invoice";
import ClientName from "components/ClientName/ClientName";
import { InfoOutlined } from "@mui/icons-material";

let timeout = null

function Invoice() {
  let dispatch = useDispatch()
  const navigate = useNavigate()
  const queryData = qs.parse(window.location.search)
  const user = useSelector(state => state.auth.user)
  const data = useSelector(state => state.invoice.list)

  const [loadingPdf, setLoadingPdf] = useState(false)
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState(queryData.q || "")
  const [status, setStatus] = useState(queryData.status || "no-voided")
  const [userId, setUserId] = useState(null)
  const [reviewPlanId, setReviewPlanId] = useState(null)
  const [openForm, setOpenForm] = useState(null)
  const [openDelete, setOpenDelete] = useState(null)
  const [dateMinInput, setDateMinInput] = useState(null)
  const [dateMaxInput, setDateMaxInput] = useState(null)
  const [dateMin, setDateMin] = useState(null)
  const [dateMax, setDateMax] = useState(null)

  const [openAmountDetail, setOpenAmountDetail] = useState(null)
  const [voidInvoiceData, setVoidInvoiceData] = useState(null)
  const [sendToClientData, setSendToClientData] = useState(null)
  const [markAsPaidData, setMarkAsPaidData] = useState(null)

  const { limit, currentPage } = data.paginator
  const getData = useCallback((page = currentPage, newLimit = limit) => {
    let params = {
      limit: newLimit,
      page,
    }
    if (search !== "") {
      params = {
        ...params,
        q: search
      }
    }
    if (status === "no-voided") {
      params = {
        ...params,
        notStatus: STATUS.voided.value
      }
    } else if (status !== "all") {
      params = {
        ...params,
        status
      }
    }
    if (userId) {
      params = {
        ...params,
        userId
      }
    }
    if (dateMin) {
      params = {
        ...params,
        dueDateMin: moment(dateMin).format("YYYY-MM-DD")
      }
    }
    if (dateMax) {
      params = {
        ...params,
        dueDateMax: moment(dateMax).format("YYYY-MM-DD")
      }
    }
    setLoading(true)
    dispatch(getAllInvoice(params))
    setLoading(false)
  }, [search, limit, currentPage, status, userId, dateMin, dateMax, dispatch])
  useEffect(() => {
    let params = {
      limit,
      page: 1,
    }
    if (search !== "") {
      params = {
        ...params,
        q: search
      }
    }
    if (status === "no-voided") {
      params = {
        ...params,
        notStatus: STATUS.voided.value
      }
    } else if (status !== "all") {
      params = {
        ...params,
        status
      }
    }
    if (userId) {
      params = {
        ...params,
        userId
      }
    }
    if (dateMin) {
      params = {
        ...params,
        dueDateMin: moment(dateMin).format("YYYY-MM-DD")
      }
    }
    if (dateMax) {
      params = {
        ...params,
        dueDateMax: moment(dateMax).format("YYYY-MM-DD")
      }
    }
    setLoading(true)
    dispatch(getAllInvoice(params))
    setLoading(false)
  }, [search, limit, status, userId, dateMin, dateMax, dispatch])

  useEffect(() => {
    return () => {
      if (timeout) {
        clearTimeout(timeout)
        timeout = null
      }
    }
  })
  const handleCreate = () => {
    getData()
  }

  const handleSendToClient = (data) => () => {
    setSendToClientData(data)
  }
  const handleVoidInvoiceData = (data) => () => {
    setVoidInvoiceData(data)
  }
  const handleSubmitSendToClient = async () => {
    if (sendToClientData && sendToClientData.id) {
      return sendInvoiceToClient(sendToClientData.id)
    }
    return null
  }
  const handleVoidInvoice = async () => {
    if (voidInvoiceData && voidInvoiceData.id) {
      return dispatch(doVoidInvoice(voidInvoiceData.id))
    }
    return null
  }
  const handleMarkAsPaid = (data) => () => {
    setMarkAsPaidData(data)
  }
  const handleSubmitMarkAsPaid = async () => {
    if (markAsPaidData && markAsPaidData.id) {
      return dispatch(markPaidInvoice(markAsPaidData.id))
    }
    return null
  }

  const handleChangePaginator = (paginator) => {
    getData(paginator.currentPage, paginator.limit)
  }
  const handleClickUpdate = (data) => () => {
    setOpenForm(data)
  }
  const handleClickDelete = (data) => () => {
    setOpenDelete(data)
  }
  const handleSubmitDelete = () => {
    if (openDelete && openDelete.id) {
      return dispatch(deleteInvoiceById(openDelete.id))
    }
    return null
  }
  const handleDownloadInvoice = (data) => () => {
    setLoadingPdf(true)
    download(data.id).then(resp => {
      setLoadingPdf(false)
      downloadBlobFile(resp.data, "application/pdf; charset=utf-8", "test.pdf")
    })
  }

  return (
    <MDBox display="flex" justifyContent="space-between" alignInvoices="center" p={2} bgColor="white">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <MDInputTimeout disabled={loading} value={search} onChange={(e) => setSearch(e.target.value)} label="Invoice number" fullWidth />
        </Grid>
        {hasAccess(["reviewPlan.setUser"], user) && (
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <ClientSelector disabled={loading} value={userId} onChange={(e) => setUserId(e.target.value)} label="Client" fullWidth />
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <ReviewPlanSelector disabled={loading} value={reviewPlanId} onChange={(e) => setReviewPlanId(e.target.value)} label="Review Plan ID" fullWidth />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={2}>
          <MDInput select disabled={loading} label="Status" value={status} fullWidth onChange={(e) => setStatus(e.target.value)}>
            <MenuItem value="all">
              All statuses
            </MenuItem>
            <MenuItem value="no-voided">
              All, except {STATUS.voided.value.toLowerCase()}
            </MenuItem>
            {Object.values(STATUS).map(s => {
              return (
                <MenuItem value={s.value} key={`status-${s.key}`}>
                  {s.text}
                </MenuItem>
              )
            })}
          </MDInput>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={2}>
          <MobileDatePicker
            label="Due date (from)"
            inputFormat="DD MMM YYYY"
            value={dateMinInput}
            disabled={loading}
            renderInput={(params) => <MDInput fullWidth {...params} InputProps={{
              endAdornment: dateMin ? (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="No minimum date"
                    edge="end"
                    onClick={() => {
                      setDateMin(null)
                      setDateMinInput(null)
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }} />}
            onChange={(value) => setDateMinInput(value)}
            onAccept={() => setDateMin(dateMinInput)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={2}>
          <MobileDatePicker
            label="Due date (to)"
            inputFormat="DD MMM YYYY"
            value={dateMaxInput}
            disabled={loading}
            renderInput={(params) => <MDInput fullWidth {...params} InputProps={{
              endAdornment: dateMax ? (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="No maximum date"
                    edge="end"
                    onClick={() => {
                      setDateMax(null)
                      setDateMaxInput(null)
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }} />}
            onChange={(value) => setDateMaxInput(value)}
            onAccept={() => setDateMax(dateMaxInput)}
          />
        </Grid>
        <Grid item xs={12}>
          <MDTablePagination paginator={data.paginator} onChange={handleChangePaginator}>
            <Table stickyHeader sx={{ minWidth: 450 }}>
              <TableHead>
                <TableRow>
                  {hasAccess(["reviewPlan.setUser"], user) && (
                    <TableCell>
                      <MDTypography variant="button" fontWeight="bold">Client</MDTypography>
                    </TableCell>
                  )}
                  <TableCell>
                    <MDTypography variant="button" fontWeight="bold">Invoice No.</MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="button" fontWeight="bold">Due</MDTypography>
                  </TableCell>
                  {(hasAccess(["reviewPlan.view"], user)) && (
                    <TableCell>
                      <MDTypography variant="button" fontWeight="bold">Review Plan</MDTypography>
                    </TableCell>
                  )}
                  {(hasAccess(["packingList.view"], user)) && (
                    <TableCell>
                      <MDTypography variant="button" fontWeight="bold">Packing List</MDTypography>
                    </TableCell>
                  )}
                  <TableCell>
                    <MDTypography variant="button" fontWeight="bold">Amount</MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="button" fontWeight="bold">Status</MDTypography>
                  </TableCell>
                  {hasAccess(["invoice.update", "invoice.delete", "invoice.view", "invoice.sendToClient", "invoice.void", "invoice.pay"], user) && (
                    <TableCell align="right" />
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.data.map(d => {
                  let actionItems = []
                  if (hasAccess(["invoice.view"], user)) {
                    actionItems.push({
                      key: "download_invoice",
                      text: "Download PDF",
                      icon: <CloudDownloadIcon color="action" />,
                      onClick: handleDownloadInvoice(d)
                    })
                  }
                  if (hasAccess(["invoice.sendToClient"], user)) {
                    actionItems.push({
                      key: "send_to_client",
                      text: "Send to Client",
                      icon: <ForwardToInboxIcon color="warning" />,
                      onClick: handleSendToClient(d)
                    })
                  }
                  if (hasAccess(["invoice.void"], user) && d.status === STATUS.new.value) {
                    actionItems.push({
                      key: "void",
                      text: "Void",
                      icon: <MoneyOffCsredIcon color="error" />,
                      onClick: handleVoidInvoiceData(d)
                    })
                  }
                  if (hasAccess(["invoice.pay"], user) && d.status === STATUS.new.value) {
                    actionItems.push({
                      key: "mark-as-paid",
                      text: "Mark as Paid",
                      icon: <PriceCheckIcon color="success" />,
                      onClick: handleMarkAsPaid(d)
                    })
                  }
                  const invoiceAmountDetailData = invoiceAmountDetail(d)
                  return (
                    <TableRow key={`data-${d.id}`}>
                      {hasAccess(["reviewPlan.setUser"], user) && (
                        <TableCell>
                          {d.user ? (
                            <React.Fragment>
                              <ClientName client={d.user} showEmail />
                              {/* <div>
                                <MDTypography variant="body2">{displayName(d.user)}</MDTypography>
                              </div>
                              <div>
                                <MDTypography variant="button">{d.user.email}</MDTypography>
                              </div> */}
                            </React.Fragment>
                          ) : "-"}
                        </TableCell>
                      )}
                      <TableCell>
                        <MDTypography variant="body2">{d.invoiceNumber}</MDTypography>
                      </TableCell>
                      <TableCell size="small">
                        <div>
                          <MDTypography variant="body2" color={d.invoiceDue && d.status === STATUS.new.value && moment(d.invoiceDue).isSameOrBefore(moment()) ? "error" : "dark"} fontWeight={d.invoiceDue && d.status === STATUS.new.value && moment(d.invoiceDue).isSameOrBefore(moment()) ? "bold" : "regular"}>{formatDateTime(d.invoiceDue, true, false)}</MDTypography>
                        </div>
                        <div style={{ lineHeight: 1, marginTop: 4 }}>
                          <MDTypography variant="caption">Invoice date</MDTypography>
                        </div>
                        <div>
                          <MDTypography variant="caption">{formatDateTime(d.invoiceDate, true, false)}</MDTypography>
                        </div>
                      </TableCell>
                      {(hasAccess(["reviewPlan.view"], user)) && (
                        <TableCell>
                          {d.reviewPlan && (
                            <div className="flex gap-2" style={{gap: '5px'}}>
                              <div>
                                <Link className="color-primary" to={AUTHENTICATED_ROUTE.reviewPlans.route + "?id=" + d.reviewPlan.id}>{d.reviewPlan ? "#" + d.reviewPlan.id : ""}</Link>
                              </div>
                              <div>
                                /
                              </div>
                              <div>
                                <Chip
                                  size="small"
                                  color={RP_STATUS[d.reviewPlan.status] ? RP_STATUS[d.reviewPlan.status].color : 'default'}
                                  label={RP_STATUS[d.reviewPlan.status] ? RP_STATUS[d.reviewPlan.status].text : d.reviewPlan.status}
                                />
                              </div>
                              
                            </div>
                          )}
                        </TableCell>
                      )}
                      {(hasAccess(["packingList.view"], user)) && (
                        <TableCell>
                          {d.reviewPlan && d.reviewPlan.packingList && (
                            <React.Fragment>
                              <div style={{ lineHeight: 1 }}>
                                <MDTypography variant="caption">Internal shipping code</MDTypography>
                              </div>
                              <div>
                                <MDTypography variant="body2">{d.reviewPlan.packingList && d.reviewPlan.packingList.internalShippingCode ? d.reviewPlan.packingList.internalShippingCode : ""}</MDTypography>
                              </div>
                              <div style={{ lineHeight: 1 }}>
                                <MDTypography variant="caption">External shipping code</MDTypography>
                              </div>
                              <div>
                                <MDTypography variant="body2">{d.reviewPlan.packingList && d.reviewPlan.packingList.externalShippingCode ? d.reviewPlan.packingList.externalShippingCode : "-"}</MDTypography>
                              </div>
                              <div>
                                <Chip
                                  size="small"
                                  color={PL_STATUS[d.reviewPlan.packingList.status] ? PL_STATUS[d.reviewPlan.packingList.status].color : 'default'}
                                  label={PL_STATUS[d.reviewPlan.packingList.status] ? PL_STATUS[d.reviewPlan.packingList.status].text : d.reviewPlan.packingList.status}
                                />
                              </div>
                              <div>
                                <MDButton style={{padding: '0px', minWidth: '0px'}} variant="text" size="small" color="primary" onClick={() => navigate(AUTHENTICATED_ROUTE.packingLists.route + "?q=" + d.reviewPlan.packingList.internalShippingCode)}>
                                  View detail
                                </MDButton>
                              </div>
                            </React.Fragment>
                          )}
                        </TableCell>
                      )}
                      <TableCell align="right">
                        <div className="color-primary no-wrap flex align-items-center cursor-pointer" onClick={() => setOpenAmountDetail(invoiceAmountDetailData)} >
                          {formatter.fullCurrency(invoiceAmountDetailData.netAmount)}
                          {' '}
                          <span className="color-primary flex ml-5px"><InfoOutlined /></span>
                        </div>
                      </TableCell>
                      <TableCell size="small">
                        <Chip
                          size="small"
                          color={STATUS[d.status] ? STATUS[d.status].color : 'default'}
                          label={STATUS[d.status] ? STATUS[d.status].text : d.status}
                        />
                        <div style={{ lineHeight: 1 }}>
                          <MDTypography variant="caption">Updated:</MDTypography>
                        </div>
                        <div style={{ lineHeight: 1 }}>
                          <MDTypography variant="caption">{formatDateTime(d.updatedAt)}</MDTypography>
                        </div>
                        <div style={{ lineHeight: 1 }}>
                          <MDTypography variant="caption">Created:</MDTypography>
                        </div>
                        <div style={{ lineHeight: 1 }}>
                          <MDTypography variant="caption">{formatDateTime(d.createdAt)}</MDTypography>
                        </div>
                        <div style={{ lineHeight: 1 }}>
                          <MDTypography variant="caption">Issued by</MDTypography>
                        </div>
                        <div style={{ lineHeight: 1 }}>
                          <MDTypography variant="caption">{d.issuerUser && displayName(d.issuerUser)}</MDTypography>
                        </div>
                      </TableCell>
                      {hasAccess(["invoice.update", "invoice.delete", "invoice.view", "invoice.sendToClient", "invoice.void", "invoice.pay"], user) && (
                        <TableCell align="right" padding="checkbox" size="small">
                          <MDActionMenu
                            items={actionItems}
                            handleUpdate={hasAccess(["invoice.update"], user) && !FINAL_STATUSES.includes(d.status) ? handleClickUpdate(d) : null}
                            handleDelete={hasAccess(["invoice.delete"], user) && STATUS_ALLOW_DELETE.includes(d.status) && (d.issuerUserId === user.id || d.managedByUserId === user.id) ? handleClickDelete(d) : null}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </MDTablePagination>
          {hasAccess(["invoice.create"], user) && (
            <MDFab color="primary" onClick={() => setOpenForm({})}>
              <AddIcon />
            </MDFab>
          )}
          {openForm !== null && (
            <InvoiceForm userId={openForm.userId} hideClient={!!openForm.userId} data={openForm} onClose={() => setOpenForm(null)} onCreate={handleCreate} />
          )}
          {openDelete && (
            <MDAlertDialog onClose={() => setOpenDelete(null)} title="Delete Invoice?" content={
              <>
                <MDBox>
                  <MDTypography variant="h5">Invoice number {openDelete.invoiceNumber || ""}</MDTypography>
                  <MDTypography variant="body2">will be deleted permanently.</MDTypography>
                </MDBox>
              </>
            }
              onSubmit={handleSubmitDelete}
              submitText="Delete"
            />
          )}
          {sendToClientData && (
            <MDAlertDialog onClose={() => setSendToClientData(null)} title="Send Invoice?" content={
              <>
                <MDBox>
                  <MDTypography variant="h5">Invoice number {sendToClientData.invoiceNumber || ""}</MDTypography>
                  <MDTypography variant="body2">will be sent to {sendToClientData.user.email}.</MDTypography>
                  {sendToClientData.lastSentAt && (
                    <MDTypography variant="body2" fontWeight="bold">Last email was sent at {formatDateTime(sendToClientData.lastSentAt)}.</MDTypography>
                  )}
                  <MDTypography variant="body2">If the client don't have an account, system will send the invitation link in the email as well.</MDTypography>
                  <MDTypography variant="body2">This action is not reversible.</MDTypography>
                </MDBox>
              </>
            }
              onSubmit={handleSubmitSendToClient}
              submitText="Send"
            />
          )}
          {voidInvoiceData && (
            <MDAlertDialog onClose={() => setVoidInvoiceData(null)} title="Void Invoice?" content={
              <>
                <MDBox>
                  <MDTypography variant="h5">Invoice number {voidInvoiceData.invoiceNumber || ""}</MDTypography>
                  <MDTypography variant="body2">will be voided. System will notify client on email.</MDTypography>
                  <MDTypography variant="body2">This action is not reversible.</MDTypography>
                </MDBox>
              </>
            }
              onSubmit={handleVoidInvoice}
              submitText="Void"
            />
          )}
          {markAsPaidData && (
            <MDAlertDialog onClose={() => setMarkAsPaidData(null)} title="Mark Invoice as Paid?" content={
              <>
                <MDBox>
                  <MDTypography variant="h5">Invoice number {markAsPaidData.invoiceNumber || ""}</MDTypography>
                  <MDTypography variant="body2">will be marked as Paid. System will notify client on email.</MDTypography>
                  <MDTypography variant="body2">This action is not reversible.</MDTypography>
                </MDBox>
              </>
            }
              onSubmit={handleSubmitMarkAsPaid}
              submitText="Mark as Paid"
            />
          )}
        </Grid>
      </Grid>
      {loadingPdf && (
        <Dialog open maxWidth="xs" disableEscapeKeyDown>
          <DialogContent>
            <MDTypography variant="button" fontWeight="bold">Generating PDF</MDTypography>
          </DialogContent>
        </Dialog>
      )}
      {openAmountDetail && (
        <Dialog open maxWidth="xs" fullWidth disableEscapeKeyDown onClose={() => setOpenAmountDetail(null)}>
          <DialogContent>
            <MDBox display="flex" justifyContent="space-between" alignInvoices="center" bgColor="white">
              <div>
                <MDTypography variant="body2">Amount:</MDTypography>
              </div>
              <div>
                <MDTypography variant="body2">{formatter.fullCurrency(openAmountDetail.amount)}</MDTypography>
              </div>
            </MDBox>
            {openAmountDetail.discountPercentageAmount && (
              <MDBox display="flex" justifyContent="space-between" alignInvoices="center" bgColor="white">
                <div>
                  <MDTypography variant="body2">Disc. ({formatter.numberToCommaString(openAmountDetail.discountPercentage)}%):</MDTypography>
                </div>
                <div>
                  <MDTypography variant="body2">{formatter.fullCurrency(openAmountDetail.discountPercentageAmount)}</MDTypography>
                </div>
              </MDBox>
            )}
            {openAmountDetail.discount && (
              <MDBox display="flex" justifyContent="space-between" alignInvoices="center" bgColor="white">
                <div>
                  <MDTypography variant="body2">Disc.:</MDTypography>
                </div>
                <div>
                  <MDTypography variant="body2">{formatter.fullCurrency(openAmountDetail.discount)}</MDTypography>
                </div>
              </MDBox>
            )}
            {openAmountDetail.taxPercentageAmount && (
              <MDBox display="flex" justifyContent="space-between" alignInvoices="center" bgColor="white">
                <div>
                  <MDTypography variant="body2">Tax ({formatter.numberToCommaString(openAmountDetail.taxPercentage)}%):</MDTypography>
                </div>
                <div>
                  <MDTypography variant="body2">{formatter.fullCurrency(openAmountDetail.taxPercentageAmount)}</MDTypography>
                </div>
              </MDBox>
            )}
            <Divider light={false} />
            <MDBox display="flex" justifyContent="space-between" alignInvoices="center" bgColor="white">
              <div>
                <MDTypography variant="body2" fontWeight="bold">Net Amount:</MDTypography>
              </div>
              <div>
                <MDTypography variant="body2" fontWeight="bold">{formatter.fullCurrency(openAmountDetail.netAmount)}</MDTypography>
              </div>
            </MDBox>
          </DialogContent>
          <DialogActions>
            <MDButton variant="gradient" disabled={loading} color="secondary" onClick={() => setOpenAmountDetail(null)}>
              Close
            </MDButton>
          </DialogActions>
        </Dialog>
      )}
    </MDBox>
  );
}

Invoice.propTypes = {
}

export default Invoice