import SessionStoreService from "./session-store-service";
import { API_URL } from "../constants/api";
import { getAxios, postAxios } from "../helpers";

class AuthService {
  async isAuthenticated() {
    let seconds = new Date().getTime() / 1000;
    let hasAuthorization = SessionStoreService.hasAuthorization();
    if (hasAuthorization) {
      let accessToken = SessionStoreService.getAuthorization();
      if (typeof accessToken === "string") {
        accessToken = JSON.parse(accessToken);
      }
      if (accessToken.expiresAt <= seconds) {
        this.logout();
        return false;
      }
      accessToken.lastActivityTime = seconds;
      SessionStoreService.setAuthorization(accessToken);
      return accessToken;
    }
    return hasAuthorization;
  }

  getAuthUser() {
    const url = API_URL.AUTH.GET_MY_PROFILE;
    return getAxios(url);
  }

  updateAuthUser(params) {
    const url = API_URL.AUTH.UPDATE_MY_PROFILE;
    return postAxios(url, params, "put");
  }

  changePassword(params) {
    const url = API_URL.AUTH.CHANGE_PASSWORD;
    return postAxios(url, params, "put");
  }

  login(email, password) {
    const url = API_URL.AUTH.LOGIN;
    return postAxios(url, { email, password });
  }

  register(firstName, lastName, email, password) {
    const url = API_URL.AUTH.REGISTER;
    return postAxios(url, { firstName, lastName, email, password });
  }

  verify(email, code) {
    const url = API_URL.AUTH.VERIFY;
    return postAxios(url, { email, code });
  }

  sendResetPasswordEmail(email) {
    const url = API_URL.AUTH.SEND_RESET_PASSWORD_EMAIL;
    return postAxios(url, { email });
  }
  checkOtpResetPassword(email, code) {
    const url = API_URL.AUTH.CHECK_OTP_RESET_PASSWORD;
    return postAxios(url, { email, code });
  }
  resetPassword(email, password, code) {
    const url = API_URL.AUTH.RESET_PASSWORD;
    return postAxios(url, { email, password, code });
  }

  setSession(res) {
    SessionStoreService.setAuthorization(res.token);
  }

  logout() {
    SessionStoreService.clearAuthorization();
  }

}

export default new AuthService();
