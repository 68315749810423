// @ts-nocheck
import React, { useState } from "react";
import { useDispatch } from "react-redux";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import MDInput from "../../components/MDInput";
import MDInputPassword from "../../components/MDInputPassword";
import MDSnackbar from "../../components/MDSnackbar";


import { UNAUTHENTICATED_ROUTE } from "../../constants/routes";
import { generateGreetings } from "../../helpers";

import { validateEmail } from "../../helpers";
import { login } from "../../reducers/auth";

function SignIn() {
  let dispatch = useDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [input, setInput] = useState({
    email: "",
    password: "",
  })

  const handleChangeInput = (e) => {
    setErrorMessage(null)
    setInput(prev => {
      return {
        ...prev,
        [e.target.name]: e.target.value
      }
    })
  }

  const handleSubmit = async () => {
    setErrorMessage(null)
    if (input.email.trim() === "" || input.password.trim() === "") {
      setErrorMessage("Fill all fields")
      return
    }
    if (!validateEmail(input.email)) {
      setErrorMessage("Please input a valid email")
      return
    }
    try {
      setLoading(true)
      const resp = await dispatch(login(input))
      if (resp?.status && resp?.status === 200) {
        navigate("/")
      }
      setLoading(false)
    } catch (e) {
      setInput(prev => {
        return {
          ...prev,
          password: ''
        }
      })
      setErrorMessage(e.data.message)
      setLoading(false)
    }
  }

  return (
    <>
      {errorMessage && (
        <MDSnackbar
          color="error"
          title={errorMessage}
          open
          close={() => setErrorMessage(null)}
        />
      )}
      <Card>
        <MDBox pt={4} pb={3} px={3}>
          <MDTypography variant="h4" fontWeight="bold" color="dark" mt={1} mb={2}>
            {generateGreetings()},
          </MDTypography>
          <MDBox component="form" role="form" autoComplete="off" noValidate>
            <MDBox mb={2}>
              <MDInput required type="email" error={input.email !== "" && !validateEmail(input.email)} disabled={loading} label="Email" fullWidth value={input?.email || ""} name="email" onChange={handleChangeInput} />
            </MDBox>
            <MDBox mb={0}>
              <MDInputPassword required disabled={loading} label="Password" fullWidth value={input?.password || ""} name="password" onChange={handleChangeInput} />
            </MDBox>
            <MDBox mb={2} textAlign="right">
              <MDTypography
                component={Link}
                to={UNAUTHENTICATED_ROUTE.forgotPassword.route}
                variant="button"
              >
                Forgot password?
              </MDTypography>
            </MDBox>

            <MDBox mt={1} mb={1}>
              <MDButton variant="gradient" disabled={loading || input.email === "" || input.password === ""} color="primary" fullWidth onClick={handleSubmit}>
                sign in
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to={UNAUTHENTICATED_ROUTE.signUp.route}
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </>
  );
}

SignIn.propTypes = {
}

export default SignIn