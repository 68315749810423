import React, { useCallback, useEffect, useState } from "react";
import fbaReportService from "../../services/fba-report-service";
import inventoryPredictionService from "../../services/inventory-prediction";

import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Chip from '@mui/material/Chip';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import PriceChangeIcon from '@mui/icons-material/PriceChange';

// Material Dashboard 2 React components
import MDTablePagination from "../../components/MDTablePagination";
import MDInputTimeout from "../../components/MDInputTimeout";
import MDInput from "../../components/MDInput";
import MDBox from "../../components/MDBox";
import MDFab from "../../components/MDFab";
import MDTypography from "../../components/MDTypography";
import MDActionMenu from "../../components/MDActionMenu";
import MDAlertDialog from "../../components/MDAlertDialog";
import ClientName from "components/ClientName/ClientName";
import moment from "moment";
import InventoryReportDetailPage from "./InventoryReportDetail";

function InventoryReportPage() {
  const [loading, setLoading] = useState(false)
  const [reports, setReports] = useState([])
  const [activeReport, setActiveReport] = useState(null)

  useEffect(() => {
    fbaReportService.getAll().then((res) => {
      setReports(res.data.data)
    })
  }, [setReports, setLoading])

  return <MDBox p={2} bgColor="white">
    <Grid container spacing={1}>
      <Grid item xs={12}>
      <Table stickyHeader sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <MDTypography variant="button" fontWeight="bold">Client</MDTypography>
            </TableCell>
            <TableCell>
              <MDTypography variant="button" fontWeight="bold">Date</MDTypography>
            </TableCell>
            <TableCell>
              <MDTypography variant="button" fontWeight="bold">Status</MDTypography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reports.map(i => {
            return (
              <TableRow key={`data-${i.id}`}>
                <TableCell>
                  <MDTypography variant="body2">
                    <ClientName onClick={() => {
                      setActiveReport(i)
                    }} client={i.user} />
                  </MDTypography>
                </TableCell>
                <TableCell>
                  <MDTypography variant="body2">{moment(i.date).format('YYYY-MM-DD')}</MDTypography>
                </TableCell>
                <TableCell>
                  <MDTypography variant="body2">{i.status || 'Internal Review'}</MDTypography>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      </Grid>
    </Grid>
    {
      activeReport && <InventoryReportDetailPage onClose={() => setActiveReport(null)} report={activeReport} />
    }
  </MDBox>
}

export default InventoryReportPage