// @ts-nocheck
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import qs from "query-string"
import moment from "moment";

// react-router-dom components

// @mui material components
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import InputAdornment from "@mui/material/InputAdornment";
import Chip from '@mui/material/Chip';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RateReviewIcon from '@mui/icons-material/RateReview';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloseIcon from '@mui/icons-material/Close';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

// Material Dashboard 2 React components
import MDTablePagination from "../../components/MDTablePagination";
import MDInputTimeout from "../../components/MDInputTimeout";
import MDInput from "../../components/MDInput";
import MDBox from "../../components/MDBox";
import MDFab from "../../components/MDFab";
import MDTypography from "../../components/MDTypography";
import MDActionMenu from "../../components/MDActionMenu";
import MDAlertDialog from "../../components/MDAlertDialog";
import MDButton from "../../components/MDButton";
import ClientSelector from "../../components/DataSelector/ClientSelect";
import ReviewPlanSelector from "../../components/DataSelector/ReviewPlanSelect";

import { download, deleteById as deletePackingListById, getAll as getAllPackingList, sendToClient as sendPackingListToClient, changeStatus as changeStatusPackingList } from "../../reducers/packingList";
import { hasAccess } from "../../helpers/access";
import { STATUS, FINAL_STATUSES, STATUS_ALLOW_DELETE } from "../../constants/packinglist.constant";
import { STATUS as RP_STATUS } from "../../constants/reviewplan.constant";
import { AUTHENTICATED_ROUTE } from "../../constants/routes";
import PackingListForm from "./PackingListForm";
import { displayName, downloadBlobFile, formatDateTime } from "../../helpers";
import ShipperDetail from "../Shipper/ShipperDetail";
import ClientName from "components/ClientName/ClientName";

let timeout = null

function PackingList() {
  let dispatch = useDispatch()
  const navigate = useNavigate()
  const queryData = qs.parse(window.location.search)
  const user = useSelector(state => state.auth.user)
  const data = useSelector(state => state.packingList.list)

  const [loadingPdf, setLoadingPdf] = useState(false)
  const [viewShipper, setViewShipper] = useState(null)
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState(queryData.q || "")
  const [status, setStatus] = useState(queryData.status || "no-canceled")
  const [userId, setUserId] = useState(null)
  const [reviewPlanId, setReviewPlanId] = useState(null)
  const [openForm, setOpenForm] = useState(null)
  const [openDelete, setOpenDelete] = useState(null)
  const [dateMinInput, setDateMinInput] = useState(null)
  const [dateMaxInput, setDateMaxInput] = useState(null)
  const [dateMin, setDateMin] = useState(null)
  const [dateMax, setDateMax] = useState(null)

  const [submitReviewData, setSubmitReviewData] = useState(null)
  const [sendToClientData, setSendToClientData] = useState(null)
  const [approveData, setApproveData] = useState(null)
  const [rejectData, setRejectData] = useState(null)
  const [rejectStatus, setRejectStatus] = useState(STATUS.new.value)

  const { limit, currentPage } = data.paginator
  const getData = useCallback((page = currentPage, newLimit = limit) => {
    let params = {
      limit: newLimit,
      page,
    }
    if (search !== "") {
      params = {
        ...params,
        q: search
      }
    }
    if (status === "no-canceled") {
      params = {
        ...params,
        notStatus: STATUS.canceled.value
      }
    } else if (status !== "all") {
      params = {
        ...params,
        status
      }
    }
    if (userId) {
      params = {
        ...params,
        userId
      }
    }
    if (dateMin) {
      params = {
        ...params,
        dateMin: moment(dateMin).format("YYYY-MM-DD")
      }
    }
    if (dateMax) {
      params = {
        ...params,
        dateMax: moment(dateMax).format("YYYY-MM-DD")
      }
    }
    setLoading(true)
    dispatch(getAllPackingList(params))
    setLoading(false)
  }, [search, limit, currentPage, status, userId, dateMin, dateMax, dispatch])
  useEffect(() => {
    let params = {
      limit,
      page: 1,
    }
    if (search !== "") {
      params = {
        ...params,
        q: search
      }
    }
    if (status === "no-canceled") {
      params = {
        ...params,
        notStatus: STATUS.canceled.value
      }
    } else if (status !== "all") {
      params = {
        ...params,
        status
      }
    }
    if (userId) {
      params = {
        ...params,
        userId
      }
    }
    if (dateMin) {
      params = {
        ...params,
        dateMin: moment(dateMin).format("YYYY-MM-DD")
      }
    }
    if (dateMax) {
      params = {
        ...params,
        dateMax: moment(dateMax).format("YYYY-MM-DD")
      }
    }
    setLoading(true)
    dispatch(getAllPackingList(params))
    setLoading(false)
  }, [search, limit, status, userId, dateMin, dateMax, dispatch])

  useEffect(() => {
    return () => {
      if (timeout) {
        clearTimeout(timeout)
        timeout = null
      }
    }
  })
  const handleCreate = () => {
    getData()
  }

  const handleSendToClient = (data) => () => {
    setSendToClientData(data)
  }
  const handleSubmitReviewData = (data) => () => {
    setSubmitReviewData(data)
  }
  const handleSubmitSendToClient = async () => {
    if (sendToClientData && sendToClientData.id) {
      return sendPackingListToClient(sendToClientData.id)
    }
    return null
  }
  const handleSubmitReview = async () => {
    if (submitReviewData && submitReviewData.id) {
      return dispatch(changeStatusPackingList(submitReviewData.id, STATUS.review.value))
    }
    return null
  }
  const handleApprove = (data) => () => {
    setApproveData(data)
  }
  const handleSubmitApprove = async () => {
    if (approveData && approveData.id) {
      return dispatch(changeStatusPackingList(approveData.id, STATUS.approved.value))
    }
    return null
  }
  const handleReject = (data) => () => {
    setRejectData(data)
    setRejectStatus(STATUS.new.value)
  }
  const handleSubmitReject = async () => {
    if (rejectData && rejectData.id) {
      return dispatch(changeStatusPackingList(rejectData.id, rejectStatus))
    }
    return null
  }

  const handleChangePaginator = (paginator) => {
    getData(paginator.currentPage, paginator.limit)
  }
  const handleClickUpdate = (data) => () => {
    setOpenForm(data)
  }
  const handleClickDelete = (data) => () => {
    setOpenDelete(data)
  }
  const handleSubmitDelete = () => {
    if (openDelete && openDelete.id) {
      return dispatch(deletePackingListById(openDelete.id))
    }
    return null
  }
  const handleDownloadPackingList = (data) => () => {
    setLoadingPdf(true)
    download(data.id).then(resp => {
      setLoadingPdf(false)
      downloadBlobFile(resp.data, "application/pdf; charset=utf-8", "test.pdf")
    })
  }

  return (
    <MDBox p={2} bgColor="white">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <MDInputTimeout disabled={loading} value={search} onChange={(e) => setSearch(e.target.value)} label="Search" fullWidth />
        </Grid>
        {hasAccess(["reviewPlan.setUser"], user) && (
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <ClientSelector disabled={loading} value={userId} onChange={(e) => setUserId(e.target.value)} label="Client" fullWidth />
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <ReviewPlanSelector disabled={loading} value={reviewPlanId} onChange={(e) => setReviewPlanId(e.target.value)} label="Review Plan ID" fullWidth />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={2}>
          <MDInput select disabled={loading} label="Status" value={status} fullWidth onChange={(e) => setStatus(e.target.value)}>
            <MenuItem value="all">
              All statuses
            </MenuItem>
            <MenuItem value="no-canceled">
              All, except {STATUS.canceled.value.toLowerCase()}
            </MenuItem>
            {Object.values(STATUS).map(s => {
              return (
                <MenuItem value={s.value} key={`status-${s.key}`}>
                  {s.text}
                </MenuItem>
              )
            })}
          </MDInput>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={2}>
          <MobileDatePicker
            label="Ship date (from)"
            inputFormat="DD MMM YYYY"
            value={dateMinInput}
            disabled={loading}
            renderInput={(params) => <MDInput fullWidth {...params} InputProps={{
              endAdornment: dateMin ? (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="No minimum date"
                    edge="end"
                    onClick={() => {
                      setDateMin(null)
                      setDateMinInput(null)
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }} />}
            onChange={(value) => setDateMinInput(value)}
            onAccept={() => setDateMin(dateMinInput)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={2}>
          <MobileDatePicker
            label="Ship date (to)"
            inputFormat="DD MMM YYYY"
            value={dateMaxInput}
            disabled={loading}
            renderInput={(params) => <MDInput fullWidth {...params} InputProps={{
              endAdornment: dateMax ? (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="No maximum date"
                    edge="end"
                    onClick={() => {
                      setDateMax(null)
                      setDateMaxInput(null)
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }} />}
            onChange={(value) => setDateMaxInput(value)}
            onAccept={() => setDateMax(dateMaxInput)}
          />
        </Grid>
        <Grid item xs={12}>
          <MDTablePagination paginator={data.paginator} onChange={handleChangePaginator}>
            <Table stickyHeader sx={{ minWidth: 450 }}>
              <TableHead>
                <TableRow>
                  {hasAccess(["reviewPlan.setUser"], user) && (
                    <TableCell>
                      <MDTypography variant="button" fontWeight="bold">Client</MDTypography>
                    </TableCell>
                  )}
                  <TableCell>
                    <MDTypography variant="button" fontWeight="bold">Shipping Code</MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="button" fontWeight="bold">Shipper</MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="button" fontWeight="bold">Ship Date</MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="button" fontWeight="bold">Status</MDTypography>
                  </TableCell>
                  {(hasAccess(["reviewPlan.view"], user)) && (
                    <TableCell>
                      <MDTypography variant="button" fontWeight="bold">Review Plan</MDTypography>
                    </TableCell>
                  )}
                  <TableCell>
                    <MDTypography variant="button" fontWeight="bold">Last Update</MDTypography>
                  </TableCell>
                  {hasAccess(["packingList.view", "packingList.update", "packingList.delete", "packingList.submitReview", "packingList.changeStatus"], user) && (
                    <TableCell align="right" />
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.data.map(d => {
                  let actionItems = []
                  if (hasAccess(["packingList.view"], user)) {
                    actionItems.push({
                      key: "download_packinglist",
                      text: "Download PDF",
                      icon: <CloudDownloadIcon color="action" />,
                      onClick: handleDownloadPackingList(d)
                    })
                  }
                  if (hasAccess(["packingList.sendToClient"], user) && d.status === STATUS.approved.value) {
                    actionItems.push({
                      key: "send_to_client",
                      text: "Send to Client",
                      icon: <ForwardToInboxIcon color="warning" />,
                      onClick: handleSendToClient(d)
                    })
                  }
                  if (hasAccess(["packingList.submitReview"], user) && d.status === STATUS.new.value) {
                    actionItems.push({
                      key: "submit_review",
                      text: "Submit review",
                      icon: <RateReviewIcon color="warning" />,
                      onClick: handleSubmitReviewData(d)
                    })
                  }
                  if (hasAccess(["packingList.changeStatus"], user) && d.status === STATUS.review.value) {
                    actionItems.push({
                      key: "approve",
                      text: "Approve",
                      icon: <ShoppingCartCheckoutIcon color="success" />,
                      onClick: handleApprove(d)
                    })
                    actionItems.push({
                      key: "cancel",
                      text: "Reject / Request Changes",
                      icon: <RemoveShoppingCartIcon color="error" />,
                      onClick: handleReject(d)
                    })
                  }
                  return (
                    <TableRow key={`data-${d.id}`}>
                      {hasAccess(["reviewPlan.setUser"], user) && (
                        <TableCell>
                          {d.user ? (
                            <React.Fragment>
                              <ClientName showEmail client={d.user} />
                              {/* <div>
                                <MDTypography variant="body2">{displayName(d.user)}</MDTypography>
                              </div>
                              <div>
                                <MDTypography variant="button">{d.user.email}</MDTypography>
                              </div> */}
                            </React.Fragment>
                          ) : "-"}
                        </TableCell>
                      )}
                      <TableCell>
                        <div>
                          <MDTypography variant="body2">Internal: {d.internalShippingCode}</MDTypography>
                        </div>
                        <div>
                          <MDTypography variant="body2">External: {d.externalShippingCode}</MDTypography>
                        </div>
                      </TableCell>
                      <TableCell  valign="top">
                        {d.shipper && (
                          <React.Fragment>
                            <div className="cursor-pointer color-primary" onClick={() => setViewShipper(d.shipper)}>
                              {d.shipper.name}
                            </div>
                          </React.Fragment>
                        )}
                      </TableCell>
                      <TableCell size="small">
                        <MDTypography variant="body2">{formatDateTime(d.shipDate, true, false)}</MDTypography>
                      </TableCell>
                      <TableCell padding="checkbox" size="small">
                        <Chip
                          size="small"
                          color={STATUS[d.status] ? STATUS[d.status].color : 'default'}
                          label={STATUS[d.status] ? STATUS[d.status].text : d.status}
                        />
                        <div style={{ lineHeight: 1, marginTop: 4 }}>
                          <MDTypography variant="caption">Issued by</MDTypography>
                        </div>
                        <div>
                          <MDTypography variant="body2">{d.issuerUser && displayName(d.issuerUser)}</MDTypography>
                        </div>
                      </TableCell>
                      {(hasAccess(["reviewPlan.view"], user)) && (
                        <TableCell>
                          {d.reviewPlan && (
                            <React.Fragment>
                              <div className="flex gap-2" style={{gap: '5px'}}>
                                <Link className="color-primary" to={AUTHENTICATED_ROUTE.reviewPlans.route + "?id=" + d.reviewPlan.id}>{d.reviewPlan ? "#" + d.reviewPlan.id : ""}</Link>
                                <div> / </div>
                                <Chip
                                  size="small"
                                  color={RP_STATUS[d.reviewPlan.status] ? RP_STATUS[d.reviewPlan.status].color : 'default'}
                                  label={RP_STATUS[d.reviewPlan.status] ? RP_STATUS[d.reviewPlan.status].text : d.reviewPlan.status}
                                />
                              </div>
                              {/* <div>
                                <MDButton style={{padding: '0px', minWidth: '0px'}} variant="text" size="small" color="primary" onClick={() => navigate(AUTHENTICATED_ROUTE.reviewPlans.route + "?id=" + d.reviewPlan.id)}>
                                  View detail
                                </MDButton>
                              </div> */}
                            </React.Fragment>
                          )}
                        </TableCell>
                      )}
                      <TableCell>
                        <div>
                          <MDTypography variant="body2">{formatDateTime(d.updatedAt)}</MDTypography>
                        </div>
                        <div style={{ lineHeight: 1 }}>
                          <MDTypography variant="caption">Created at:<br />{formatDateTime(d.createdAt)}</MDTypography>
                        </div>
                      </TableCell>
                      {hasAccess(["packingList.view", "packingList.update", "packingList.delete", "packingList.submitReview", "packingList.changeStatus"], user) && (
                        <TableCell align="right" padding="checkbox" size="small">
                          <MDActionMenu
                            items={actionItems}
                            handleUpdate={hasAccess(["packingList.update"], user) && !FINAL_STATUSES.includes(d.status) ? handleClickUpdate(d) : null}
                            handleDelete={hasAccess(["packingList.delete"], user) && STATUS_ALLOW_DELETE.includes(d.status) && (d.issuerUserId === user.id || d.managedByUserId === user.id) ? handleClickDelete(d) : null}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </MDTablePagination>
          {hasAccess(["packingList.create"], user) && (
            <MDFab color="primary" onClick={() => setOpenForm({})}>
              <AddIcon />
            </MDFab>
          )}
          {openForm !== null && (
            <PackingListForm userId={openForm.userId} hideClient={!!openForm.userId} data={openForm} onClose={() => setOpenForm(null)} onCreate={handleCreate} />
          )}
          {openDelete && (
            <MDAlertDialog onClose={() => setOpenDelete(null)} title="Delete Packing List?" content={
              <>
                <MDBox>
                  <MDTypography variant="h5">Packing List with internal shipping code {openDelete.internalShippingCode || ""}</MDTypography>
                  <MDTypography variant="body2">will be deleted permanently.</MDTypography>
                </MDBox>
              </>
            }
              onSubmit={handleSubmitDelete}
              submitText="Delete"
            />
          )}
          {viewShipper && (
            <ShipperDetail data={viewShipper} onClose={() => setViewShipper(null)} />
          )}
          {sendToClientData && (
            <MDAlertDialog onClose={() => setSendToClientData(null)} title="Send Packing List?" content={
              <>
                <MDBox>
                  <MDTypography variant="h5">Packing List with internal shipping code {sendToClientData.internalShippingCode || ""}</MDTypography>
                  <MDTypography variant="body2">will be sent to {sendToClientData.user.email}.</MDTypography>
                  <MDTypography variant="body2">If the client don't have an account, system will send the invitation link in the email as well.</MDTypography>
                  <MDTypography variant="body2">This action is not reversible.</MDTypography>
                </MDBox>
              </>
            }
              onSubmit={handleSubmitSendToClient}
              submitText="Send"
            />
          )}
          {submitReviewData && (
            <MDAlertDialog onClose={() => setSubmitReviewData(null)} title="Ask to Review Packing List?" content={
              <>
                <MDBox>
                  <MDTypography variant="h5">Packing List with internal shipping code {submitReviewData.internalShippingCode || ""}</MDTypography>
                  <MDTypography variant="body2">will be sent to the person who manage the review plan for review.</MDTypography>
                  <MDTypography variant="body2">This action is not reversible.</MDTypography>
                </MDBox>
              </>
            }
              onSubmit={handleSubmitReview}
              submitText="Send"
            />
          )}
          {approveData && (
            <MDAlertDialog onClose={() => setApproveData(null)} title="Approve Packing List?" content={
              <>
                <MDBox>
                  <MDTypography variant="h5">Packing List with internal shipping code {approveData.internalShippingCode || ""}</MDTypography>
                  <MDTypography variant="body2">will be approved and dispatcher will get the notification.</MDTypography>
                  <MDTypography variant="body2">This action is not reversible.</MDTypography>
                </MDBox>
              </>
            }
              onSubmit={handleSubmitApprove}
              submitText="Approve"
            />
          )}
          {rejectData && (
            <MDAlertDialog onClose={() => setRejectData(null)} title="Reject Packing List?" content={
              <>
                <MDBox mb={2}>
                  <MDTypography variant="h5">Packing List with internal shipping code {rejectData.internalShippingCode || ""}</MDTypography>
                  <MDTypography variant="body2">will be rejected.</MDTypography>
                </MDBox>
                <MDBox>
                  <FormControl>
                    <FormLabel>Please provide the new status for this packing list:</FormLabel>
                    <RadioGroup
                      value={rejectStatus}
                      onChange={(e) => setRejectStatus(e.target.value)}
                    >
                      <FormControlLabel value={STATUS.new.value} control={<Radio />} label="Request some changes" />
                      <FormControlLabel value={STATUS.canceled.value} control={<Radio />} label="Cancel packing list (this is not reversible)" />
                    </RadioGroup>
                  </FormControl>
                </MDBox>
              </>
            }
              onSubmit={handleSubmitReject}
              submitText="Reject"
            />
          )}
        </Grid>
      </Grid>
      {loadingPdf && (
        <Dialog open maxWidth="xs" disableEscapeKeyDown>
          <DialogContent>
            <MDTypography variant="button" fontWeight="bold">Generating PDF</MDTypography>
          </DialogContent>
        </Dialog>
      )}
    </MDBox>
  );
}

PackingList.propTypes = {
}

export default PackingList