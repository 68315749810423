import { forwardRef, useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for MDInput
import MDInput from "../MDInput";

let timeout = null
const MDInputTimeout = forwardRef(({ value, onChange, ...rest }, ref) => {
  const [val, setVal] = useState(value)
  useEffect(() => {
    setVal(value)
  }, [value])

  const handleChange = (e) => {
    setVal(e.target.value)
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }
    timeout = setTimeout(() => onChange(e), 800)
  }
  return (
    <MDInput value={val} {...rest} ref={ref} onChange={handleChange} />
  )
});

// Setting default values for the props of MDInputTimeout
MDInputTimeout.defaultProps = {
};

// Typechecking props for the MDInputTimeout
MDInputTimeout.propTypes = {
  onChange: PropTypes.func
};

export default MDInputTimeout;
