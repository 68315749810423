// @ts-nocheck
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import parsePhoneNumber from 'libphonenumber-js'

// react-router-dom components

// @mui material components
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UnpublishedIcon from '@mui/icons-material/Unpublished';

// Material Dashboard 2 React components
import MDTablePagination from "../../components/MDTablePagination";
import MDInputTimeout from "../../components/MDInputTimeout";
import MDInput from "../../components/MDInput";
import MDBox from "../../components/MDBox";
import MDFab from "../../components/MDFab";
import MDTypography from "../../components/MDTypography";
import MDActionMenu from "../../components/MDActionMenu";
import MDAlertDialog from "../../components/MDAlertDialog";
import MDDisplayAddress from "../../components/MDDisplayAddress";

import { deleteById as deleteUserById, getAll as getAllUser } from "../../reducers/user";
import { hasAccess } from "../../helpers/access";
import UserForm from "./UserForm";
import { displayName } from "../../helpers";
import { ALL_INTERNAL_ROLES, ROLE } from "../../constants/user.constant";


function User() {
  let dispatch = useDispatch()
  const user = useSelector(state => state.auth.user)
  const data = useSelector(state => state.user.list)

  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState("")
  const [roles, setRoles] = useState("all")
  const [status, setStatus] = useState("1")
  const [openForm, setOpenForm] = useState(null)
  const [openDelete, setOpenDelete] = useState(null)

  const { limit, currentPage } = data.paginator
  const getData = useCallback((page = currentPage, newLimit = limit) => {
    let params = {
      q: search,
      limit: newLimit,
      page,
      status,
    }
    if (roles !== "all") {
      params = {
        ...params,
        roles: [roles]
      }
    }
    setLoading(true)
    dispatch(getAllUser(params))
    setLoading(false)
  }, [search, roles, status, limit, currentPage, dispatch])
  useEffect(() => {
    let params = {
      q: search,
      limit,
      page: 1,
      status,
    }
    if (roles !== "all") {
      params = {
        ...params,
        roles: [roles]
      }
    }
    setLoading(true)
    dispatch(getAllUser(params))
    setLoading(false)
  }, [search, roles, status, limit, dispatch])

  const handleCreate = () => {
    getData()
  }

  const handleChangePaginator = (paginator) => {
    getData(paginator.currentPage, paginator.limit)
  }
  const handleClickUpdate = (data) => () => {
    setOpenForm(data)
  }
  const handleClickDelete = (data) => () => {
    setOpenDelete(data)
  }
  const handleSubmitDelete = () => {
    if (openDelete && openDelete.id) {
      return dispatch(deleteUserById(openDelete.id))
    }
    return null
  }

  return (
    <MDBox p={2} bgColor="white">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
          <MDInputTimeout disabled={loading} value={search} onChange={(e) => setSearch(e.target.value)} label="Search" fullWidth />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
          <MDInput select disabled={loading} label="Roles" value={roles} fullWidth onChange={(e) => setRoles(e.target.value)}>
            <MenuItem value="all">
              All roles
            </MenuItem>
            {Object.values(ALL_INTERNAL_ROLES).map((role) => (
              <MenuItem key={role.key} value={role.value}>
                {role.text}
              </MenuItem>
            ))}
          </MDInput>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
          <MDInput select disabled={loading} label="Status" value={status} fullWidth onChange={(e) => setStatus(e.target.value)}>
            <MenuItem value="1">
              Active
            </MenuItem>
            <MenuItem value="0">
              Inactive
            </MenuItem>
          </MDInput>
        </Grid>
        <Grid item xs={12}>
          <MDTablePagination paginator={data.paginator} onChange={handleChangePaginator}>
            <Table stickyHeader sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <MDTypography variant="button" fontWeight="bold">Name</MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="button" fontWeight="bold">Contacts</MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="button" fontWeight="bold">Address</MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="button" fontWeight="bold">Roles</MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="button" fontWeight="bold">Status</MDTypography>
                  </TableCell>
                  {hasAccess(["user.update", "user.delete"], user) && (
                    <TableCell align="right" />
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.data.map(d => {
                  return (
                    <TableRow key={`data-${d.id}`} sx={{ verticalAlign: "top" }}>
                      <TableCell>
                        <MDTypography variant="body2">{displayName(d)}</MDTypography>
                      </TableCell>
                      <TableCell>
                        {d.email && (
                          <MDTypography variant="body2">
                            <Icon><EmailIcon /></Icon> {d.email}
                          </MDTypography>
                        )}
                        {d.phone && (
                          <MDTypography variant="body2">
                            <Icon><LocalPhoneIcon /></Icon> {parsePhoneNumber("+" + d.phone).formatInternational()}
                          </MDTypography>
                        )}
                      </TableCell>
                      <TableCell>
                        <MDDisplayAddress variant="button" data={d} />
                      </TableCell>
                      <TableCell padding="checkbox" size="small">
                        <Stack direction="column" spacing={1}>
                          {d.roles.map(r => {
                            return (
                              <Chip
                                key={`role-${d.id}-${r}`}
                                label={ROLE[r].text}
                              />
                            )
                          })}
                        </Stack>
                      </TableCell>
                      <TableCell padding="checkbox" size="small">
                        <Chip
                          size="small"
                          color={d.isActive ? "success" : "error"}
                          label={d.isActive ? "Active" : "Inactive"}
                          icon={d.isActive ? <CheckCircleIcon /> : <UnpublishedIcon />}
                        />
                      </TableCell>
                      {hasAccess(["user.update", "user.delete"], user) && (
                        <TableCell align="right" padding="checkbox" size="small">
                          {d.id !== user.id && (
                            <MDActionMenu
                              handleUpdate={hasAccess(["user.update"], user) ? handleClickUpdate(d) : null}
                              handleDelete={hasAccess(["user.delete"], user) ? handleClickDelete(d) : null}
                            />
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </MDTablePagination>
          {hasAccess(["user.create"], user) && (
            <MDFab color="primary" onClick={() => setOpenForm({})}>
              <AddIcon />
            </MDFab>
          )}
          {openForm !== null && (
            <UserForm data={openForm} onClose={() => setOpenForm(null)} onCreate={handleCreate} />
          )}
          {openDelete && (
            <MDAlertDialog onClose={() => setOpenDelete(null)} title="Delete User?" content={
              <>
                <MDBox>
                  <MDTypography variant="h5">{displayName(openDelete)}</MDTypography>
                  <MDTypography variant="body2">will be deleted permanently. You need to reassign user to any active review plans, packing lists, items, invoices, etc that use this.</MDTypography>
                </MDBox>
              </>
            }
              onSubmit={handleSubmitDelete}
              submitText="Delete"
            />
          )}
        </Grid>
      </Grid>
    </MDBox>
  );
}

User.propTypes = {
}

export default User