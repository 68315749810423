// @ts-nocheck
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import ReactPhoneInput from 'react-phone-input-material-ui';

// react-router-dom components

// @mui material components
import Grid from "@mui/material/Grid";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDInput from "../../components/MDInput";
import MDButton from "../../components/MDButton";
import MDSnackbar from "../../components/MDSnackbar";
import CountrySelect from "../../components/CountryRegionSelector/CountrySelect";
import StateSelect from "../../components/CountryRegionSelector/StateSelect";

import { updateAuthUser } from "../../reducers/auth"
import { setGlobalSnackbar } from "../../reducers/app";
import AppUpdateAvatar from "components/UpdateAvatar/UpdateAvatar";

function ProfileUserForm({ onClose }) {
  let dispatch = useDispatch()
  const user = useSelector(state => state.auth.user)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [input, setInput] = useState({
    firstName: user.firstName || "",
    lastName: user.lastName || "",
    phone: user.phone || "",
    avatar: user.avatar || null,
    addressFieldOne: user.addressFieldOne || "",
    addressFieldTwo: user.addressFieldTwo || "",
    city: user.city || "",
    district: user.district || "",
    state: user.state || "",
    country: user.country || "",
    postalCode: user.postalCode || "",
  })

  const handleClose = (e, reason = '') => {
    if (reason === 'escapeKeyDown' || reason === 'backdropClick') {
      return
    }
    onClose();
  };

  const handleChangeInput = (e) => {
    setErrorMessage(null)
    setInput(prev => {
      if (e.target.name === "country") {
        prev = {
          ...prev,
          state: null,
        }
      }
      return {
        ...prev,
        [e.target.name]: e.target.value
      }
    })
  }
  const handlePhoneChange = (value) => {
    setErrorMessage(null)
    setInput(prev => {
      return {
        ...prev,
        phone: value
      }
    })
  }
  const handleAvatarChange = (value) => {
    setInput(prev => {
      return {
        ...prev,
        avatar: value
      }
    })
  }
  const handleSubmit = async () => {
    setErrorMessage(null)
    try {
      setLoading(true)
      const resp = await dispatch(updateAuthUser(input))
      dispatch(setGlobalSnackbar({
        open: true,
        title: resp.data.message,
        color: 'success',
        autoHide: true
      }))
      handleClose()
    } catch (e) {
      setLoading(false)
      setErrorMessage(e.data.message)
    }
  }

  return (
    <Dialog onClose={handleClose} open fullWidth maxWidth="sm" disableEscapeKeyDown>
      {errorMessage && (
        <MDSnackbar
          color="error"
          title={errorMessage}
          open
          close={() => setErrorMessage(null)}
        />
      )}
      <DialogTitle>Update Profile</DialogTitle>
      <DialogContent>
        <MDBox mt={1} component="form" role="form" autoComplete="off" noValidate>
          <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
            <Grid item lg={12} md={12} sm={12} mb={2}>
              <div>
                <AppUpdateAvatar onChange={(v) => handleAvatarChange(v)} url={input?.avatar} allowEdit={true} name={input?.firstName} />
              </div>
            </Grid>
            <Grid item md={12} lg={6} xl={6} sm={12} mb={2}>
              <MDInput required type="text" disabled={loading} label="First name" fullWidth value={input?.firstName || ""} name="firstName" onChange={handleChangeInput} />
            </Grid>
            <Grid item md={12} lg={6} xl={6} sm={12} mb={2}>
              <MDInput type="text" disabled={loading} label="Last name" fullWidth value={input?.lastName || ""} name="lastName" onChange={handleChangeInput} />
            </Grid>
          </Grid>
          <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
            <Grid item md={12} lg={6} xl={6} sm={12} mb={2}>
              <MDInput type="email" disabled label="Email" value={user.email} fullWidth />
            </Grid>
            <Grid item md={12} lg={6} xl={6} sm={12} mb={2}>
              <ReactPhoneInput disabled={loading} component={MDInput} country={user?.country ? user?.country.toLowerCase() : 'us'} value={input?.phone || ""} onChange={handlePhoneChange} />
            </Grid>
          </Grid>
          <MDBox mb={2}>
            <MDInput type="text" disabled={loading} label="Address Line 1" value={input?.addressFieldOne || ""} fullWidth name="addressFieldOne" onChange={handleChangeInput} />
          </MDBox>
          <MDBox mb={2}>
            <MDInput type="text" disabled={loading} label="Address Line 2" value={input?.addressFieldTwo || ""} fullWidth name="addressFieldTwo" onChange={handleChangeInput} />
          </MDBox>
          <MDBox mb={2}>
            <MDInput type="text" disabled={loading} label="City" value={input?.city || ""} fullWidth name="city" onChange={handleChangeInput} />
          </MDBox>
          <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
            <Grid item md={12} lg={6} xl={6} sm={12} mb={2}>
              <CountrySelect label="Country" disabled={loading} value={input?.country} name="country" onChange={handleChangeInput} />
            </Grid>
            <Grid item md={12} lg={6} xl={6} sm={12} mb={2}>
              {!!input?.country && (
                <StateSelect label="State" disabled={loading} defaultCountry={input?.country} value={input?.state} name="state" onChange={handleChangeInput} />
              )}
            </Grid>
          </Grid>
          <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
            <Grid item md={12} lg={6} xl={6} sm={12} mb={2}>
              <MDInput type="text" disabled={loading} label="Postal code" value={input?.postalCode || ""} fullWidth name="postalCode" onChange={handleChangeInput} />
            </Grid>
            <Grid item md={12} lg={6} xl={6} sm={12} mb={2}>
              <MDInput type="text" disabled={loading} label="District" value={input?.district || ""} fullWidth name="district" onChange={handleChangeInput} />
            </Grid>
          </Grid>
        </MDBox>
      </DialogContent>
      <DialogActions>
        <MDButton variant="gradient" disabled={loading} color="secondary" onClick={handleClose}>
          Cancel
        </MDButton>
        <MDButton variant="gradient" disabled={loading} color="primary" onClick={handleSubmit}>
          Submit
        </MDButton>
      </DialogActions>

    </Dialog>
  );
}

ProfileUserForm.propTypes = {
  onClose: PropTypes.func.isRequired
}

export default ProfileUserForm