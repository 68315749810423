// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

// Custom styles for MDInput
import MDBox from "../MDBox"

const MDSelect = ({ multiple, label, disabled, options, ...rest }) => {
  return (
    <FormControl fullWidth>
      <InputLabel id={`${label}-label`}>{label}</InputLabel>
      <Select
        labelId={`${label}-label`}
        id={`${label}-id`}
        multiple
        input={<OutlinedInput id={`${label}-id`} label={label} />}
        renderValue={(selected) => {
          if (multiple) {
            return (
              <MDBox sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => {
                  let text = options.find(o => o.value === value)
                  text = text?.text || value
                  return (
                    <Chip key={value} label={text} size="small" />
                  )
                })}
              </MDBox>
            )
          }
          const text = options.find(o => o.value === selected)
          return text || selected
        }}
        {...rest}
      >
        {options.map((opt) => (
          <MenuItem key={opt.key} value={opt.value}>
            {opt.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
};

// Setting default values for the props of MDInput
MDSelect.defaultProps = {
  multiple: false,
  disabled: false,
};

// Typechecking props for the MDInput
MDSelect.propTypes = {
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

export default MDSelect;
