import MDAlertDialog from 'components/MDAlertDialog'
import MDBox from 'components/MDBox'
import TextField from "@mui/material/TextField";
import React, { memo, useEffect, useState } from 'react'
import MDButton from "../../components/MDButton";
import MDInput from 'components/MDInput';

const EditButton = ({ defaultValue, onSubmit, editLabel = 'Edit',  numberOnly = false }) => {
  const [showEditModal, setShowEditModal] = useState(false)
  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    if (showEditModal) {
      setValue(defaultValue)
    }
  }, [showEditModal, setValue, defaultValue])

  return (
    <>
      <div className=''>
        {!!onSubmit && <MDButton variant="text" size="small" color="primary" onClick={() => {
          setShowEditModal(true)
        }}>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>{editLabel}</div>
        </MDButton>}
      </div>
      {showEditModal && (
          <MDAlertDialog
            onClose={() => {
              setShowEditModal(false)
            }}
            submitText="Save"
            onSubmit={() => onSubmit(value)}
            maxWidth="lg"
            closeText="Close"
            content={
              <MDBox>
                {!numberOnly && <TextField
                  autoFocus
                  value={value}
                  onChange={(e) => {
                    setValue(e.target.value)
                  }}
                  rows={3} fullWidth multiline />}
                {
                  numberOnly && <MDInput
                    type="number"
                    fullWidth
                    value={value}
                    name="minDaysOfSupply"
                    onChange={(e) => {
                      setValue(e.target.value)
                    }}
                  />
                }
              </MDBox>
            }
          />
      )}
    </>
  )
}

export default memo(EditButton)
