const config = {
  site: {
    domain: "fancyawesome.com",
    URL: "https://amazonportal.fancyawesome.com/", // dont give "/" at the end
  },

  api: {
    ENDPOINT: "https://amazon-portal-api.fancyawesome.com/api/v1/",
    HEADER_CLIENT_KEY: "",
    HEADER_CLIENT_SECRET: "",
    CONTENT_ENDPOINT: "",
  },
};

export default config;