// @ts-nocheck
import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import ReduxPromise from "redux-promise";
import ReduxThunk from "redux-thunk";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';

import reducers from "./reducers";
import MainRouter from "./routers";

import "./assets/styles/scss-built.css";

const createStoreWithMiddleware = applyMiddleware(
  ReduxPromise,
  ReduxThunk
)(createStore);

ReactDOM.render(
  <LocalizationProvider dateAdapter={AdapterMoment}>
    <Provider store={createStoreWithMiddleware(reducers)}>
      <MainRouter />
    </Provider>
  </LocalizationProvider>,
  document.getElementById("root")
);
