// @ts-nocheck
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

// react-router-dom components

// @mui material components
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import MDButton from '../MDButton';
import MDSnackbar from "../MDSnackbar";
import { setGlobalSnackbar } from "../../reducers/app";

function MDAlertDialog({ title, content, onClose, onSubmit, submitText, closeText = 'Cancel', maxWidth='xs' }) {
  let dispatch = useDispatch()
  const [errorMessage, setErrorMessage] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    setErrorMessage(null)
    setLoading(true)
    try {
      const resp = await onSubmit()
      dispatch(setGlobalSnackbar({
        open: true,
        title: resp.data.message,
        color: 'success',
        autoHide: true
      }))
      setLoading(false)
      onClose()
    } catch (e) {
      console.log(e)
      setErrorMessage(e.data.message)
      setLoading(false)
    }
  }

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth={maxWidth}disableEscapeKeyDown={loading}>
      {errorMessage && (
        <MDSnackbar
          color="error"
          title={errorMessage}
          open
          close={() => setErrorMessage(null)}
        />
      )}
      {title && (
        <DialogTitle>
          {title}
        </DialogTitle>
      )}
      <DialogContent>
        <DialogContentText as="div">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <MDButton variant="gradient" disabled={loading} color="secondary" onClick={onClose}>
          {closeText}
        </MDButton>
        {!!onSubmit && <MDButton variant="gradient" disabled={loading} color="primary" onClick={handleSubmit} autoFocus>
          {submitText}
        </MDButton>}
      </DialogActions>
    </Dialog>
  );
}

MDAlertDialog.defaultProps = {
  submitText: "Submit"
};

// Typechecking props for the MDPagination
MDAlertDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  content: PropTypes.node.isRequired,
  title: PropTypes.string,
  submitText: PropTypes.string,
};

export default MDAlertDialog