// @ts-nocheck
import React from "react";
import PropTypes from "prop-types";

// react-router-dom components

// @mui material components
import TableContainer from "@mui/material/TableContainer";
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';

// Material Dashboard 2 React components
import { DEFAULT_PAGINATOR } from "../../constants/api";

// from paginator, currentPage start from 1, but MUI start from 0
function MDTablePagination({ paginator, onChange, children }) {

  const handleChangePage = (event, newPage) => {
    onChange({
      ...paginator,
      currentPage: newPage + 1
    })
  };

  const handleChangeRowsPerPage = (event) => {
    onChange({
      ...paginator,
      limit: event.target.value,
    })
  };

  return (
    <Paper>
      <TableContainer sx={{ maxHeight: 600 }}>
        {children}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 30, 50, 100]}
        component="div"
        count={paginator.totalItems}
        rowsPerPage={paginator.limit}
        page={paginator.currentPage - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

MDTablePagination.defaultProps = {
  paginator: DEFAULT_PAGINATOR,
};

// Typechecking props for the MDPagination
MDTablePagination.propTypes = {
  paginator: PropTypes.object,
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func,
};

export default MDTablePagination