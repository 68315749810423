import { API_URL } from "../constants/api";
import { getAxios, postAxios } from "../helpers";

class InventoryPredictionService {
  getAll(fbaReportId) {
    let url = API_URL.INVENTORY_PREDICTION.GET_ALL;
    return getAxios(url, { fbaReportId });
  }

  updateById(id, params) {
    const url = API_URL.INVENTORY_PREDICTION.UPDATE_BY_ID.replace(":id", id);
    return postAxios(url, params, "put");
  }

  deleteById(id) {
    const url = API_URL.INVENTORY_PREDICTION.UPDATE_BY_ID.replace(":id", id);
    return postAxios(url, {}, "delete");
  }

  create(params) {
    const url = API_URL.INVENTORY_PREDICTION.CREATE;
    return postAxios(url, params);
  }
}

export default new InventoryPredictionService();