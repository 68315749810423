import { trueOrZero } from "helpers"

export const getReviewPlanReportName = (report) => {
  const path = report.dropboxPath || ''
  const parts = path.split('/')
  return parts[parts.length - 1].replace('.csv', '')
}

export const hasDaysOfSupplyIssue = (client, ip) => {
  let minDaysOfSupply = ip.item?.minDaysOfSupply
  if (!trueOrZero(minDaysOfSupply) && minDaysOfSupply === 0) {
    minDaysOfSupply = client?.minDaysOfSupply
  }
  if (!trueOrZero(minDaysOfSupply)) {
    minDaysOfSupply = 120
  }

  return ip.data?.translatedData?.daysOfSupply > minDaysOfSupply
}

export const ignoreReviewPlanItem = (ip, client) => {
  // const rec1 = Math.round(ip.analysisResult?.calculatedData?.rec1 || 0) || 0
  // const rec2 = Math.round(ip.analysisResult?.calculatedData?.rec2 || 0) || 0
  // const rec3 = Math.round(ip.analysisResult?.calculatedData?.rec3 || 0) || 0
  // const rec4 = Math.round(ip.analysisResult?.calculatedData?.rec4 || 0) || 0

  const recZero = ip.roundedFinalValue === 0
  const issueCount = Object.values(ip.analysisResult.issues || {}).filter(ii => ii).length || 0
  
  return  ip.doNotOrder || hasDaysOfSupplyIssue(client, ip) || (recZero && issueCount <= 1)
}