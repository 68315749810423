import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import theme from "../assets/theme";

import {
  UNAUTHENTICATED_ROUTE,
  AUTHENTICATED_ROUTE,
  AUTHENTICATED_OTHER_ROUTE,
} from "../constants/routes";
import { checkAuth } from "../reducers/auth";
import { initialize, setGlobalSnackbar } from "../reducers/app";
import Page404 from "../pages/Page404";
import Sidenav from "../components/LayoutContainers/Sidenav";
import MDSnackbar from "../components/MDSnackbar";
import { hasAccess } from "../helpers/access";
import DashboardLayout from "../components/LayoutContainers/DashboardLayout";
import BasicLayout from "../components/LayoutContainers/BasicLayout";

const MainRouter = () => {
  const dispatch = useDispatch();
  const appReady = useSelector((state) => state.app.init);
  const globalSnackbar = useSelector((state) => state.app.globalSnackbar);
  const user = useSelector((state) => state.auth.user);
  useEffect(() => {
    dispatch(checkAuth()).then(() => {
      dispatch(initialize());
    });
  }, [dispatch]);

  const closeGlobalSnackbar = () => {
    dispatch(setGlobalSnackbar({
      open: false,
      title: null,
      autoHide: false
    }))
  }

  if (!appReady) {
    return null;
  }

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {user ? (
          <React.Fragment>
            <Sidenav />
            <DashboardLayout>
              <Routes>
                {Object.values(AUTHENTICATED_ROUTE).map((r) => {
                  if (r.userRoleAbilityKeys && r.userRoleAbilityKeys.length > 0) {
                    if (!hasAccess(r.userRoleAbilityKeys, user)) return null
                  }
                  return (
                    <Route
                      exact
                      path={r.route}
                      element={r.component}
                      key={r.route}
                    />
                  );
                })}
                {Object.values(AUTHENTICATED_OTHER_ROUTE).map((r) => {
                  if (r.userRoleAbilityKeys && r.userRoleAbilityKeys.length > 0) {
                    if (!hasAccess(r.userRoleAbilityKeys, user)) return null
                  }
                  return (
                    <Route
                      exact
                      path={r.route}
                      element={r.component}
                      key={r.route}
                    />
                  );
                })}
                <Route
                  path="/"
                  element={
                    <Navigate to={`${AUTHENTICATED_ROUTE.dashboard.route}`} />
                  }
                />
                <Route
                  path="*"
                  element={
                    <Page404 />
                  }
                />
              </Routes>
            </DashboardLayout>
          </React.Fragment>
        ) : (
          <BasicLayout>
            <Routes>
              {Object.values(UNAUTHENTICATED_ROUTE).map((r) => {
                return (
                  <Route
                    exact
                    path={r.route}
                    element={r.component}
                    key={r.route}
                  />
                );
              })}
              <Route
                path="/"
                element={
                  <Navigate to={`${UNAUTHENTICATED_ROUTE.signIn.route}`} />
                }
              />
              <Route
                path="*"
                element={
                  <Page404 showBackButton={true} />
                }
              />
            </Routes>
          </BasicLayout>
        )}

        {globalSnackbar && globalSnackbar.open && (
          <MDSnackbar
            {...globalSnackbar}
            close={closeGlobalSnackbar}
          />
        )}
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default MainRouter;
