// @ts-nocheck
import React from "react";
import PropTypes from "prop-types";

// react-router-dom components

// @mui material components
import Fab from '@mui/material/Fab';

// Material Dashboard 2 React components

import pxToRem from "../../assets/theme/functions/pxToRem";

function MDFab({ children, position, bottom, right, ...rest }) {

  return (
    <Fab color="primary" sx={{
      position,
      bottom,
      right,
      fontSize: pxToRem(20)
    }} {...rest}>
      {children}
    </Fab>
  );
}


MDFab.defaultProps = {
  position: 'fixed',
  right: 16,
  bottom: 16,
};

// Typechecking props for the MDPagination
MDFab.propTypes = {
  children: PropTypes.node.isRequired,
  position: PropTypes.string,
  bottom: PropTypes.number,
  right: PropTypes.number,
};

export default MDFab