import AuthService from "../services/auth-service";
import SessionStoreManager from "../services/session-store-service";

const LOGIN = "auth/LOGIN";
const LOGOUT = "auth/LOGOUT";

const initalState = {
  user: null,
};

const auth = (state = { ...initalState }, { type, payload }) => {
  switch (type) {
    case LOGOUT:
      return {
        user: null,
      };
    case LOGIN:
      return {
        ...state,
        user: payload,
      };
    default:
      return state;
  }
};
export default auth;

export const checkAuth = () => async (dispatch) => {
  const cookieData = await AuthService.isAuthenticated();
  let result = null
  if (cookieData && cookieData?.accessToken) {
    try {
      const resp = await AuthService.getAuthUser();
      if (resp.status && resp.status === 200) {
        dispatch({ type: LOGIN, payload: resp.data.data });
      }
      result = resp
    } catch (e) {
      result = e
    }
  } else {
    SessionStoreManager.askSessionFromTab();
  }
  return result
};
export const login = ({ email, password }) => async (dispatch) => {
  const resp = await AuthService.login(email, password);
  if (resp.status && resp.status === 200) {
    AuthService.setSession(resp.data.data);
    dispatch({ type: LOGIN, payload: resp.data.data.user });
  }
  return resp
};
export const logout = () => async (dispatch) => {
  AuthService.logout();
  dispatch({ type: LOGOUT });
};

export const register = ({ firstName, lastName, email, password }) => {
  return AuthService.register(firstName, lastName, email, password);
};

export const verify = (email, code) => {
  return AuthService.verify(email, code);
};

export const sendResetPasswordEmail = (email) => {
  return AuthService.sendResetPasswordEmail(email);
};
export const checkOtpResetPassword = (email, code) => {
  return AuthService.checkOtpResetPassword(email, code);
};
export const resetPassword = (email, password, code) => {
  return AuthService.resetPassword(email, password, code);
};

export const getMyProfile = () => async (dispatch) => {
  try {
    const resp = await AuthService.getAuthUser();
    if (resp.status && resp.status === 200) {
      dispatch({ type: LOGIN, payload: resp.data.data });
    }
    return resp
  } catch (e) {
    return
  }
};

export const updateAuthUser = (params) => async (dispatch) => {
  const resp = await AuthService.updateAuthUser(params);
  if (resp.status && resp.status === 200) {
    dispatch({ type: LOGIN, payload: resp.data.data });
  }
  return resp
};
export const changePassword = (params) => async (dispatch) => {
  const resp = await AuthService.changePassword(params);
  if (resp.status && resp.status === 200) {
    dispatch({ type: LOGIN, payload: resp.data.data });
  }
  return resp
};