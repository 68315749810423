import { API_URL } from "../constants/api";
import { getAxios, postAxios } from "../helpers";

class ReviewPlanService {

  getAll(params = null) {
    let url = API_URL.REVIEW_PLAN.GET_ALL;
    return getAxios(url, params);
  }

  getById(id) {
    const url = API_URL.REVIEW_PLAN.GET_BY_ID.replace(":id", id);
    return getAxios(url);
  }

  create(params) {
    const url = API_URL.REVIEW_PLAN.CREATE;
    return postAxios(url, params);
  }

  updateById(id, params) {
    const url = API_URL.REVIEW_PLAN.UPDATE_BY_ID.replace(":id", id);
    return postAxios(url, params, "put");
  }

  deleteById(id) {
    const url = API_URL.REVIEW_PLAN.DELETE_BY_ID.replace(":id", id);
    return postAxios(url, {}, "delete");
  }

  download(id) {
    const url = API_URL.REVIEW_PLAN.DOWNLOAD.replace(":id", id);
    return getAxios(url, null, { "Content-Type": "application/pdf" }, { responseType: "blob" });
  }
  downloadExcel(id) {
    const url = API_URL.REVIEW_PLAN.DOWNLOAD_EXCEL.replace(":id", id);
    return getAxios(url, null, { "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }, { responseType: "blob" });
  }
  downloadOrderSheet(id) {
    const url = API_URL.REVIEW_PLAN.DOWNLOAD_ORDER_SHEET.replace(":id", id);
    return getAxios(url, null, { "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }, { responseType: "blob" });
  }
  sendToClient(id) {
    const url = API_URL.REVIEW_PLAN.SEND_TO_CLIENT.replace(":id", id);
    return postAxios(url, {});
  }
  approve(id) {
    const url = API_URL.REVIEW_PLAN.APPROVE.replace(":id", id);
    return postAxios(url, {});
  }
  reject(id, status) {
    const url = API_URL.REVIEW_PLAN.REJECT.replace(":id", id);
    return postAxios(url, { status });
  }

}

export default new ReviewPlanService();
