// @ts-nocheck
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import PropTypes from "prop-types";
import NumberFormat from 'react-number-format';

// react-router-dom components

// @mui material components
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from '@mui/material/IconButton';
import Tooltip from "@mui/material/Tooltip";
import RefreshIcon from '@mui/icons-material/Refresh';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import MDSnackbar from "../../components/MDSnackbar";
import MDInput from "../../components/MDInput";
import MDTypography from "../../components/MDTypography";
import ClientSelector from "../../components/DataSelector/ClientSelect";
import ReviewPlanSelector from "../../components/DataSelector/ReviewPlanSelect";

import { create, updateById, generateNumber } from "../../reducers/invoice"
import { setGlobalSnackbar } from "../../reducers/app";
import { STATUS as RP_STATUS } from "../../constants/reviewplan.constant";
import { formatter } from "../../helpers";
import { invoiceAmountDetail } from "../../helpers/invoice";

function InvoiceForm({ hideClient, data, userId, onClose, onCreate }) {
  let dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const [selectedUserId, setUserId] = useState(userId || null)
  const [input, setInput] = useState({
    reviewPlanId: data.reviewPlanId || null,
    invoiceDate: data.invoiceDate || moment().format("YYYY-MM-DD"),
    invoiceDue: data.invoiceDue || moment().add(30, "days").format("YYYY-MM-DD"),
    invoiceNumber: data.invoiceNumber || "",
    amount: data.amount || "",
    discount: data.discount || "",
    discountPercentage: data.discountPercentage || "",
    taxPercentage: data.taxPercentage || "",
  })
  useEffect(() => {
    (async function () {
      const invoiceNumber = await generateNumber(input.invoiceDate)
      if (invoiceNumber) {
        setInput(prev => {
          return {
            ...prev,
            invoiceNumber: invoiceNumber || ""
          }
        })
      }
    })()
  }, [input.invoiceDate])

  const handleClose = (e, reason = '') => {
    if (reason === 'escapeKeyDown' || reason === 'backdropClick') {
      return
    }
    onClose();
  };

  const handleChangeInput = (e) => {
    setErrorMessage(null)
    setInput(prev => {
      let newState = {
        [e.target.name]: e.target.value
      }
      if (e.target.name === "reviewPlanId" && e.target.data) {
        newState = {
          ...newState,
          amount: e.target.data.totalValue || 0
        }
      }
      return {
        ...prev,
        ...newState,
      }
    })
  }

  const handleSubmit = async () => {
    setErrorMessage(null)
    try {
      setLoading(true)
      let resp = null
      let params = {
        ...input,
      }
      if (data.id) {
        resp = await dispatch(updateById(data.id, params))
      } else {
        resp = await create(params)
        if (onCreate) {
          onCreate(resp)
        }
      }
      dispatch(setGlobalSnackbar({
        open: true,
        title: resp.data.message,
        color: 'success',
        autoHide: true
      }))
      handleClose()
    } catch (e) {
      setLoading(false)
      setErrorMessage(e.data.message)
    }
  }

  const generateInvoiceNumber = useCallback(async () => {
    if (data.id) {
      return
    }
    const invoiceNumber = await generateNumber(input.invoiceDate)
    if (invoiceNumber) {
      setInput(prev => {
        return {
          ...prev,
          invoiceNumber: invoiceNumber || ""
        }
      })
    }
  }, [input.invoiceDate, data.id])

  const amountDetail = invoiceAmountDetail(input)

  return (
    <Dialog onClose={handleClose} open fullWidth maxWidth="xs" disableEscapeKeyDown>
      {errorMessage && (
        <MDSnackbar
          color="error"
          title={errorMessage}
          open
          close={() => setErrorMessage(null)}
        />
      )}
      <DialogTitle>{data.id ? "Update" : "Create"} Invoice</DialogTitle>
      <DialogContent>
        <MDBox mt={1} component="form" role="form" autoComplete="off" noValidate>
          {data.id && (
            <MDBox mb={2}>
              <MDTypography variant="body2" fontWeight="medium" color="dark">
                Invoice Number: {data.invoiceNumber}
              </MDTypography>
            </MDBox>
          )}
          {hideClient ? (
            <MDBox mb={2}>
              <ClientSelector required disabled label="Client" fullWidth value={selectedUserId || null} name="selectedUserId" onChange={(e) => setUserId(e.target.value)} />
            </MDBox>
          ) : (
            <MDBox mb={2}>
              <ClientSelector required disabled={loading} label="Client" fullWidth value={selectedUserId || null} name="selectedUserId" onChange={(e) => setUserId(e.target.value)} />
            </MDBox>
          )}
          {selectedUserId && (
            <MDBox mb={2}>
              <ReviewPlanSelector userId={selectedUserId} required disabled={loading} status={RP_STATUS.approved.value} label={`Receive plan (${RP_STATUS.approved.text} only)`} fullWidth value={input?.reviewPlanId || null} name="reviewPlanId" onChange={handleChangeInput} />
            </MDBox>
          )}
          {input?.reviewPlanId && (
            <React.Fragment>
              <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
                <Grid item md={12} lg={6} xl={6} sm={12} mb={2}>
                  <MobileDatePicker
                    label="Invoice date"
                    inputFormat="DD MMM YYYY"
                    value={input?.invoiceDate}
                    disabled={loading}
                    renderInput={(params) => <MDInput fullWidth {...params} />}
                    onChange={(value) => handleChangeInput({ target: { name: 'invoiceDate', value } })}
                  />
                </Grid>
                <Grid item md={12} lg={6} xl={6} sm={12} mb={2}>
                  <MobileDatePicker
                    label="Invoice due date"
                    inputFormat="DD MMM YYYY"
                    value={input?.invoiceDue}
                    disabled={loading}
                    renderInput={(params) => <MDInput fullWidth {...params} />}
                    onChange={(value) => handleChangeInput({ target: { name: 'invoiceDue', value } })}
                  />
                </Grid>
              </Grid>
              {!data.id && (
                <MDBox mb={2}>
                  <MDInput disabled={loading} label="Invoice number" fullWidth value={input?.invoiceNumber || ""} name="invoiceNumber" onChange={handleChangeInput}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title="Re-generate based on invoice date" placement="top">
                            <IconButton
                              aria-label="Generate"
                              edge="end"
                              onClick={generateInvoiceNumber}
                            >
                              <RefreshIcon />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      )
                    }}
                  />
                </MDBox>
              )}
              <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
                <Grid item md={12} lg={6} xl={6} sm={12} mb={2}>
                  <NumberFormat
                    disabled={loading}
                    label="Amount"
                    fullWidth
                    required
                    name="amount"
                    value={input?.amount || ""}
                    customInput={MDInput}
                    thousandSeparator={true}
                    onValueChange={({ floatValue }) => handleChangeInput({ target: { name: "amount", value: floatValue } })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <MDTypography variant="body2" fontWeight="regular" color="text">
                            $
                          </MDTypography>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item md={12} lg={6} xl={6} sm={12} mb={2}>
                  <NumberFormat
                    disabled={loading}
                    label="Tax"
                    fullWidth
                    decimalScale={0}
                    name="taxPercentage"
                    value={input?.taxPercentage || ""}
                    customInput={MDInput}
                    thousandSeparator={true}
                    onValueChange={({ floatValue }) => handleChangeInput({ target: { name: "taxPercentage", value: floatValue } })}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <MDTypography variant="body2" fontWeight="regular" color="text">
                            %
                          </MDTypography>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
                <Grid item md={12} lg={6} xl={6} sm={12} mb={2}>
                  <NumberFormat
                    disabled={loading}
                    label="Discount"
                    fullWidth
                    decimalScale={0}
                    name="discountPercentage"
                    value={input?.discountPercentage || ""}
                    customInput={MDInput}
                    thousandSeparator={true}
                    onValueChange={({ floatValue }) => handleChangeInput({ target: { name: "discountPercentage", value: floatValue } })}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <MDTypography variant="body2" fontWeight="regular" color="text">
                            %
                          </MDTypography>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item md={12} lg={6} xl={6} sm={12} mb={2}>
                  <NumberFormat
                    disabled={loading}
                    label="Fixed Discount"
                    fullWidth
                    name="discount"
                    value={input?.discount || ""}
                    customInput={MDInput}
                    thousandSeparator={true}
                    onValueChange={({ floatValue }) => handleChangeInput({ target: { name: "discount", value: floatValue } })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <MDTypography variant="body2" fontWeight="regular" color="text">
                            $
                          </MDTypography>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>

              <MDBox mb={2}>
                <MDBox display="flex" alignItems="center" color="white" justifyContent="space-between">
                  <div>
                    <MDTypography variant="body2" fontWeight="regular" color="dark">
                      Amount:
                    </MDTypography>
                  </div>
                  <div>
                    <MDTypography variant="body2" fontWeight="regular" color="dark">
                      {formatter.fullCurrency(amountDetail.amount)}
                    </MDTypography>
                  </div>
                </MDBox>
                <MDBox display="flex" alignItems="center" color="white" justifyContent="space-between">
                  <div>
                    <MDTypography variant="body2" fontWeight="regular" color="dark">
                      Discount {amountDetail.discountPercentage ? `(${formatter.numberToCommaString(amountDetail.discountPercentage)} %)` : ''}:
                    </MDTypography>
                  </div>
                  <div>
                    <MDTypography variant="body2" fontWeight="regular" color="dark">
                      {formatter.fullCurrency(amountDetail.discountPercentageAmount)}
                    </MDTypography>
                  </div>
                </MDBox>
                <MDBox display="flex" alignItems="center" color="white" justifyContent="space-between">
                  <div>
                    <MDTypography variant="body2" fontWeight="regular" color="dark">
                      Fixed discount:
                    </MDTypography>
                  </div>
                  <div>
                    <MDTypography variant="body2" fontWeight="regular" color="dark">
                      {formatter.fullCurrency(amountDetail.discount)}
                    </MDTypography>
                  </div>
                </MDBox>
                <MDBox display="flex" alignItems="center" color="white" justifyContent="space-between">
                  <div>
                    <MDTypography variant="body2" fontWeight="regular" color="dark">
                      Tax {amountDetail.taxPercentage ? `(${formatter.numberToCommaString(amountDetail.taxPercentage)} %)` : ''}:
                    </MDTypography>
                  </div>
                  <div>
                    <MDTypography variant="body2" fontWeight="regular" color="dark">
                      {formatter.fullCurrency(amountDetail.taxPercentageAmount)}
                    </MDTypography>
                  </div>
                </MDBox>
                <Divider light={false} sx={{ marginTop: 1, marginBottom: 1 }} />
                <MDBox display="flex" alignItems="center" color="white" justifyContent="space-between">
                  <div>
                    <MDTypography variant="body2" fontWeight="bold" color="dark">
                      Net Amount:
                    </MDTypography>
                  </div>
                  <div>
                    <MDTypography variant="body2" fontWeight="bold" color="dark">
                      {formatter.fullCurrency(amountDetail.netAmount)}
                    </MDTypography>
                  </div>
                </MDBox>
              </MDBox>
            </React.Fragment>
          )}
        </MDBox>
      </DialogContent>
      <DialogActions>
        <MDButton variant="gradient" disabled={loading} color="secondary" onClick={handleClose}>
          Cancel
        </MDButton>
        <MDButton variant="gradient" disabled={loading} color="primary" onClick={handleSubmit}>
          Submit
        </MDButton>
      </DialogActions>

    </Dialog>
  );
}

InvoiceForm.defaultProps = {
  userId: null,
  data: {
    id: null,
    reviewPlanId: "",
    invoiceDate: "",
    invoiceDue: "",
    invoiceNumber: "",
    amount: "",
    discount: "",
    discountPercentage: "",
    taxPercentage: "",
  },
  hideClient: false
};

InvoiceForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  userId: PropTypes.number,
  onCreate: PropTypes.func,
  data: PropTypes.object,
  hideClient: PropTypes.bool,
}

export default InvoiceForm