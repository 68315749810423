import * as countryData from 'country-region-data';

export function getCountryName(countryCode) {
  if (!countryData[countryCode]) return ""
  return countryData[countryCode][0]
}

export function getStateName(countryCode, stateCode) {
  if (!countryData[countryCode]) return ""
  const states = countryData[countryCode][2]
  const findState = states.find(s => s[1] === stateCode)
  if (!findState) return ""
  return findState[0]
}