// @ts-nocheck
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NumberFormat from 'react-number-format';
import qs from "query-string"

// react-router-dom components

// @mui material components
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import InputAdornment from "@mui/material/InputAdornment";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Chip from '@mui/material/Chip';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import AddIcon from '@mui/icons-material/Add';
import InventoryIcon from '@mui/icons-material/Inventory';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

// Material Dashboard 2 React components
import MDTablePagination from "../../components/MDTablePagination";
import MDInput from "../../components/MDInput";
import MDBox from "../../components/MDBox";
import MDFab from "../../components/MDFab";
import MDTypography from "../../components/MDTypography";
import MDActionMenu from "../../components/MDActionMenu";
import MDAlertDialog from "../../components/MDAlertDialog";
import MDButton from "../../components/MDButton";
import ClientSelector from "../../components/DataSelector/ClientSelect";
import ReceiverSelector from "../../components/DataSelector/ReceiverSelect";

import { addPackingList, download, downloadExcel, deleteById as deleteReviewPlanById, getAll as getAllReviewPlan, sendToClient as sendReviewPlanToClient, approve as approveReviewPlan, reject as rejectReviewPlan, downloadOrderSheet } from "../../reducers/reviewPlan";
import { hasAccess } from "../../helpers/access";
import { STATUS, FINAL_STATUSES, STATUS_ALLOW_DELETE } from "../../constants/reviewplan.constant";
import { STATUS as PL_STATUS } from "../../constants/packinglist.constant";
import { AUTHENTICATED_ROUTE } from "../../constants/routes";
import PackingListForm from "../PackingList/PackingListForm";
import ReviewPlanForm from "./ReviewPlanForm";
import ReviewPlanItems from "./ReviewPlanItems";
import { displayName, downloadBlobFile, formatDateTime, formatter } from "../../helpers";
import ReceiverDetail from "../Receiver/ReceiverDetail";
import ClientName from "components/ClientName/ClientName";
import InventoryReportDetailPage from "pages/InventoryReport/InventoryReportDetail";
import moment from "moment";
import { Edit, FileDownload, FileDownloadSharp } from "@mui/icons-material";
import SeeMoreText from "components/SeeMoreText/SeeMoreText";
import { updateById } from "../../reducers/reviewPlan"
import { getReviewPlanReportName } from "helpers/review-plan";


let timeout = null

function ReviewPlan() {
  let dispatch = useDispatch()
  const navigate = useNavigate()
  const queryData = qs.parse(window.location.search)
  const user = useSelector(state => state.auth.user)
  const data = useSelector(state => state.reviewPlan.list)

  const [loadingPdf, setLoadingPdf] = useState(false)
  const [createPackingList, setCreatePackingList] = useState(null)
  const [viewReceiver, setViewReceiver] = useState(null)
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState(queryData.id || "")
  const [searchID, setSearchID] = useState(queryData.id || "")
  const [status, setStatus] = useState(queryData.status || "no-canceled")
  const [receiverId, setReceiverId] = useState(null)
  const [userId, setUserId] = useState(null)
  const [openForm, setOpenForm] = useState(null)
  const [openDelete, setOpenDelete] = useState(null)
  const [openManageItems, setOpenManageItems] = useState(null)

  const [sendToClientData, setSendToClientData] = useState(null)
  const [approveData, setApproveData] = useState(null)
  const [rejectData, setRejectData] = useState(null)
  const [rejectStatus, setRejectStatus] = useState(STATUS.new.value)

  const { limit, currentPage } = data.paginator
  const getData = useCallback((page = currentPage, newLimit = limit) => {
    let params = {
      limit: newLimit,
      page,
    }
    if (searchID !== "") {
      params = {
        ...params,
        id: searchID
      }
    }
    if (status === "no-canceled") {
      params = {
        ...params,
        notStatus: STATUS.canceled.value
      }
    } else if (status !== "all") {
      params = {
        ...params,
        status
      }
    }
    if (userId) {
      params = {
        ...params,
        userId
      }
    }
    if (receiverId) {
      params = {
        ...params,
        receiverId
      }
    }
    setLoading(true)
    dispatch(getAllReviewPlan(params))
    setLoading(false)
  }, [searchID, receiverId, limit, currentPage, status, userId, dispatch])
  useEffect(() => {
    let params = {
      limit,
      page: 1,
    }
    if (searchID !== "") {
      params = {
        ...params,
        id: searchID
      }
    }
    if (status === "no-canceled") {
      params = {
        ...params,
        notStatus: STATUS.canceled.value
      }
    } else if (status !== "all") {
      params = {
        ...params,
        status
      }
    }
    if (userId) {
      params = {
        ...params,
        userId
      }
    }
    if (receiverId) {
      params = {
        ...params,
        receiverId
      }
    }
    setLoading(true)
    dispatch(getAllReviewPlan(params))
    setLoading(false)
  }, [searchID, receiverId, limit, status, userId, dispatch])
  useEffect(() => {
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }
    timeout = setTimeout(() => {
      setSearchID(search)
    }, 800)
  }, [search])
  useEffect(() => {
    setSearch(queryData.id)
  }, [queryData.id])

  useEffect(() => {
    return () => {
      if (timeout) {
        clearTimeout(timeout)
        timeout = null
      }
    }
  })
  const handleCreate = () => {
    getData()
  }
  const handleCreatePackingList = (resp) => {
    dispatch(addPackingList(resp))
  }

  const handleSendToClient = (data) => () => {
    setSendToClientData(data)
  }
  const handleSubmitSendToClient = async () => {
    if (sendToClientData && sendToClientData.id) {
      return dispatch(sendReviewPlanToClient(sendToClientData.id))
    }
    return null
  }
  const handleApprove = (data) => () => {
    setApproveData(data)
  }
  const handleSubmitApprove = async () => {
    if (approveData && approveData.id) {
      return dispatch(approveReviewPlan(approveData.id))
    }
    return null
  }
  const handleReject = (data) => () => {
    setRejectData(data)
    setRejectStatus(STATUS.new.value)
  }
  const handleSubmitReject = async () => {
    if (rejectData && rejectData.id) {
      return dispatch(rejectReviewPlan(rejectData.id, rejectStatus))
    }
    return null
  }

  const handleChangePaginator = (paginator) => {
    getData(paginator.currentPage, paginator.limit)
  }
  const handleClickUpdate = (data) => () => {
    setOpenForm(data)
  }
  const handleClickDelete = (data) => () => {
    setOpenDelete(data)
  }
  const handleSubmitDelete = () => {
    if (openDelete && openDelete.id) {
      return dispatch(deleteReviewPlanById(openDelete.id))
    }
    return null
  }
  const handleManageItems = (data) => () => {
    setOpenManageItems(data)
  }
  const handleDownloadReviewPlan = (data) => () => {
    setLoadingPdf(true)
    download(data.id).then(resp => {
      setLoadingPdf(false)
      downloadBlobFile(resp.data, "application/pdf; charset=utf-8", "plan.pdf")
    })
  }
  const handleDownloadReviewPlanExcel = (data) => () => {
    console.log('handleDownloadReviewPlanExcel')
    const { user } = data
    const fileName = `${(user.companyName || user.firstName)?.split(' ').join('-')}-review-plan-raw-data-${moment(data.date).format('YYYY-MM-DD')}.xlsx`
    setLoadingPdf(true)
    downloadExcel(data.id).then(resp => {
      setLoadingPdf(false)
      downloadBlobFile(resp.data, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8", fileName)
    })
  }
  const handleDownloadReviewPlanOrderSheet = (data) => () => {
    const { user } = data
    const fileName = `${(user.companyName || user.firstName)?.split(' ').join('-')}-review-plan-${moment(data.date).format('YYYY-MM-DD')}.xlsx`
    setLoadingPdf(true)
    downloadOrderSheet(data.id).then(resp => {
      setLoadingPdf(false)
      downloadBlobFile(resp.data, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8", fileName)
    })
  }

  return (
    <MDBox display="flex" justifyContent="space-between" alignReviewPlans="center" p={2} bgColor="white">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={2}>
          <NumberFormat
            disabled={loading}
            label="Search ID"
            fullWidth
            value={search}
            customInput={MDInput}
            thousandSeparator={true}
            onValueChange={({ floatValue }) => setSearch(floatValue)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MDTypography variant="body2" fontWeight="regular" color="text">
                    #
                  </MDTypography>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {hasAccess(["reviewPlan.setUser"], user) && (
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <ClientSelector disabled={loading} value={userId} onChange={(e) => setUserId(e.target.value)} label="Client" fullWidth />
          </Grid>
        )}
        {/* <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <ReceiverSelector userId={userId} disabled={loading} value={receiverId} onChange={(e) => setReceiverId(e.target.value)} label="Receiver" fullWidth />
        </Grid> */}
        <Grid item xs={12} sm={12} md={6} lg={3} xl={2}>
          <MDInput select disabled={loading} label="Status" value={status} fullWidth onChange={(e) => setStatus(e.target.value)}>
            <MenuItem value="all">
              All statuses
            </MenuItem>
            <MenuItem value="no-canceled">
              All, except {STATUS.canceled.value.toLowerCase()}
            </MenuItem>
            {Object.values(STATUS).map(s => {
              return (
                <MenuItem value={s.value} key={`status-${s.key}`}>
                  {s.text}
                </MenuItem>
              )
            })}
          </MDInput>
        </Grid>
        <Grid item xs={12}>
          <MDTablePagination paginator={data.paginator} onChange={handleChangePaginator}>
            <Table stickyHeader sx={{ minWidth: 450 }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <div className="no-wrap">
                      <MDTypography variant="button" fontWeight="bold">ID</MDTypography>
                    </div>
                  </TableCell>
                  {hasAccess(["reviewPlan.setUser"], user) && (
                    <TableCell>
                      <MDTypography variant="button" fontWeight="bold">Report Name</MDTypography>
                    </TableCell>
                  )}
                  {!hasAccess(["reviewPlan.setUser"], user) && (
                    <TableCell>
                      <MDTypography variant="button" fontWeight="bold">Review Plan</MDTypography>
                    </TableCell>
                  )}
                  {/* <TableCell>
                    <MDTypography variant="button" fontWeight="bold">Receiver</MDTypography>
                  </TableCell> */}
                  <TableCell>
                    <MDTypography variant="button" fontWeight="bold">Status</MDTypography>
                  </TableCell>
                  {/* <TableCell>
                    <MDTypography variant="button" fontWeight="bold">Total Value</MDTypography>
                  </TableCell> */}
                  {/* {(hasAccess(["packingList.view"], user)) && (
                    <TableCell>
                      <MDTypography variant="button" fontWeight="bold">Packing List</MDTypography>
                    </TableCell>
                  )} */}
                  {/* <TableCell>
                    <MDTypography variant="button" fontWeight="bold">Internal Note</MDTypography>
                  </TableCell> */}
                  {/* <TableCell>
                    <MDTypography variant="button" fontWeight="bold">
                      {hasAccess(["reviewPlan.setUser"], user) ? "Note To Client" : "Note From FA Team"}
                    </MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="button" fontWeight="bold">
                      {hasAccess(["reviewPlan.setUser"], user) ? "Client Note" : "Your Note"}
                    </MDTypography>
                  </TableCell> */}
                  <TableCell>
                    <MDTypography variant="button" fontWeight="bold">Last Update</MDTypography>
                  </TableCell>
                  {hasAccess(["reviewPlan.view", "reviewPlan.update", "reviewPlan.delete", "reviewPlan.approve", "reviewPlan.cancel"], user) && (
                    <TableCell align="right" />
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.data.map(d => {
                  let actionItems = []
                  if (hasAccess(["reviewPlanItem.view"], user)) {
                    actionItems.push({
                      key: "manage_items",
                      text: "Review Products",
                      icon: <InventoryIcon color="warning" />,
                      onClick: handleManageItems(d)
                    })
                  }
                  if (hasAccess(["reviewPlan.setUser"], user)) {
                    actionItems.push({
                      key: "download_order_sheet_excel",
                      text: "Download Order Sheet",
                      icon: <CloudDownloadIcon color="success" />,
                      onClick: handleDownloadReviewPlanOrderSheet(d)
                    })
                  }
                  if (hasAccess(["reviewPlan.sendToClient"], user) && d.status === STATUS.new.value) {
                    actionItems.push({
                      key: "send_to_client",
                      text: "Send to Client",
                      icon: <ForwardToInboxIcon color="warning" />,
                      onClick: handleSendToClient(d)
                    })
                  }
                  if (hasAccess(["reviewPlanItem.view"], user)) {
                    // actionItems.push({
                    //   key: "download_reviewplan",
                    //   text: "Download PDF",
                    //   icon: <CloudDownloadIcon color="error" />,
                    //   onClick: handleDownloadReviewPlan(d)
                    // })
                    actionItems.push({
                      key: "download_reviewplan_excel",
                      text: hasAccess(["reviewPlan.setUser"], user) ? "Download Raw Data" : "Download Excel",
                      icon: <CloudDownloadIcon color="success" />,
                      onClick: handleDownloadReviewPlanExcel(d)
                    })
                  }
                  if (hasAccess(["reviewPlan.approve"], user) && d.status !== STATUS.new.value && !FINAL_STATUSES.includes(d.status)) {
                    actionItems.push({
                      key: "approve",
                      text: "Approve",
                      icon: <ShoppingCartCheckoutIcon color="success" />,
                      onClick: handleApprove(d)
                    })
                  }
                  if (hasAccess(["reviewPlan.cancel"], user) && d.status !== STATUS.new.value && !FINAL_STATUSES.includes(d.status)) {
                    actionItems.push({
                      key: "cancel",
                      text: "Reject / Request Changes",
                      icon: <RemoveShoppingCartIcon color="error" />,
                      onClick: handleReject(d)
                    })
                  }
                  return (
                    <TableRow key={`data-${d.id}`}>
                      <TableCell align="left" padding="checkbox" size="small">
                        <MDTypography variant="body2">{d.id}</MDTypography>
                      </TableCell>
                      {hasAccess(["reviewPlan.setUser"], user) && (
                        <TableCell>
                          <div style={{marginBottom: '10px'}}>{getReviewPlanReportName(d)}</div>
                          {d.user ? (
                            <React.Fragment>
                              <ClientName showEmail client={d.user} />
                            </React.Fragment>
                          ) : "-"}
                        </TableCell>
                      )}
                      {!hasAccess(["reviewPlan.setUser"], user) && (
                        <TableCell>
                          {`${d.user?.companyName} Review Plan ${moment(d.date).format("MM/DD/YYYY")}`}
                        </TableCell>
                      )}
                      <TableCell padding="checkbox" size="small">
                        <Chip
                          size="small"
                          color={STATUS[d.status] ? STATUS[d.status].color : 'default'}
                          label={STATUS[d.status] ? STATUS[d.status].text : d.status}
                        />
                        {d.managedByUser && <div className="no-wrap" style={{ lineHeight: 1, marginTop: 4 }}>
                          <MDTypography variant="caption">Managed by {displayName(d.managedByUser)}</MDTypography>
                        </div>}
                      </TableCell>

                      <TableCell>
                        <div style={{ lineHeight: 2 }}>
                          {formatDateTime(d.updatedAt)}
                        </div>
                        <div style={{ lineHeight: 1 }}>
                          <MDTypography variant="caption">Created: {formatDateTime(d.createdAt, true, false)}</MDTypography>
                        </div>
                      </TableCell>
                      {hasAccess(["reviewPlan.view", "reviewPlan.update", "reviewPlan.delete", "reviewPlan.approve", "reviewPlan.cancel"], user) && (
                        <TableCell align="right" padding="checkbox" size="small">
                          <MDActionMenu
                            items={actionItems}
                            handleUpdate={hasAccess(["reviewPlan.update"], user) && user.id !== d.userId ? handleClickUpdate(d) : null}
                            handleDelete={hasAccess(["reviewPlan.delete"], user) && user.id !== d.userId ? handleClickDelete(d) : null}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </MDTablePagination>
          {hasAccess(["reviewPlan.create"], user) && (
            <MDFab color="primary" onClick={() => setOpenForm({})}>
              <AddIcon />
            </MDFab>
          )}
          {openForm !== null && (
            <ReviewPlanForm data={openForm} onClose={() => setOpenForm(null)} onCreate={handleCreate} />
          )}
          {openDelete && (
            <MDAlertDialog onClose={() => setOpenDelete(null)} title="Delete Review Plan?" content={
              <>
                <MDBox>
                  <MDTypography variant="h5">Review Plan #{openDelete.id || ""}</MDTypography>
                  <MDTypography variant="body2">will be deleted permanently.</MDTypography>
                </MDBox>
              </>
            }
              onSubmit={handleSubmitDelete}
              submitText="Delete"
            />
          )}
          {viewReceiver && (
            <ReceiverDetail data={viewReceiver} onClose={() => setViewReceiver(null)} />
          )}
          {openManageItems !== null && (
            <InventoryReportDetailPage
              onDownloadRawData={handleDownloadReviewPlanExcel(openManageItems)}
              onDownloadOrderSheet={handleDownloadReviewPlanOrderSheet(openManageItems)}
              onSendToClient={handleSendToClient(openManageItems)}
              onClose={() => setOpenManageItems(null)}
              report={openManageItems}
              reportsList={data?.data || []}
            />
          )}
          {sendToClientData && (
            <MDAlertDialog onClose={() => setSendToClientData(null)} title="Send Review Plan?" content={
              <>
                <MDBox>
                  <MDTypography variant="h5">Review Plan #{sendToClientData.id || ""}</MDTypography>
                  <MDTypography variant="body2">will be sent to {sendToClientData.user.email} and the review plan status will be updated.</MDTypography>
                  <MDTypography variant="body2">If the client don't have an account, system will send the invitation link in the email as well.</MDTypography>
                  <MDTypography variant="body2">This action is not reversible.</MDTypography>
                </MDBox>
              </>
            }
              onSubmit={handleSubmitSendToClient}
              submitText="Send"
            />
          )}
          {approveData && (
            <MDAlertDialog onClose={() => setApproveData(null)} title="Approve Review Plan?" content={
              <>
                <MDBox>
                  <MDTypography variant="h5">Review Plan #{approveData.id || ""}</MDTypography>
                  <MDTypography variant="body2">will be approved and we will start processing your review plan immediately.</MDTypography>
                  <MDTypography variant="body2">This action is not reversible.</MDTypography>
                </MDBox>
              </>
            }
              onSubmit={handleSubmitApprove}
              submitText="Approve"
            />
          )}
          {rejectData && (
            <MDAlertDialog onClose={() => {
              setRejectData(null)
              getData()
            }} title="Request Change?" content={
              <>
                <MDBox mb={2}>
                  <MDTypography variant="h5">Review Plan #{rejectData.id || ""}</MDTypography>
                  <MDTypography variant="body2">will be sent back to FA team.</MDTypography>
                </MDBox>
                {/* <MDBox>
                  <FormControl>
                    <FormLabel>Please provide the new status for this review plan:</FormLabel>
                    <RadioGroup
                      value={rejectStatus}
                      onChange={(e) => setRejectStatus(e.target.value)}
                    >
                      <FormControlLabel value={STATUS.new.value} control={<Radio />} label="Request some changes" />
                      <FormControlLabel value={STATUS.canceled.value} control={<Radio />} label="Cancel / void this review plan (this is not reversible)" />
                    </RadioGroup>
                  </FormControl>
                </MDBox> */}
              </>
            }
              onSubmit={handleSubmitReject}
              submitText="Send"
            />
          )}
        </Grid>
      </Grid>
      {loadingPdf && (
        <Dialog open maxWidth="xs" disableEscapeKeyDown>
          <DialogContent>
            <MDTypography variant="button" fontWeight="bold">Generating file, please wait...</MDTypography>
          </DialogContent>
        </Dialog>
      )}
      {createPackingList && (
        <PackingListForm userId={createPackingList.userId} data={{ reviewPlanId: createPackingList.reviewPlanId }} onClose={() => setCreatePackingList(null)} onCreate={handleCreatePackingList} />
      )}
    </MDBox>
  );
}

ReviewPlan.propTypes = {
}

export default ReviewPlan