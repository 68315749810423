import AppAvatar from "components/AppAvatar/Avatar";
import { displayName } from "helpers";
import React, { useState } from "react";
import { Link } from "react-router-dom";

function ClientName({ client, showEmail = false, unlimitedWidth = false, link = undefined, onClick = undefined }) {
  const e = <div onClick={() => {
    if (onClick) {
      onClick()
    }
  }} className={`client-name-inline ${onClick ? 'cursor-pointer' : ''}`}>
    <div className="client-avatar">
      <AppAvatar url={client.avatar} name={client.firstName} />
    </div>
    <div className="client-name__info">
      <div className="client-name__name">{displayName(client)}</div>
    </div>
  </div>

  return <>
    {
      link && <Link to={link}>{e}</Link>
    }
    {
      !link && e
    }
    {showEmail && <div className="client-name__email">{client.email}</div>}
  </>
}

export default ClientName