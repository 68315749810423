import InventoryPredictionService from "../services/inventory-prediction";

const GET_ALL = 'inventoryPrediction/GET_ALL'
const UPDATE = 'inventoryPrediction/UPDATE'
const DELETE = 'inventoryPrediction/DELETE'
const CREATE = 'inventoryPrediction/CREATE'

const initalState = {
  list: {
    data: [],
  },
}

const inventoryPrediction = (state = initalState, {type, payload}) => {
  switch (type) {
    case GET_ALL:
      return {
        ...state,
        list: {
          data: payload.data,
        },
      }
    case CREATE:
      return {
        ...state,
        list: {
          data: [payload.data, ...state.list.data],
        },
      }
    case UPDATE:
      return {
        ...state,
        list: {
          data: state.list.data.map(item => {
            if (item.id === payload.data.id) {
              return {
                ...item,
                ...payload.data,
              }
            }
            return item
          }),
        },
      }
      case DELETE:
      return {
        ...state,
        list: {
          data: state.list.data.filter(item => {
            if (item.id === payload.data.id) {
              return false
            }
            return true
          }),
        },
      }
    case 'item/UPDATE':
      return {
        ...state,
        list: {
          data: state.list.data.map(item => {
            if (item.itemId === payload.data.id) {
              return {
                ...item,
                item: {
                  ...payload.data,
                  ...(payload.data.dimension || {})
                },
              }
            }
            return item
          }),
        },
      }
    default:
      return state
  }
}

export default inventoryPrediction

export const getAll = (reviewPlanId) => async (dispatch) => {
  const resp = await InventoryPredictionService.getAll(reviewPlanId);
  if (resp.status && resp.status === 200) {
    dispatch({ type: GET_ALL, payload: resp.data });
  }
  return resp
};

export const create = (params) => async (dispatch) => {
  const resp = await InventoryPredictionService.create(params);
  if (resp.status && resp.status === 200) {
    dispatch({ type: CREATE, payload: resp.data });
  }
  return resp
}

export const updateById = (id, params) => async (dispatch) => {
  const resp = await InventoryPredictionService.updateById(id, params);
  if (resp.status && resp.status === 200) {
    dispatch({ type: UPDATE, payload: resp.data });
  }
  return resp
}

export const deleteById = (id) => async (dispatch) => {
  const resp = await InventoryPredictionService.deleteById(id);
  if (resp.status && resp.status === 200) {
    dispatch({ type: DELETE, payload: resp.data });
  }
  return resp
}
