// @ts-nocheck
import React from "react";

// react-router-dom components

// @mui material components

// Material Dashboard 2 React components

import ProfileUserSection from "./ProfileUserSection";

function Profile() {

  return (
    <ProfileUserSection />
  );
}

Profile.propTypes = {
}

export default Profile