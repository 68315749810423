// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";


// Material Dashboard 2 React components
import MDTypography from "../../MDTypography";
import MDBox from "../../MDBox";

import PageLayout from "../PageLayout";

import config from "../../../configs"

const logo = config.site.URL + "/logo.png"

function BasicLayout({ image, children }) {
  return (
    <PageLayout>
      <MDBox
        position="absolute"
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            image &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MDBox px={1} width="100%" height="100vh" mx="auto">
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item md={5} lg={6} xl={6} sx={{ textAlign: 'center', display: { xs: 'none', sm: 'none', md: 'block' } }}>
            <img src={logo} alt="logo" />
            <MDTypography variant="h1" fontWeight="bold" color="dark" mt={4}>Amazon Portal</MDTypography>
          </Grid>
          <Grid item xs={11} sm={9} md={5} lg={4} xl={4}>
            {children}
          </Grid>
        </Grid>
      </MDBox>
    </PageLayout>
  );
}

// Typechecking props for the BasicLayout
BasicLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BasicLayout;
