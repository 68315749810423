// @ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

// react-router-dom components

// @mui material components
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from "@mui/material/IconButton";
import Icon from '@mui/material/Icon';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

import MoreVertIcon from '@mui/icons-material/MoreVert';

function MDActionMenu({ items, handleUpdate, handleDelete }) {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  if (!!handleUpdate) {
    items = [
      ...items.map(m => {
        return {
          ...m,
          onClick: () => {
            m.onClick()
            setOpen(false)
          }
        }
      }),
      {
        key: "update",
        text: "Update",
        icon: <EditIcon color="info" />,
        onClick: handleUpdate
      }
    ]
  }
  if (!!handleDelete) {
    items = [
      ...items,
      {
        key: "delete",
        text: "Delete",
        icon: <DeleteIcon color="error" />,
        onClick: handleDelete
      }
    ]
  }
  const toggleMenu = () => {
    setOpen((prevOpen) => !prevOpen);
  }
  const handleClose = (event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target)
    ) {
      return;
    }
    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false && anchorRef) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);


  return (
    <>
      <IconButton
        ref={anchorRef}
        size="small"
        disableRipple
        color="inherit"
        variant="contained"
        onClick={toggleMenu}
      >
        <Icon>
          <MoreVertIcon />
        </Icon>
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-start"
        transition
        disablePortal
        style={{ zIndex: 2 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  onKeyDown={handleListKeyDown}
                >
                  {items.map(m => {
                    return (
                      <MenuItem onClick={m.onClick} key={m.key}>
                        {m.icon && (
                          <Icon sx={{ paddingRight: 2, marginTop: -0.5 }}>{m.icon}</Icon>
                        )}
                        {m.text}
                      </MenuItem>
                    )
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

MDActionMenu.defaultProps = {
  items: []
};

// Typechecking props for the MDPagination
MDActionMenu.propTypes = {
  items: PropTypes.array,
  handleUpdate: PropTypes.func,
  handleDelete: PropTypes.func,
};

export default MDActionMenu