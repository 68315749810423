import axios from "axios";
import { API_URL } from "../constants/api";
import { postAxios } from "../helpers";

class FileUploadService {
  async upload(file, folderName, fileName) {
    const uploadUrl = await this.getUploadUrl(folderName, fileName);

    const uploadInfo = uploadUrl.data.data;

    const data = new FormData();
    data.append('file', file, fileName);

    console.log('file Type', file.type);
    console.log('file name', file.name);

    await axios.put(
      uploadInfo.upload_url,
      file,
      {
        headers: {
          'Content-Type': file.type, 'X-Amz-Acl': 'public-read',
        },
        timeout: 240000,
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
      }
    );

    return uploadInfo.access_url;
  }

  async getUploadUrl (folderName, fileName) {
    let url = API_URL.FILE_UPLOAD.STORE;
    return postAxios(url, {
      folderName, fileName
    });
  }
}

export default new FileUploadService()