import React from 'react'

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDInput from "../../../components/MDInput";
import MDBox from "../../../components/MDBox";
import MDAlertDialog from "../../../components/MDAlertDialog";
import { useDispatch, useSelector } from 'react-redux';

import { updateById } from '../../../reducers/inventoryPrediction'

function AddRecommendationModal ({onClose, initData, report}) {
  console.log('initData', initData)
  const user = useSelector(state => state.auth.user)
  const isClient = user.id === report.userId
  const dispatch = useDispatch()

  const recKey = isClient ? 'clientRec' : 'internalRec'
  const commentKey = isClient ? 'clientComment' : 'internalComment'

  const [data, setData] = React.useState({
    [recKey]: initData[recKey],
    [commentKey]: initData[commentKey],
  })
  const [loading, setLoading] = React.useState(false)

  const handleChange = (e, isNumber = false) => {
    console.log('e.target.value', e.target.value)
    setData({
      ...data,
      [e.target.name]: isNumber ? parseInt(e.target.value) : e.target.value,
    })
  }

  const handleSubmit = () => {
    return dispatch(updateById(initData.id, data))
  }

  return (
    <MDAlertDialog onClose={() => onClose()} title="Add Manual Recommendation" content={
      <>
        <MDBox>
          <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
            {isClient && <Grid item md={12} lg={12} xl={12} sm={12} mb={2}>
              <div>Recommendation from FA Team: {initData.internalRec ? initData.internalRec : initData.autoRecommendation}</div>
            </Grid>}
            {!isClient && <Grid item md={12} lg={12} xl={12} sm={12} mb={2}>
              <div>Auto Recommendation: {initData.autoRecommendation ?? 'None'}</div>
            </Grid>}
            <Grid item md={12} lg={12} xl={12} sm={12} mb={2} >
              <MDInput type="text" disabled={loading} label="Your Recommendation" fullWidth value={(data?.[recKey] || data[recKey] === 0) ? data[recKey] : ""} name={recKey} onChange={(e) => handleChange(e, true)} />
            </Grid>
            <Grid item md={12} lg={12} xl={12} sm={12} mb={2}>
              <MDInput type="text" disabled={loading} label="Comment" fullWidth value={data?.[commentKey] || ""} name={commentKey} onChange={handleChange} />
            </Grid>
          </Grid>
        </MDBox>
      </>
    }
      onSubmit={handleSubmit}
      submitText="Submit Recommendation"
    />
  )
}

export default AddRecommendationModal