export function invoiceAmountDetail(data) {
  let totalAmt = data.amount
  if (data.discount) {
    totalAmt = totalAmt - data.discount
  }
  let discPctAmt = null
  if (data.discountPercentage) {
    discPctAmt = data.amount * data.discountPercentage / 100
    totalAmt = totalAmt - discPctAmt
  }
  let taxPctAmt = null
  if (data.taxPercentage) {
    taxPctAmt = data.amount * data.taxPercentage / 100
    totalAmt = totalAmt + taxPctAmt
  }
  return {
    amount: data.amount,
    discount: data.discount,
    discountPercentage: data.discountPercentage,
    taxPercentage: data.taxPercentage,
    discountPercentageAmount: discPctAmt,
    taxPercentageAmount: taxPctAmt,
    netAmount: totalAmt,
  }
}