import { Chip } from '@mui/material';
import React from 'react'
import { STATUS } from "../../../constants/reviewplan.constant";

function ReviewPlanStatus ({reviewPlan}) {
  return <div>
    <Chip
      size="small"
      color={STATUS[reviewPlan.status] ? STATUS[reviewPlan.status].color : 'default'}
      label={STATUS[reviewPlan.status] ? STATUS[reviewPlan.status].text : reviewPlan.status}
    />
  </div>
}

export default ReviewPlanStatus