// @ts-nocheck
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import parsePhoneNumber from 'libphonenumber-js'

// react-router-dom components

// @mui material components
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Divider from "@mui/material/Divider";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import MDDisplayAddress from "../../components/MDDisplayAddress";
import MDTypography from "../../components/MDTypography";

import { getById } from "../../reducers/receiver"
import { displayName } from "../../helpers";
import { hasAccess } from "../../helpers/access";

function ReceiverDetail({ data, onClose }) {
  let dispatch = useDispatch()

  const user = useSelector(state => state.auth.user)
  const receiver = useSelector(state => state.receiver.detail) || data

  useEffect(() => {
    if (data.id) dispatch(getById(data.id))
  }, [data.id, dispatch])

  if (!receiver.id) return null
  return (
    <Dialog onClose={onClose} open fullWidth maxWidth="xs" disableEscapeKeyDown>
      <DialogTitle>Receiver Detail</DialogTitle>
      <DialogContent>
        <MDBox mt={1}>
          <MDBox mb={2}>
            <MDTypography variant="body2">{receiver.name}</MDTypography>
          </MDBox>
          {receiver.phone && (
            <MDBox mb={2}>
              <MDTypography variant="body2">
                {parsePhoneNumber("+" + receiver.phone).formatInternational()}
              </MDTypography>
            </MDBox>
          )}
          <MDDisplayAddress data={data} />
          {hasAccess(["receiver.setUser"], user) && receiver.user && (
            <React.Fragment>
              <Divider light={false} />
              <MDBox mb={2}>
                <div style={{ lineHeight: 1 }}>
                  <MDTypography variant="caption">Client:</MDTypography>
                </div>
                <MDTypography variant="body2">{displayName(receiver.user) || ""}</MDTypography>
              </MDBox>
            </React.Fragment>
          )}
        </MDBox>
      </DialogContent>
      <DialogActions>
        <MDButton variant="gradient" color="secondary" onClick={onClose}>
          Close
        </MDButton>
      </DialogActions>

    </Dialog>
  );
}

ReceiverDetail.defaultProps = {
  data: {
    id: null,
    userId: "",
    name: "",
    phone: "",
    addressFieldOne: "",
    addressFieldTwo: "",
    city: "",
    district: "",
    state: "",
    country: "",
    postalCode: "",
    user: {}
  },
};

ReceiverDetail.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object,
}

export default ReceiverDetail