export const ROLE = {
  admin: {
    key: 'admin',
    value: 'admin',
    text: 'Admin'
  },
  client: {
    key: 'client',
    value: 'client',
    text: 'Client'
  },
  accountManager: {
    key: 'accountManager',
    value: 'accountManager',
    text: 'Account Manager'
  },
  dispatcher: {
    key: 'dispatcher',
    value: 'dispatcher',
    text: 'Dispatcher'
  },
}

export const ALL_ROLES = [ROLE.admin, ROLE.accountManager, ROLE.dispatcher, ROLE.client]
export const ALL_INTERNAL_ROLES = [ROLE.admin, ROLE.accountManager, ROLE.dispatcher]
export const ROLE_CLIENT_MANAGEMENT = [ROLE.admin, ROLE.client, ROLE.accountManager]
export const INTERNAL_ROLE_CLIENT_MANAGEMENT = [ROLE.admin, ROLE.accountManager]

export const USER_ROLE_ABILITY = {
  user: {
    create: [ROLE.admin],
    update: [ROLE.admin],
    delete: [ROLE.admin],
    view: [ROLE.admin],
  },
  client: {
    create: [ROLE.admin, ROLE.accountManager],
    update: [ROLE.admin, ROLE.accountManager],
    delete: [ROLE.admin, ROLE.accountManager],
    view: [ROLE.admin, ROLE.accountManager],
  },
  shipper: {
    create: ALL_INTERNAL_ROLES,
    update: ALL_INTERNAL_ROLES,
    delete: ALL_INTERNAL_ROLES,
    view: ALL_ROLES,
  },
  receiver: {
    create: ALL_INTERNAL_ROLES,
    update: ALL_INTERNAL_ROLES,
    delete: ALL_INTERNAL_ROLES,
    setUser: ALL_INTERNAL_ROLES,
    view: ALL_ROLES,
  },
  item: {
    create: ROLE_CLIENT_MANAGEMENT,
    update: ROLE_CLIENT_MANAGEMENT,
    delete: ROLE_CLIENT_MANAGEMENT,
    setUser: ROLE_CLIENT_MANAGEMENT.filter(r => r !== ROLE.client),
    view: ALL_ROLES,
  },
  itemPrice: {
    create: ROLE_CLIENT_MANAGEMENT,
    update: ROLE_CLIENT_MANAGEMENT,
    delete: ROLE_CLIENT_MANAGEMENT,
    view: ALL_ROLES,
  },
  reviewPlan: {
    create: ROLE_CLIENT_MANAGEMENT,
    update: ROLE_CLIENT_MANAGEMENT,
    delete: ROLE_CLIENT_MANAGEMENT,
    setUser: ROLE_CLIENT_MANAGEMENT.filter(r => r !== ROLE.client),
    sendToClient: [ROLE.admin, ROLE.accountManager],
    approve: [ROLE.client],
    cancel: [ROLE.client],
    view: ALL_ROLES,
  },
  reviewPlanItem: {
    create: ROLE_CLIENT_MANAGEMENT,
    update: ROLE_CLIENT_MANAGEMENT,
    delete: ROLE_CLIENT_MANAGEMENT,
    view: ALL_ROLES,
  },
  packingList: {
    create: ALL_INTERNAL_ROLES,
    update: ALL_INTERNAL_ROLES,
    delete: ALL_INTERNAL_ROLES,
    sendToClient: [ROLE.admin, ROLE.accountManager],
    submitReview: [ROLE.admin, ROLE.dispatcher],
    changeStatus: [ROLE.admin, ROLE.accountManager],
    view: ALL_ROLES,
  },
  invoice: {
    create: [ROLE.admin, ROLE.accountManager],
    update: [ROLE.admin, ROLE.accountManager],
    delete: [ROLE.admin, ROLE.accountManager],
    sendToClient: [ROLE.admin, ROLE.accountManager],
    void: [ROLE.admin],
    pay: [ROLE.admin, ROLE.client],
    view: ROLE_CLIENT_MANAGEMENT,
  },
}

export const USER_TO_DO_DASHBOARD = {
  newRP: {
    key: 'newRP',
    value: 'newRP',
    text: "New Review Plans",
    roles: ALL_INTERNAL_ROLES,
    getText: (user) => {
      return 'Need Review Plans'
    },
  },
  reviewRP: {
    key: 'reviewRP',
    value: 'reviewRP',
    text: "In-review Review Plans",
    roles: [ROLE.client, ...ALL_INTERNAL_ROLES],
    getText: (user) => {
      if (user?.roles.includes(ROLE.client.value)) {
        return 'Approval Required'
      } else {
        return 'Waiting for Client'
      }
    },
  },
  approvedRP: {
    key: 'approvedRP',
    value: 'approvedRP',
    text: "Approved Review Plans",
    roles: [ROLE.client, ...ALL_INTERNAL_ROLES],
    getText: (user) => {
      if (user?.roles.includes(ROLE.client.value)) {
        return 'Approved Plans'
      } else {
        return 'Client Approved Plans'
      }
    },
  },
  // reviewPL: {
  //   key: 'reviewPL',
  //   value: 'reviewPL',
  //   text: "In-review Packing Lists",
  //   roles: [ROLE.admin, ROLE.accountManager],
  // },
  // approvedPL: {
  //   key: 'approvedPL',
  //   value: 'approvedPL',
  //   text: "Approved Packing Lists",
  //   roles: [ROLE.dispatcher],
  // },
  // newInvoice: {
  //   key: 'newInvoice',
  //   value: 'newInvoice',
  //   text: "New / Unpaid Invoices",
  //   roles: [ROLE.client],
  // },
  // dueInvoice: {
  //   key: 'dueInvoice',
  //   value: 'dueInvoice',
  //   text: "Due Invoices (Unpaid)",
  //   roles: [ROLE.admin],
  // },
}