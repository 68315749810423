// @ts-nocheck
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

// react-router-dom components

// @mui material components
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

// Material Dashboard 2 React components
import MDInput from "../../components/MDInput";
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import MDSnackbar from "../../components/MDSnackbar";
import ClientSelector from "../../components/DataSelector/ClientSelect";
import ReceiverSelector from "../../components/DataSelector/ReceiverSelect";
import UserSelector from "../../components/DataSelector/UserSelect";

import { create, updateById } from "../../reducers/reviewPlan"
import { setGlobalSnackbar } from "../../reducers/app";
import { hasAccess } from "../../helpers/access";
import { INTERNAL_ROLE_CLIENT_MANAGEMENT } from "../../constants/user.constant";

const INTERNAL_ROLE_CLIENT_MANAGEMENT_OPTIONS = INTERNAL_ROLE_CLIENT_MANAGEMENT.map(a => a.value)

function ReviewPlanForm({ hideClient, data, onClose, onCreate }) {
  let dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const user = useSelector(state => state.auth.user)

  let userId = data.userId || null
  if (!hasAccess(["reviewPlan.setUser"], user)) {
    userId = user.id
  }
  const [input, setInput] = useState({
    userId,
    receiverId: data.receiverId || null,
    managedByUserId: data.managedByUserId || null,
  })

  const handleClose = (e, reason = '') => {
    if (reason === 'escapeKeyDown' || reason === 'backdropClick') {
      return
    }
    onClose();
  };

  const handleChangeInput = (e) => {
    setErrorMessage(null)
    setInput(prev => {
      return {
        ...prev,
        [e.target.name]: e.target.value
      }
    })
  }

  const handleSubmit = async () => {
    setErrorMessage(null)
    try {
      setLoading(true)
      let resp = null
      let params = {
        ...input,
      }
      if (data.id) {
        resp = await dispatch(updateById(data.id, params))
      } else {
        resp = await create(params)
        if (onCreate) {
          onCreate()
        }
      }
      dispatch(setGlobalSnackbar({
        open: true,
        title: resp.data.message,
        color: 'success',
        autoHide: true
      }))
      handleClose()
    } catch (e) {
      setLoading(false)
      setErrorMessage(e.data.message)
    }
  }

  return (
    <Dialog onClose={handleClose} open fullWidth maxWidth="xs" disableEscapeKeyDown>
      {errorMessage && (
        <MDSnackbar
          color="error"
          title={errorMessage}
          open
          close={() => setErrorMessage(null)}
        />
      )}
      <DialogTitle>{data.id ? "Update" : "Create"} Review Plan</DialogTitle>
      <DialogContent>
        <MDBox mt={1} component="form" role="form" autoComplete="off" noValidate>
          {hasAccess(["reviewPlan.setUser"], user) && !hideClient && (
            <MDBox mb={2}>
              <ClientSelector required disabled={loading} label="Client" fullWidth value={input?.userId || null} name="userId" onChange={handleChangeInput} />
            </MDBox>
          )}
          {hasAccess(["reviewPlan.setUser"], user) && (
            <MDBox mb={2}>
              <UserSelector roles={INTERNAL_ROLE_CLIENT_MANAGEMENT_OPTIONS} required disabled={loading} label="Managed by" fullWidth value={input?.managedByUserId || null} name="managedByUserId" onChange={handleChangeInput} />
            </MDBox>
          )}
          <MDBox mb={2}>
            <MDInput type="text" disabled={loading} label="Order Number" fullWidth value={input?.orderNumber || "1"} name="orderNumber" onChange={handleChangeInput} />
          </MDBox>
          {/* {input?.userId && (
            <MDBox mb={2}>
              <ReceiverSelector userId={input?.userId} required disabled={loading} label="Receiver" fullWidth value={input?.receiverId || null} name="receiverId" onChange={handleChangeInput} allowAdd />
            </MDBox>
          )} */}
        </MDBox>
      </DialogContent>
      <DialogActions>
        <MDButton variant="gradient" disabled={loading} color="secondary" onClick={handleClose}>
          Cancel
        </MDButton>
        <MDButton variant="gradient" disabled={loading} color="primary" onClick={handleSubmit}>
          Submit
        </MDButton>
      </DialogActions>

    </Dialog>
  );
}

ReviewPlanForm.defaultProps = {
  data: {
    id: null,
    userId: "",
    receiverId: "",
    managedByUserId: "",
    orderNumber: "1",
  },
  hideClient: false,
};

ReviewPlanForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func,
  data: PropTypes.object,
  hideClient: PropTypes.bool,
}

export default ReviewPlanForm