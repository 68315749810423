import { API_URL } from "../constants/api";
import { getAxios, postAxios } from "../helpers";

class FBAReportService {
  getAll(params = null) {
    let url = API_URL.FBA_REPORT.GET_ALL;
    return getAxios(url, params);
  }
}

export default new FBAReportService();