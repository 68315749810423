// @ts-nocheck
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
// react-router-dom components

// @mui material components
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import InventoryIcon from '@mui/icons-material/Inventory';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDActionMenu from "../../components/MDActionMenu";
import MDAlertDialog from "../../components/MDAlertDialog";
import MDButton from "../../components/MDButton";

import { addPackingList, download, downloadExcel, getAll as getAllReviewPlan, approve as approveReviewPlan, reject as rejectReviewPlan, sendToClient as sendReviewPlanToClient, downloadOrderSheet } from "../../reducers/reviewPlan";
import { hasAccess } from "../../helpers/access";
import { STATUS, FINAL_STATUSES } from "../../constants/reviewplan.constant";
import { AUTHENTICATED_ROUTE } from "../../constants/routes";
import { downloadBlobFile, formatDateTime } from "../../helpers";
import PackingListForm from "../PackingList/PackingListForm";
import ReceiverDetail from "../Receiver/ReceiverDetail";
import ClientName from "components/ClientName/ClientName";
import InventoryReportDetailPage from "pages/InventoryReport/InventoryReportDetail";
import { USER_TO_DO_DASHBOARD } from "constants/user.constant";
import { ForwardToInbox } from "@mui/icons-material";
import { ROLE } from "constants/user.constant";
import { getReviewPlanReportName } from "helpers/review-plan";

const LIMIT = 5

function DashboardReviewPlan({ status, dashboardType, onStatusUpdated = () => {} }) {
  let dispatch = useDispatch()
  const navigate = useNavigate()
  const user = useSelector(state => state.auth.user)
  const data = useSelector(state => state.reviewPlan.dashboardByStatus[status])

  const [loadingPdf, setLoadingPdf] = useState(false)
  const [loading, setLoading] = useState(false)
  const [viewReceiver, setViewReceiver] = useState(null)
  const [createPackingList, setCreatePackingList] = useState(null)
  const [approveData, setApproveData] = useState(null)
  const [rejectData, setRejectData] = useState(null)
  const [rejectStatus, setRejectStatus] = useState(STATUS.new.value)
  const [openManageItems, setOpenManageItems] = useState(null)

  const getData = useCallback(() => {
    const params = {
      limit: LIMIT,
      page: 1,
      status: status,
      order: 'updatedAt'
    }
    setLoading(true)
    dispatch(getAllReviewPlan(params, 'dashboard'))
    setLoading(false)
  }, [status, dispatch])

  useEffect(() => {
    const params = {
      limit: LIMIT,
      page: 1,
      status: status,
      order: 'updatedAt'
    }
    setLoading(true)
    dispatch(getAllReviewPlan(params, 'dashboard'))
    setLoading(false)
  }, [status, dispatch])

  const handleCreatePackingList = (resp) => {
    dispatch(addPackingList(resp))
  }
  const handleApprove = (data) => () => {
    setApproveData(data)
  }
  const handleSubmitApprove = async () => {
    if (approveData && approveData.id) {
      return dispatch(approveReviewPlan(approveData.id))
    }
    return null
  }
  const handleReject = (data) => () => {
    setRejectData(data)
    setRejectStatus(STATUS.new.value)
  }
  const handleSubmitReject = async () => {
    if (rejectData && rejectData.id) {
      return dispatch(rejectReviewPlan(rejectData.id, rejectStatus))
    }
    return null
  }

  const handleManageItems = (data) => () => {
    setOpenManageItems(data)
  }
  const handleDownloadReviewPlan = (data) => () => {
    setLoadingPdf(true)
    download(data.id).then(resp => {
      setLoadingPdf(false)
      downloadBlobFile(resp.data, "application/pdf; charset=utf-8", "test.pdf")
    })
  }

  const handleDownloadReviewPlanExcel = (data) => () => {
    const { user } = data
    const fileName = `${(user.companyName || user.firstName)?.split(' ').join('-')}-review-plan-raw-data-${moment(data.date).format('YYYY-MM-DD')}.xlsx`
    setLoadingPdf(true)
    downloadExcel(data.id).then(resp => {
      setLoadingPdf(false)
      downloadBlobFile(resp.data, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8", fileName)
    })
  }

  const handleDownloadReviewPlanOrderSheet = (data) => () => {
    const { user } = data
    const fileName = `${(user.companyName || user.firstName)?.split(' ').join('-')}-review-plan-${moment(data.date).format('YYYY-MM-DD')}.xlsx`
    setLoadingPdf(true)
    downloadOrderSheet(data.id).then(resp => {
      setLoadingPdf(false)
      downloadBlobFile(resp.data, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8", fileName)
    })
  }

  const [sendToClientData, setSendToClientData] = useState(null)
  const handleSendToClient = (data) => () => {
    setSendToClientData(data)
  }
  const handleSubmitSendToClient = async () => {
    if (sendToClientData && sendToClientData.id) {
      return dispatch(sendReviewPlanToClient(sendToClientData.id))
    }
    return null
  }

  if (loading) {
    return (
      <MDTypography variant="button">Loading...</MDTypography>
    )
  }
  return (
    <React.Fragment>
      <div className="mb-5px">
        <MDTypography variant="body1" fontWeight="bold">
          {USER_TO_DO_DASHBOARD[dashboardType].getText?.(user) ?? USER_TO_DO_DASHBOARD[dashboardType].text} ({data.paginator.totalItems})
        </MDTypography>
      </div>
      {data.data.length > 0 ? <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell align="right">
              <MDTypography variant="button" fontWeight="bold">#ID</MDTypography>
            </TableCell>
            {hasAccess(["reviewPlan.setUser"], user) && (
              <TableCell align="left">
                <MDTypography variant="button" fontWeight="bold">Client</MDTypography>
              </TableCell>
            )}
            {!hasAccess(["reviewPlan.setUser"], user) && (
              <TableCell align="left">
                <MDTypography variant="button" fontWeight="bold">Plan</MDTypography>
              </TableCell>
            )}
            {status !== STATUS.clientReview.value && <TableCell align="left">
              <MDTypography variant="button" fontWeight="bold">Plan Date</MDTypography>
            </TableCell>}
            {status === STATUS.clientReview.value && (
              <TableCell>
                <div className="no-wrap">
                  <MDTypography variant="button" fontWeight="bold">Last Update</MDTypography>
                </div>
              </TableCell>
            )}
            {hasAccess(["reviewPlan.view", "reviewPlan.approve", "reviewPlan.cancel"], user) && (
              <TableCell align="right" />
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.data.map(d => {
            let actionItems = []
            if (hasAccess(["reviewPlanItem.view"], user)) {
              actionItems.push({
                key: "manage_items",
                text: "Manage Items",
                icon: <InventoryIcon color="warning" />,
                onClick: handleManageItems(d)
              })
              if (hasAccess(["reviewPlan.setUser"], user)) {
                actionItems.push({
                  key: "download_order_sheet_excel",
                  text: "Download Order Sheet",
                  icon: <CloudDownloadIcon color="success" />,
                  onClick: handleDownloadReviewPlanOrderSheet(d)
                })
              }
              if (hasAccess(["reviewPlan.setUser"], user) && status === STATUS.new.value) {
                actionItems.push({
                  key: "send_to_client",
                  text: "Send to Client",
                  icon: <ForwardToInbox color="warning" />,
                  onClick: handleSendToClient(d)
                })
              }
              actionItems.push({
                key: "download_reviewplan_excel",
                text: hasAccess(["reviewPlan.setUser"], user) ? "Download Raw Data" : "Download Excel",
                icon: <CloudDownloadIcon color="success" />,
                onClick: handleDownloadReviewPlanExcel(d)
              })
              
            }
            if (hasAccess(["reviewPlan.approve"], user) && !FINAL_STATUSES.includes(d.status)) {
              actionItems.push({
                key: "approve",
                text: "Approve",
                icon: <ShoppingCartCheckoutIcon color="success" />,
                onClick: handleApprove(d)
              })
            }
            if (hasAccess(["reviewPlan.cancel"], user) && !FINAL_STATUSES.includes(d.status)) {
              actionItems.push({
                key: "cancel",
                text: "Reject / Request Changes",
                icon: <RemoveShoppingCartIcon color="error" />,
                onClick: handleReject(d)
              })
            }
            return (
              <TableRow key={`data-${d.id}`}>
                <TableCell align="right" padding="checkbox" size="small">
                  <MDTypography variant="body2">
                    <Link className="color-primary" to={AUTHENTICATED_ROUTE.reviewPlans.route + "?id=" + d.id + "&status=" + status}>#{d.id}</Link>
                  </MDTypography>
                </TableCell>
                
                <TableCell>
                  {hasAccess(["reviewPlan.setUser"], user) && (
                    <>
                      {d.user ? (
                        <ClientName client={d.user} />
                      ) : "-"}
                    </>
                  )}
                  {!hasAccess(["reviewPlan.setUser"], user) && (
                    <>
                      {`${d.user?.companyName} Review Plan ${moment(d.date).format("MM/DD/YYYY")}`}
                    </>
                  )}

                  <div style={{color: '#777', marginTop: '2px'}}>{getReviewPlanReportName(d)}</div>
                </TableCell>
                
                {status !== STATUS.clientReview.value && <TableCell align="right" padding="checkbox" size="small">
                  <div className="no-wrap">{d.date ? moment(d.date).format("DD MMM YYYY") : "-"}</div>
                </TableCell>}
                {status === STATUS.clientReview.value && (
                  <TableCell>
                    <div className="no-wrap">
                      {formatDateTime(d.updatedAt, true, false)}
                    </div>
                  </TableCell>
                )}
                {hasAccess(["reviewPlan.view", "reviewPlan.approve", "reviewPlan.cancel"], user) && (
                  <TableCell align="right" padding="checkbox" size="small">
                    <MDActionMenu
                      items={actionItems}
                    />
                  </TableCell>
                )}
              </TableRow>
            )
          })}
        </TableBody>
      </Table> : <MDBox p={2} bgColor="white" height="80%" display="flex" justifyContent="center" alignItems="center">
        <MDTypography variant="body1" mt={2}>No plans</MDTypography>
      </MDBox>}
      
      {data.data.length > 0 && <MDBox pt={1} display="flex" justifyContent="center" alignItems="center">
        <MDButton variant="contained" size="small" color="secondary" onClick={() => navigate(AUTHENTICATED_ROUTE.reviewPlans.route + "?status=" + status)}>
          View All
        </MDButton>
      </MDBox>}
      {loadingPdf && (
        <Dialog open maxWidth="xs" disableEscapeKeyDown>
          <DialogContent>
            <MDTypography variant="button" fontWeight="bold">Generating file, please wait...</MDTypography>
          </DialogContent>
        </Dialog>
      )}
      {approveData && (
        <MDAlertDialog onClose={() => {
          setApproveData(null)
          onStatusUpdated()
        }} title="Approve Review Plan?" content={
          <>
            <MDBox>
              <MDTypography variant="h5">Review Plan #{approveData.id || ""}</MDTypography>
              <MDTypography variant="body2">will be approved and we will start processing your review plan immediately.</MDTypography>
              <MDTypography variant="body2">This action is not reversible.</MDTypography>
            </MDBox>
          </>
        }
          onSubmit={handleSubmitApprove}
          submitText="Approve"
        />
      )}
      {rejectData && (
        <MDAlertDialog onClose={() => {
          setRejectData(null)
          onStatusUpdated()
        }} title="Request Change?" content={
          <>
            <MDBox mb={2}>
              <MDTypography variant="h5">Review Plan #{rejectData.id || ""}</MDTypography>
              <MDTypography variant="body2">will be sent back to FA team.</MDTypography>
            </MDBox>
            {/* <MDBox>
              <FormControl>
                <FormLabel>Please provide the new status for this review plan:</FormLabel>
                <RadioGroup
                  value={rejectStatus}
                  onChange={(e) => setRejectStatus(e.target.value)}
                >
                  <FormControlLabel value={STATUS.new.value} control={<Radio />} label="Request some changes" />
                  <FormControlLabel value={STATUS.canceled.value} control={<Radio />} label="Cancel / void this review plan (this is not reversible)" />
                </RadioGroup>
              </FormControl>
            </MDBox> */}
          </>
        }
          onSubmit={handleSubmitReject}
          submitText="Reject"
        />
      )}
      {viewReceiver && (
        <ReceiverDetail data={viewReceiver} onClose={() => setViewReceiver(null)} />
      )}
      {createPackingList && (
        <PackingListForm userId={createPackingList.userId} data={{ reviewPlanId: createPackingList.reviewPlanId }} onClose={() => setCreatePackingList(null)} onCreate={handleCreatePackingList} />
      )}
      {openManageItems !== null && (
        <InventoryReportDetailPage
          reportsList={data.data || []}
          report={openManageItems}
          onClose={() => setOpenManageItems(null)}
          onSendToClient={handleSendToClient(openManageItems)}
          onDownloadRawData={handleDownloadReviewPlanExcel(openManageItems)}
          onDownloadOrderSheet={handleDownloadReviewPlanOrderSheet(openManageItems)}
        />
      )}
      {sendToClientData && (
        <MDAlertDialog onClose={() => {
          setSendToClientData(null)
          onStatusUpdated()
        }} title="Send Review Plan?" content={
          <>
            <MDBox>
              <MDTypography variant="h5">Review Plan #{sendToClientData.id || ""}</MDTypography>
              <MDTypography variant="body2">will be sent to {sendToClientData.user.email} and the review plan status will be updated.</MDTypography>
              <MDTypography variant="body2">If the client don't have an account, system will send the invitation link in the email as well.</MDTypography>
              <MDTypography variant="body2">This action is not reversible.</MDTypography>
            </MDBox>
          </>
        }
          onSubmit={handleSubmitSendToClient}
          submitText="Send"
        />
      )}
    </React.Fragment>
  );
}

DashboardReviewPlan.propTypes = {
  status: PropTypes.string.isRequired,
}

export default DashboardReviewPlan