/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React base styles
import colors from "../base/colors";

// Material Dashboard 2 React helper functions
import rgba from "../functions/rgba";
import pxToRem from "../functions/pxToRem";

const { transparent, white } = colors;

const divider = {
  styleOverrides: {
    root: {
      height: pxToRem(1),
      margin: `${pxToRem(16)} 0`,
    },

    vertical: {
      width: pxToRem(1),
      height: "100%",
      margin: `0 ${pxToRem(16)}`,
    },

    light: {
      backgroundColor: transparent.main,
      backgroundImage: `linear-gradient(to right, ${rgba(white.main, 0)}, ${white.main}, ${rgba(
        white.main,
        0
      )}) !important`,

      "&.MuiDivider-vertical": {
        backgroundImage: `linear-gradient(to bottom, ${rgba(white.main, 0)}, ${white.main}, ${rgba(
          white.main,
          0
        )}) !important`,
      },
    },
  },
};

export default divider;
