import { useEffect, memo, useState } from 'react';
import PropTypes from "prop-types";
import MDInput from "../MDInput";
import MDBox from "../MDBox";
import MDAutocomplete from "../MDAutocomplete";

import * as countryData from 'country-region-data';

function StateSelect({ label, name, onChange, value, defaultCountry, ...rest }) {
  const [states, setStates] = useState([])
  const [dataValue, setDataValue] = useState(null)
  useEffect(() => {
    let defaultValue = null
    if (value) {
      defaultValue = states.find(c => c.value === value) || null
    }
    setDataValue(defaultValue)
  }, [value, states])
  useEffect(() => {
    if (!countryData[defaultCountry]) {
      setStates([])
      setDataValue(null)
      return
    }
    const result = countryData[defaultCountry][2].map(t => {
      return { value: t[1], label: t[0] }
    })
    setStates(result)
    setDataValue(null)
  }, [defaultCountry])

  const handleChange = (e, result) => {
    setDataValue(result)
    if (typeof onChange !== 'undefined') {
      onChange({
        target: {
          name,
          value: result?.value
        }
      })
    }
  }

  if (!defaultCountry) return null
  if (!countryData[defaultCountry]) return null
  return (
    <MDAutocomplete
      options={states}
      autoHighlight
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <MDBox component="li" {...props}>
          {option.label}
        </MDBox>
      )}
      value={dataValue}
      onChange={handleChange}
      isOptionEqualToValue={(option, selected) => {
        return option.value === selected.value
      }}
      renderInput={(params) => (
        <MDInput
          {...params}
          label={label}
          name={name}
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
      {...rest}
    />
  );
}

StateSelect.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  defaultCountry: PropTypes.string.isRequired,
}

export default memo(StateSelect)