import React, { useState } from "react";

function AppAvatar({ url, name }) {
  console.log('name', name)
  const firstLetter = name ? name[0] : 'A';
  return <div>
    {!!url && url !='' ? <img className="avatar" src={url} /> : <div>
      <div className="avatar avatar__empty">{firstLetter}</div>
    </div>}
  </div>
}

export default AppAvatar