// REDUCER is used to map what is listed in action (dispatch function). parameter in dispatch function will move to 2nd parameter in each reducer
import { combineReducers } from "redux";

import app from "./app";
import auth from "./auth";
import shipper from "./shipper";
import user from "./user";
import client from "./client";
import receiver from "./receiver";
import item from "./item";
import itemPrice from "./itemPrice";
import reviewPlan from "./reviewPlan";
import reviewPlanItem from "./reviewPlanItem";
import packingList from "./packingList";
import invoice from "./invoice";
import inventoryPrediction from "./inventoryPrediction";

// combineReducers will make the state to be inside this object. i.e: checkUser, auth, etc.
const rootReducer = combineReducers({
  app,
  auth,
  shipper,
  user,
  client,
  receiver,
  item,
  itemPrice,
  reviewPlan,
  reviewPlanItem,
  packingList,
  invoice,
  inventoryPrediction
});

export default rootReducer;
