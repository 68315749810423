import { API_URL } from "../constants/api";
import { getAxios, postAxios } from "../helpers";

class PackingListService {

  getAll(params = null) {
    let url = API_URL.PACKING_LIST.GET_ALL;
    return getAxios(url, params);
  }

  getById(id) {
    const url = API_URL.PACKING_LIST.GET_BY_ID.replace(":id", id);
    return getAxios(url);
  }

  create(params) {
    const url = API_URL.PACKING_LIST.CREATE;
    return postAxios(url, params);
  }

  updateById(id, params) {
    const url = API_URL.PACKING_LIST.UPDATE_BY_ID.replace(":id", id);
    return postAxios(url, params, "put");
  }

  deleteById(id) {
    const url = API_URL.PACKING_LIST.DELETE_BY_ID.replace(":id", id);
    return postAxios(url, {}, "delete");
  }

  download(id) {
    const url = API_URL.PACKING_LIST.DOWNLOAD.replace(":id", id);
    return getAxios(url, null, { "Content-Type": "application/pdf" }, { responseType: "blob" });
  }
  sendToClient(id) {
    const url = API_URL.PACKING_LIST.SEND_TO_CLIENT.replace(":id", id);
    return postAxios(url, {});
  }
  changeStatus(id, status) {
    const url = API_URL.PACKING_LIST.CHANGE_STATUS.replace(":id", id);
    return postAxios(url, { status }, "put");
  }

}

export default new PackingListService();
