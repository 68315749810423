import Cookies from "js-cookie";
import config from "../configs";

export const ACCESS_TOKEN_KEY = "fa-amazon-portal";

class SessionStoreService {
  constructor() {
    this.storageType = "cookies";
    this.storage = Cookies;
    this.localStorageEvent = {
      logout: "logoutUser",
      getStorage: "getStorage",
      setStorage: "setStorage",
      noStorage: "noStorage",
    };
  }

  getLocalStorageEvents() {
    return this.localStorageEvent;
  }

  getStorage() {
    return this.storage;
  }

  hasAuthorization() {
    let accessToken = null;
    if (this.storageType === "cookies") {
      accessToken = this.storage.get(ACCESS_TOKEN_KEY);
    } else {
      accessToken = this.storage.getItem(ACCESS_TOKEN_KEY);
    }
    if (accessToken) {
      if (accessToken === "null") {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }

  getAuthorization() {
    if (this.storageType === "cookies") {
      return this.storage.get(ACCESS_TOKEN_KEY);
    } else {
      return this.storage.getItem(ACCESS_TOKEN_KEY);
    }
  }

  setAuthorization(tokenData) {
    if (this.storageType === "cookies") {
      let expires = tokenData.expiresAt;
      this.storage.set(ACCESS_TOKEN_KEY, JSON.stringify(tokenData), {
        path: "/",
        expires: expires,
        ...config.cookieConfig,
      });
    } else {
      this.storage.setItem(ACCESS_TOKEN_KEY, JSON.stringify(tokenData));
      this.sendSessionToTabs();
    }
  }

  clearAuthorization() {
    if (this.storageType === "cookies") {
      this.storage.remove(ACCESS_TOKEN_KEY, {
        path: "/",
        ...config.cookieConfig,
      });
    } else {
      this.storage.removeItem(ACCESS_TOKEN_KEY);
      // call other tab to remove session too
      localStorage.setItem(
        this.localStorageEvent.logout,
        this.localStorageEvent.logout
      );
      localStorage.removeItem(this.localStorageEvent.logout);
    }
  }

  sendSessionToTabs() {
    let appSession = {};
    appSession[ACCESS_TOKEN_KEY] = this.accessToken;
    localStorage.setItem(
      this.localStorageEvent.setStorage,
      JSON.stringify(appSession)
    );
    localStorage.removeItem(this.localStorageEvent.setStorage);
  }

  askSessionFromTab() {
    if (this.storage.length === 0 && this.storageType !== "cookies") {
      localStorage.setItem(
        this.localStorageEvent.getStorage,
        this.localStorageEvent.getStorage
      );
      localStorage.removeItem(this.localStorageEvent.getStorage);
    }
  }
}

export default new SessionStoreService();
