import { API_URL } from "../constants/api";
import { getAxios, postAxios } from "../helpers";

class ClientService {

  getAll(params = null) {
    let url = API_URL.CLIENT.GET_ALL;
    return getAxios(url, params);
  }

  getById(id) {
    const url = API_URL.CLIENT.GET_BY_ID.replace(":id", id);
    return getAxios(url);
  }

  create(params) {
    const url = API_URL.CLIENT.CREATE;
    return postAxios(url, params);
  }

  updateById(id, params) {
    const url = API_URL.CLIENT.UPDATE_BY_ID.replace(":id", id);
    return postAxios(url, params, "put");
  }

  deleteById(id) {
    const url = API_URL.CLIENT.DELETE_BY_ID.replace(":id", id);
    return postAxios(url, {}, "delete");
  }

  pullFBAReport(id) {
    const url = API_URL.CLIENT.PULL_FBA_REPORT.replace(":id", id);
    return postAxios(url, {});
  }

  pullMasterSheet(id) {
    const url = API_URL.CLIENT.PULL_MASTER_SHEET.replace(":id", id);
    return postAxios(url, {});
  }

}

export default new ClientService();
