// @ts-nocheck
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import NumberFormat from 'react-number-format';
import moment from "moment";

// react-router-dom components

// @mui material components
import Grid from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import InputAdornment from '@mui/material/InputAdornment';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDInput from "../../components/MDInput";
import MDButton from "../../components/MDButton";
import MDSnackbar from "../../components/MDSnackbar";
import MDTypography from "../../components/MDTypography";

import { create, updateById } from "../../reducers/itemPrice"
import { setGlobalSnackbar } from "../../reducers/app";

function ItemPriceForm({ item, data, onClose, onCreate, onUpdate }) {
  let dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const [input, setInput] = useState({
    effectiveDate: moment(data.effectiveDate) || moment(),
    price: data.price || "",
    update: "0"
  })

  const handleClose = (e, reason = '') => {
    if (reason === 'escapeKeyDown' || reason === 'backdropClick') {
      return
    }
    onClose();
  };

  const handleChangeInput = (e) => {
    setErrorMessage(null)
    setInput(prev => {
      return {
        ...prev,
        [e.target.name]: e.target.value
      }
    })
  }

  const handleSubmit = async () => {
    setErrorMessage(null)
    try {
      setLoading(true)
      let resp = null
      let params = {
        ...input,
        update: input.update === "1",
        effectiveDate: moment(input.effectiveDate).format("YYYY-MM-DD"),
      }
      if (data.id) {
        resp = await dispatch(updateById(item.id, data.id, params))
        if (onUpdate) {
          onUpdate()
        }
      } else {
        resp = await create(item.id, params)
        if (onCreate) {
          onCreate()
        }
      }
      dispatch(setGlobalSnackbar({
        open: true,
        title: resp.data.message,
        color: 'success',
        autoHide: true
      }))
      handleClose()
    } catch (e) {
      setLoading(false)
      setErrorMessage(e.data.message)
    }
  }

  return (
    <Dialog onClose={handleClose} open fullWidth maxWidth="xs" disableEscapeKeyDown>
      {errorMessage && (
        <MDSnackbar
          color="error"
          title={errorMessage}
          open
          close={() => setErrorMessage(null)}
        />
      )}
      <DialogTitle>{data.id ? "Update" : "Create"} Price for Item: {item.name}</DialogTitle>
      <DialogContent>
        <MDBox mt={1} component="form" role="form" autoComplete="off" noValidate>
          <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
            <Grid item md={12} lg={6} xl={6} sm={12} mb={2}>
              <MobileDatePicker
                label="Effective date"
                inputFormat="DD MMM YYYY"
                value={input?.effectiveDate || ""}
                disabled={loading}
                fullWidth
                renderInput={(params) => <MDInput {...params} />}
                onChange={(value) => handleChangeInput({ target: { name: 'effectiveDate', value } })}
              />
            </Grid>
            <Grid item md={12} lg={6} xl={6} sm={12} mb={2}>
              <NumberFormat
                disabled={loading}
                label="Price"
                fullWidth
                required
                name="price"
                value={input?.price || ""}
                customInput={MDInput}
                thousandSeparator={true}
                onValueChange={({ floatValue }) => handleChangeInput({ target: { name: "price", value: floatValue } })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MDTypography variant="body2" fontWeight="regular" color="text">
                        $
                      </MDTypography>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} mb={2}>
              <FormControl>
                <FormLabel>Update non final review plan items price per unit with this price?</FormLabel>
                <RadioGroup
                  row
                  name="update"
                  value={input.update}
                  onChange={handleChangeInput}
                >
                  <FormControlLabel value="1" control={<Radio />} label="Yes, help me update" />
                  <FormControlLabel value="0" control={<Radio />} label="No, do not update" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </MDBox>
      </DialogContent>
      <DialogActions>
        <MDButton variant="gradient" disabled={loading} color="secondary" onClick={handleClose}>
          Cancel
        </MDButton>
        <MDButton variant="gradient" disabled={loading} color="primary" onClick={handleSubmit}>
          Submit
        </MDButton>
      </DialogActions>

    </Dialog>
  );
}

ItemPriceForm.defaultProps = {
  data: {
    id: null,
    effectiveDate: moment().format("YYYY-MM-DD"),
    price: "",
  }
};

ItemPriceForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
  data: PropTypes.object,
  item: PropTypes.object.isRequired,
}

export default ItemPriceForm