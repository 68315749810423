import React, { useCallback, useEffect, useMemo, useState } from "react";
import fbaReportService from "../../services/fba-report-service";
import inventoryPredictionService from "../../services/inventory-prediction";
import { getAll as getAllInventoryPrediction } from "../../reducers/inventoryPrediction";

import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Chip from '@mui/material/Chip';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';
import MDButton from "../../components/MDButton";

// Material Dashboard 2 React components
import MDTablePagination from "../../components/MDTablePagination";
import MDInputTimeout from "../../components/MDInputTimeout";
import MDInput from "../../components/MDInput";
import MDBox from "../../components/MDBox";
import MDFab from "../../components/MDFab";
import MDTypography from "../../components/MDTypography";
import MDActionMenu from "../../components/MDActionMenu";
import MDAlertDialog from "../../components/MDAlertDialog";
import { Dialog, DialogContent, DialogTitle, IconButton, Tooltip } from "@mui/material";

import { hasAccess } from "../../helpers/access";


import moment from "moment";
import HeaderInCell from "./components/HeaderInCell";
import { useDispatch, useSelector } from "react-redux";
import AddRecommendationModal from "./components/AddRecommendationModal";
import { Add, ArrowDownward, ArrowDropDown, ArrowDropUp, ArrowUpward, ChevronLeft, Delete, Edit, NoteAdd, Sort, SortByAlpha, TrendingDown, Warning } from "@mui/icons-material";
import ItemForm from "pages/Item/ItemForm";
import InventoryReportItemForm from "./InventoryReportItemForm";
import { trueOrZero, formatter } from "helpers";

import SeeMoreText from "components/SeeMoreText/SeeMoreText";

import { updateById, deleteById } from '../../reducers/inventoryPrediction'
import EditButton from "components/EditButton/EditButton";
import DeleteButton from "components/DeleteButton/DeleteButton";
import { ignoreReviewPlanItem } from "helpers/review-plan";
import { STATUS } from "constants/reviewplan.constant";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function getItemIssueScore(item) {
  let count = Object.values(item.analysisResult.issues).filter(issue => !!issue).length
  if (!item.item.isFromMastersheet) {
    count += 1
  }
  return count
}

function getFirstIssueIndex(item) {
  Object.values(item.analysisResult.issues).forEach((issue, index) => {
    if (issue) {
      return index
    }
  })
  return 0
}

const REQUIRED_ITEM_COLUMNS = [
  {
    key: 'labelingOwner',
    text: 'Who Labels',
    valueGetter: (item) => !!item?.labelingOwner
  },
  {
    key: 'prepOwner',
    text: 'Who Prepares',
    valueGetter: (item) => !!item?.prepOwner
  },
  {
    key: 'labelNotes',
    text: 'Label Type',
    valueGetter: (item) => !!item?.labelNotes
  },
  {
    key: 'shippingNotes',
    text: 'Prep Type',
    valueGetter: (item) => !!item?.shippingNotes
  },
  {
    key: 'weight',
    text: 'Weight',
    valueGetter: (item) => !!item?.weight
  },
  {
    key: 'length',
    text: 'Length',
    valueGetter: (item) => !!item?.dimension.length
  },
  {
    key: 'width',
    text: 'Width',
    valueGetter: (item) => !!item?.dimension.width
  },
  {
    key: 'height',
    text: 'Height',
    valueGetter: (item) => !!item?.dimension.height
  },
  {
    key: 'qtyPerUnit',
    text: 'Units Per Box',
    valueGetter: (item) => !!item?.qtyPerUnit
  }
]

function InventoryReportDetailPage({report, onClose, onDownloadOrderSheet, onDownloadRawData, onSendToClient, reportsList}) {  
  // this is urgly, should change all report to repor from reducer
  const reportFromReducer = (reportsList || []).find(r => r.id === report.id)

  const user = useSelector(state => state.auth.user)
  const dispatch = useDispatch()

  const isClient = useMemo(() => report.userId === user.id, [report, user])
  const isAccountManager = hasAccess(['reviewPlan.setUser'], user)

  const [loading, setLoading] = useState(false)
  const [addRecommendation, setAddRecommendation] = useState(null)
  const [itemForm, setItemForm] = useState(null)
  const [reviewPlanItemForm, setReviewPlanItemForm] = useState(null)
  const [showDetailItem, setShowDetailItem] = useState(null)

  const _inventoryPredictions = useSelector(state => state.inventoryPrediction.list.data)

  const [sortBy, setSortBy] = useState(null)
  const [sortOrder, setSortOrder] = useState(null)

  const inventoryPredictions = useMemo(() => {
    let foundInvalidItem = false
    _inventoryPredictions.forEach(ip => {
      if (!ip.analysisResult) {
        ip.invalid = true
        foundInvalidItem = true
        return ip
      }

      // const value = trueOrZero(ip.value) ? Math.round(ip.value) : 0
      // const trend = ip.analysisResult.trend

      // const noOfBoxesRec = ip.item?.qtyPerUnit ? (
      //   trend === 'up' ? Math.ceil(value / ip.item.qtyPerUnit) : (
      //     trend === 'down' ? Math.floor(value / ip.item.qtyPerUnit) : Math.round(value / ip.item.qtyPerUnit)
      //   )
      // ) : 0
      // const roundedValue = ip.item?.qtyPerUnit ? noOfBoxesRec * ip.item.qtyPerUnit : value
      // ip.noOfBoxesRec = noOfBoxesRec
      // ip.roundedValue = roundedValue

      // const finalValue = trueOrZero(ip.clientRec) ? ip.clientRec : (trueOrZero(ip.internalRec) ? ip.internalRec : value)
      // ip.finalValue = finalValue
      // const noOfBoxes = ip.item?.qtyPerUnit ? (
      //   trend === 'up' ? Math.ceil(finalValue / ip.item.qtyPerUnit) : (
      //     trend === 'down' ? Math.floor(finalValue / ip.item.qtyPerUnit) : Math.round(finalValue / ip.item.qtyPerUnit)
      //   )
      // ) : 0
      // const roundedFinalValue = ip.item?.qtyPerUnit ? noOfBoxes * ip.item.qtyPerUnit : finalValue
      // ip.noOfBoxes = noOfBoxes
      // ip.roundedFinalValue = roundedFinalValue
    })

    if (foundInvalidItem) return _inventoryPredictions

    switch (sortBy) {
      case 'name':
        return _inventoryPredictions.sort((a, b) => {
          if (a.item.name > b.item.name) {
            return sortOrder === 'asc' ? 1 : -1
          }
          if (a.item.name < b.item.name) {
            return sortOrder === 'asc' ? -1 : 1
          }
          return 0
        })
      case 'merchantSku':
      case 'labelingOwner':
      case 'prepOwner':
      case 'weight':
        return _inventoryPredictions.sort((a, b) => {
          if (a.item[sortBy] > b.item[sortBy]) {
            return sortOrder === 'asc' ? 1 : -1
          }
          if (a.item[sortBy] < b.item[sortBy]) {
            return sortOrder === 'asc' ? -1 : 1
          }
          return 0
        })
      case 'orderNumber':
        return _inventoryPredictions.sort((a, b) => {
          if (a[sortBy] > b[sortBy]) {
            return sortOrder === 'asc' ? 1 : -1
          }
          if (a[sortBy] < b[sortBy]) {
            return sortOrder === 'asc' ? -1 : 1
          }
          return 0
        })
      case 'length':
      case 'width':
      case 'height':
        return _inventoryPredictions.sort((a, b) => {
          if (a.item.dimension[sortBy] > b.item.dimension[sortBy]) {
            return sortOrder === 'asc' ? 1 : -1
          }
          if (a.item.dimension[sortBy] < b.item.dimension[sortBy]) {
            return sortOrder === 'asc' ? -1 : 1
          }
          return 0
        })
      case 'quantity':
        return _inventoryPredictions.sort((a, b) => {
          // const aValue = a.value ? Math.round(a.value) : ''
          // const bValue = b.value ? Math.round(b.value) : ''
          const aFinalValue = a.roundedFinalValue
          const bFinalValue = b.roundedFinalValue
          
          if (aFinalValue > bFinalValue) {
            return sortOrder === 'asc' ? 1 : -1
          }
          if (aFinalValue < bFinalValue) {
            return sortOrder === 'asc' ? -1 : 1
          }
          return 0
        })
      case 'numberOfBoxes':
        return _inventoryPredictions.sort((a, b) => {
          // const aValue = a.value ? Math.round(a.value) : ''
          // const bValue = b.value ? Math.round(b.value) : ''
          // const aFinalValue = trueOrZero(a.clientRec) ? a.clientRec : (trueOrZero(a.internalRec) ? a.internalRec : aValue)
          // const bFinalValue = trueOrZero(b.clientRec) ? b.clientRec : (trueOrZero(b.internalRec) ? b.internalRec : bValue)
          
          const aNumberOfBoxes = a.noOfBoxes || 0
          const bNumberOfBoxes = b.noOfBoxes || 0

          if (aNumberOfBoxes > bNumberOfBoxes) {
            return sortOrder === 'asc' ? 1 : -1
          }
          if (aNumberOfBoxes < bNumberOfBoxes) {
            return sortOrder === 'asc' ? -1 : 1
          }
          return 0
        })
      case 'notes':
        return _inventoryPredictions.sort((a, b) => {
          const aValue = `${a.internalToClientNote ?? ''}${a.clientNote ?? ''}`
          const bValue = `${b.internalToClientNote ?? ''}${b.clientNote ?? ''}`
          
          if (aValue > bValue) {
            return sortOrder === 'asc' ? 1 : -1
          }
          if (aValue < bValue) {
            return sortOrder === 'asc' ? -1 : 1
          }
          return 0
        })
      case 'issues':
        return _inventoryPredictions.sort((a, b) => {
          const aValue = getItemIssueScore(a)
          const bValue = getItemIssueScore(b)
          
          if (aValue > bValue) {
            return sortOrder === 'asc' ? 1 : -1
          }
          if (aValue < bValue) {
            return sortOrder === 'asc' ? -1 : 1
          }

          return 0
        })
      default:
        return _inventoryPredictions
    }
  }, [_inventoryPredictions, sortBy, sortOrder])

  const asinMap = useMemo(() => {
    const asinMap = {}
    _inventoryPredictions.filter((ip) => {
      return !ip.doNotOrder
    }).forEach(item => {
      if (asinMap[item.item.asin]) {
        asinMap[item.item.asin].skus.push(item.item.merchantSku)
      } else {
        asinMap[item.item.asin] = {
          asin: item.item.asin,
          skus: [item.item.merchantSku],
        }
      }
    })
    return asinMap
  }, [_inventoryPredictions])

  const invalidItems = useMemo(() => {
    return inventoryPredictions.filter(ip => ip.invalid)
  }, [inventoryPredictions])

  const onClickSortHeader = useCallback((field) => {
    if (sortBy === field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    } else {
      setSortBy(field)
      setSortOrder('asc')
    }
  }, [sortBy, sortOrder, setSortBy, setSortOrder])

  const renderSortableHeader = useCallback((field, title, options = {}) => {
    const { maxWidth, flexWrap } = options
    return <TableCell onClick={() => onClickSortHeader(field)}>
      <div style={{marginTop: '2px', display: 'flex', alignItems: 'flex-start', gap: '5px', cursor: 'pointer', maxWidth: maxWidth ?? '1000px', flexWrap: flexWrap}}>
        <MDTypography color={sortBy === field ? 'primary' : ''} variant="button" fontWeight="bold">{title}</MDTypography>
        <div>
          {
            sortBy !== field && <Sort />
          }
          {
            sortBy === field && sortOrder === 'asc' && <ArrowDropUp color="primary" />
          }
          {
            sortBy === field && sortOrder === 'desc' && <ArrowDropDown color="primary" />
          }
        </div>
      </div>
    </TableCell>
  }, [sortBy, sortOrder, onClickSortHeader])

  useEffect(() => {
    if (report) {
      setLoading(true)
      // inventoryPredictionService.getAll(report.id).then((res) => {
      //   setInventoryPredictions(res.data.data)
      //   setLoading(false)
      // })
      dispatch(getAllInventoryPrediction(report.id)).then((res) => {
        setLoading(false)
      })
    }
  }, [report, dispatch])

  if (loading) return <Dialog open maxWidth="xs" disableEscapeKeyDown>
    <DialogContent>
      <MDTypography variant="button" fontWeight="bold">Loading...</MDTypography>
    </DialogContent>
  </Dialog>

  if (invalidItems.length > 0 || inventoryPredictions.length === 0) {
    setTimeout(() => {
      dispatch(getAllInventoryPrediction(report.id)).then((res) => {
        setLoading(false)
      })
    }, [10000])

    return <Dialog open maxWidth="xs" disableEscapeKeyDown>
    <DialogContent>
      <MDTypography variant="button" fontWeight="bold">Data is being processed, please wait...</MDTypography>
    </DialogContent>
  </Dialog>
  }

  return <Dialog onClose={onClose || (() => {})} open fullWidth fullScreen disableEscapeKeyDown TransitionComponent={Transition}>
    <DialogTitle display="flex" justifyContent="space-between" alignItems="center">
      <div style={{ paddingLeft: '0px', display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
        <MDTypography variant="h6" component="div">
          Review Plan for {report.user.firstName} - {moment(report.createdAt).format('MM/DD/YYYY')}
        </MDTypography>
        <MDButton variant="contained" size="small" color="primary" onClick={() => {
          setReviewPlanItemForm({})
        }}>
          Add Item
        </MDButton>
        
      </div>
      <div style={{ paddingLeft: '0px', display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
        <MDButton variant="contained" size="small" color="warning" onClick={() => {
          onDownloadRawData()
        }}>
          Download Raw Data
        </MDButton>
        {isAccountManager && <MDButton variant="contained" size="small" color="success" onClick={() => {
          onDownloadOrderSheet()
        }}>
          Download Order Sheet
        </MDButton>}

        {isAccountManager && reportFromReducer && reportFromReducer.status === STATUS.new.value && <MDButton variant="contained" size="small" color="primary" onClick={() => {
          if (onSendToClient) {
            onSendToClient()
          }
        }}>
          Send To Client
        </MDButton>}
        <IconButton
          style={{ marginLeft: '0px' }}
          edge="start"
          color="inherit"
          onClick={onClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </div>
    </DialogTitle>
    <DialogContent style={{padding: '0px'}} p={0}>
      {/* <div>Duplicated Asin: {Object.values(asinMap).filter((it) => it.skus.length > 1).map(i => i.asin).join('-')}</div> */}
      <MDBox p={0} bgColor="white">
        <Grid container spacing={1}>
          <Grid item xs={12}>
          <Table stickyHeader sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                {renderSortableHeader('orderNumber', 'Order', { maxWidth: '10px', flexWrap: 'wrap' })}
                {/* {renderSortableHeader('name', 'Product Name')} */}
                <TableCell style={{ position: 'sticky', left: '0px', top: '0px', zIndex: 200,  borderRight: 'solid 2px #ededed'}}>
                  <MDTypography variant="button" fontWeight="bold">Product</MDTypography>
                </TableCell>
                {/* <TableCell>
                  <MDTypography variant="button" fontWeight="bold">SKU</MDTypography>
                </TableCell> */}
                <TableCell >
                  <MDTypography variant="button" fontWeight="bold">ASIN</MDTypography>
                </TableCell>
                {renderSortableHeader('fnsku', 'FNSKU')}
                {renderSortableHeader('merchantSku', 'SKU')}
                
                {renderSortableHeader('labelingOwner', 'Who Labels')}
                {renderSortableHeader('labelNotes', 'Label Type')}
                {renderSortableHeader('prepOwner', 'Who Preps')}
                {renderSortableHeader('shippingNotes', 'Prep Type')}
                {renderSortableHeader('length', 'Length')}
                {renderSortableHeader('width', 'Width')}
                {renderSortableHeader('height', 'Height')}
                {renderSortableHeader('weight', 'Weight')}
                {renderSortableHeader('quantity', 'Rec. QTY')}
                {renderSortableHeader('numberOfBoxes', 'No. of Cases')}
                {hasAccess(['reviewPlan.setUser'], user) && renderSortableHeader('issues', 'Review Flags')}
                {renderSortableHeader('notes', 'Notes')}
                <TableCell>
                  <div className="no-wrap"><MDTypography variant="button" fontWeight="bold"><Edit/> Edit</MDTypography></div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {inventoryPredictions.filter((ip) => {
                if (ignoreReviewPlanItem(ip, report.user)) return false
                return true
              }).map(i => {
                let minDaysOfSupply = i.item?.minDaysOfSupply
                if (!trueOrZero(minDaysOfSupply)) {
                  minDaysOfSupply = report.user?.minDaysOfSupply
                }
                if (!trueOrZero(minDaysOfSupply)) {
                  minDaysOfSupply = 120
                }

                const hasInternalNote = !!i.internalToClientNote
                const hasClientNote = !!i.clientNote

                const duplicatedASIN = asinMap[i.item.asin] && asinMap[i.item.asin].skus.length > 1

                const missingValueColumns = []
                REQUIRED_ITEM_COLUMNS.forEach((c) => {
                  if (i.item?.isFromMastersheet && !c.valueGetter(i.item)) {
                    missingValueColumns.push(c.text)
                  }
                })

                return (
                  <React.Fragment key={`data-${i.id}`}>
                    <TableRow>
                      <TableCell>
                        {i.orderNumber || 1}
                        <div className="no-wrap p-0">
                          <EditButton defaultValue={i.orderNumber ?? 1} numberOnly editLabel={<>Edit</>} onSubmit={(value) => {
                            return dispatch(updateById(i.id, {
                              orderNumber: value
                            }))
                          }} />
                        </div>
                      </TableCell>
                      <TableCell rowSpan={1} style={{ position: 'sticky', left: '0px', zIndex: 100, borderRight: 'solid 2px #f1f1f1'  }}>
                        <Tooltip title={i.item?.name}>
                          <div  className="div-3-lines" style={{maxWidth: '200px'}}>
                            {i.item?.name}
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <div className="no-wrap">{i.item?.asin || "-"}</div>
                      </TableCell>
                      <TableCell>
                        <div className="no-wrap">{i.item?.fnsku || "-"}</div>
                      </TableCell>
                      <TableCell>
                        <div className="no-wrap">{i.item?.merchantSku || "-"}</div>
                        {
                          duplicatedASIN && i.item?.merchantSku !== i.data?.translatedData?.sku && <div style={{ fontSize: '12px', color: '#777' }}>FBA SKU: {i.data?.translatedData?.sku}</div>
                        }
                      </TableCell>
                      <TableCell>
                        <MDTypography variant="body2">
                          {i.item?.labelingOwner || '-'} 
                        </MDTypography>
                      </TableCell>
                      <TableCell>
                        <MDTypography variant="body2">
                          {i.item?.labelNotes || '-'} 
                        </MDTypography>
                      </TableCell>
                      <TableCell>
                        <MDTypography variant="body2">
                          {i.item?.prepOwner || '-'} 
                        </MDTypography>
                      </TableCell>
                      <TableCell>
                        <MDTypography variant="body2">
                          {i.item?.shippingNotes || '-'} 
                        </MDTypography>
                      </TableCell>
                      <TableCell>
                        <div className="no-wrap">
                          {i.item?.dimension?.length ? `${i.item.dimension.length}` : '-'} 
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="no-wrap">
                        {i.item?.dimension?.width ? `${i.item.dimension.width}` : '-'} 
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="no-wrap">
                        {i.item?.dimension?.height ? `${i.item.dimension.height}` : '-'} 
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="no-wrap">
                        {i.item?.weight ? `${i.item.weight}` : '-'} 
                        </div>
                      </TableCell>
                      <TableCell>
                        <div style={{fontSize: '12px'}}>
                          {
                            isAccountManager && <div className="flex gap-2 align-items-center" style={{marginBottom: '2px'}} onClick={() => {
                              setShowDetailItem(i)
                            }}>
                              { trueOrZero(i.roundedValue) && <div>{i.roundedValue > 0 ? i.roundedValue : 0}</div> }
                              { !i.roundedValue && i.roundedValue !== 0 && <div>-</div> }
                            </div>
                          }
                          {
                            isAccountManager && (trueOrZero(i.internalRec) || !!i.internalComment) && <div style={{marginBottom: '2px'}}>
                              <HeaderInCell text={'INTERNAL RECOMMENDATION'} />
                              <div style={{fontSize: i.clientRec ? '12px' : '16px', fontWeight: i.clientRec ? 300 : 500}}>{i.internalRec}</div>
                              {!!i.internalComment && <div><span style={{fontWeight: 500}}>Comment: </span><span style={{color: '#777'}}>{i.internalComment}</span></div>}
                            </div>
                          }
                          {
                            report.status === 'new' && isAccountManager && <div className="p-0">
                              <MDButton variant="text" size="small" color="primary" onClick={() => {
                                setAddRecommendation({
                                  ...i,
                                  autoRecommendation: i.roundedValue,
                                })
                              }}>
                                Override
                              </MDButton>
                            </div>
                          }
                          {
                            report.userId === user.id && <div>
                              <div style={{fontSize: trueOrZero(i.clientRec) ? '12px' : '16px', fontWeight: trueOrZero(i.clientRec) ? 300 : 500}}>{trueOrZero(i.internalRec) ? i.internalRec : (trueOrZero(i.roundedValue) ? i.roundedValue : '-')}</div>
                              {!!i.internalComment && <div><span style={{fontWeight: 500}}>Comment: </span><span style={{color: '#777'}}>{i.internalComment}</span></div>}
                            </div>
                          }
                          {
                            (trueOrZero(i.clientRec) || !!i.clientComment) && <>
                              <HeaderInCell text={report.userId === user.id ? 'FROM YOU' : 'FROM CLIENT'} />
                              {trueOrZero(i.clientRec) && <div style={{fontSize: '16px', fontWeight: 500}}>{i.clientRec}</div>}
                              {!!i.clientComment && <div><span style={{fontWeight: 500}}>Comment: </span><span style={{color: '#777'}}>{i.clientComment}</span></div>}
                            </>
                          }
                          {
                            report.userId === user.id && report.status === 'clientReview' && <div className="p-0">
                              <MDButton variant="text" size="small" color="primary" onClick={() => {
                                setAddRecommendation({
                                  ...i,
                                  autoRecommendation: i.roundedValue,
                                })
                              }}>
                                <div style={{whiteSpace: 'nowrap',
                                  display: 'flex',
                                  flexDirection: 'row', 
                                  gap: '5px',
                                  alignItems: 'center'}}><Edit/> Recommendation</div>
                              </MDButton>
                              
                            </div>
                          }
                        </div>
                      </TableCell>
                      <TableCell>
                        {i.noOfBoxes}
                      </TableCell>
                      {hasAccess(['reviewPlan.setUser'], user) && <TableCell>
                        <>
                        {
                          i.clientEditLineItem && <div className="text-red" style={{ color: 'red', fontSize: '12px', minWidth: '120px' }}>- Client Edited</div>
                        }
                        {
                          !i.item.isFromMastersheet && <div className="text-red" style={{ color: 'red', fontSize: '12px', minWidth: '120px' }}>- SKU not found</div>
                        }
                        {
                          i.flagForReview && <div className="text-red" style={{ color: 'red', fontSize: '12px', minWidth: '120px' }}>
                            {
                              i.analysisResult.issues.zeroSales && <div>- Has Zero Sales</div>
                            }
                            {/* {
                              i.analysisResult.issues.highVariance && <div>- High Variance</div>
                            } */}
                            {
                              i.data?.translatedData?.daysOfSupply > minDaysOfSupply && i.analysisResult.issues.daysOfSupply && <div>- Days Of Supply {'>'} {minDaysOfSupply}</div>
                            }
                            {
                              !i.analysisResult.issues.zeroSales && i.analysisResult.issues.inventoryAge && <div>- Problem With Inventory Age</div>
                            }
                            {
                              i.analysisResult.issues.pendingRemoval && <div>- Pending Removal</div>
                            }
                            {
                              i.analysisResult.issues.changesIncreaseTooSignificant && <div>- Sales last 7 days too large</div>
                            }
                            {
                              i.analysisResult.issues.changesDecreaseTooSignificant && <div>- Sales last 7 days too small</div>
                            }
                            {
                              i.analysisResult.issues.highSalesWarning && <div>- High Sales Warning</div>
                            }
                            {
                              i.analysisResult.issues.lowSalesWarning && <div>- Low Sales Warning</div>
                            }
                          </div>
                        }
                        {
                          duplicatedASIN && <div className="text-red" style={{ color: 'red', fontSize: '12px', minWidth: '120px' }}>- ASIN duplicated ({asinMap[i.item.asin].skus.filter(sku => sku !== i.item.merchantSku).join(',')})</div>
                        }
                        {
                          missingValueColumns.length > 0 && <div className="text-red" style={{ color: 'red', fontSize: '12px', minWidth: '120px' }}>- Missing Data: {missingValueColumns.join(', ')}</div>
                        }
                        </>
                      </TableCell>}
                      <TableCell>
                        <div style={{minWidth: '200px'}}>
                          {hasInternalNote && <div className="">
                            <SeeMoreText editLabel="Edit Note" onSubmit={ isAccountManager ? (value) => {
                              return dispatch(updateById(i.id, {
                                internalToClientNote: value
                              }))
                            } : undefined} prefix={'FA: '} text={`${i.internalToClientNote ?? ''}`} />
                          </div>}
                          {hasClientNote && <div style={{marginTop: '5px'}}>
                            <SeeMoreText editLabel="Edit Note" onSubmit={ isClient ? (value) => {
                              return dispatch(updateById(i.id, {
                                clientNote: value
                              }))
                            } : undefined} prefix={isClient ? 'You: ' : 'Client: '} text={`${i.clientNote ?? ''}`} />
                          </div>}
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        <div className="p-0">
                          <MDButton variant="text" size="small" color="primary" onClick={() => {
                            setItemForm(i.item)
                          }}>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '4px' }}><Edit /> Edit</div>
                          </MDButton>
                        </div>
                        {isClient && !hasClientNote && <div className="p-0 text-right">
                          <EditButton editLabel={<><Add /> Note</>} onSubmit={(value) => {
                            return dispatch(updateById(i.id, {
                              clientNote: value
                            }))
                          }} />
                        </div>}
                        {isAccountManager && !hasInternalNote && <div className="p-0  text-right">
                          <EditButton editLabel={<><Add /> Note</>} onSubmit={(value) => {
                            return dispatch(updateById(i.id, {
                              internalToClientNote: value
                            }))
                          }} />
                        </div>}
                        {(isAccountManager || isClient) && <div className="p-0  text-right">
                          <DeleteButton deleteLabel={<><Delete /> Delete</>} onSubmit={(value) => {
                            return dispatch(deleteById(i.id))
                          }} />
                        </div>}
                      </TableCell>
                    </TableRow>
                    {/* {hasInternalNote && <TableRow style={{backgroundColor: '#eee'}}>
                      <TableCell>
                      </TableCell>
                      <TableCell colSpan={isClient ? 10 : 11}>
                        <div className="">
                          <SeeMoreText editLabel="Edit Note" onSubmit={ isAccountManager ? (value) => {
                            return dispatch(updateById(i.id, {
                              internalToClientNote: value
                            }))
                          } : undefined} text={`FA: ${i.internalToClientNote ?? ''}`} />
                        </div>
                      </TableCell>
                    </TableRow>}
                    {hasClientNote && <TableRow style={{backgroundColor: '#eee'}}>
                      <TableCell>
                      </TableCell>
                      <TableCell colSpan={isClient ? 10 : 11}>
                        <div className="">
                          <SeeMoreText editLabel="Edit Note" onSubmit={ isClient ? (value) => {
                            return dispatch(updateById(i.id, {
                              clientNote: value
                            }))
                          } : undefined} text={`${isClient ? 'You' : 'Client: '} ${i.clientNote ?? ''}`} />
                        </div>
                      </TableCell>
                    </TableRow>} */}
                  </React.Fragment>
                )
              })}
            </TableBody>
          </Table>
          </Grid>
        </Grid>
      </MDBox>
      {
        addRecommendation && <AddRecommendationModal report={report} onClose={() => setAddRecommendation(null)} initData={addRecommendation} />
      }
      {itemForm !== null && (
        <ItemForm data={itemForm} onClose={() => setItemForm(null)} />
      )}
      {
        showDetailItem && hasAccess(['reviewPlan.setUser'], user) && <MDAlertDialog
          onClose={() => {
            console.log('onclose')
            setShowDetailItem(null)
          }}
          maxWidth="lg"
          closeText="Close"
          content={(() => {
            const rec1 = Math.round(showDetailItem.analysisResult?.calculatedData?.rec1 || 0) || 0
            const rec2 = Math.round(showDetailItem.analysisResult?.calculatedData?.rec2 || 0) || 0
            const rec3 = Math.round(showDetailItem.analysisResult?.calculatedData?.rec3 || 0) || 0
            const rec4 = Math.round(showDetailItem.analysisResult?.calculatedData?.rec4 || 0) || 0

            return <MDBox>
              <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
                <Grid item md={12} lg={12} xl={12} sm={12} mb={2}>
                  <MDTypography variant="h6" color="textPrimary">Review Plan Item Info</MDTypography>
                </Grid>
                <Grid item md={12} lg={12} xl={12} sm={12} mb={2}>
                  <div>FBA SKU: {showDetailItem.data?.translatedData?.sku}</div>
                  <div>FBA ASIN: {showDetailItem.data?.translatedData?.asin}</div>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Metric</TableCell>
                        <TableCell>7 Days</TableCell>
                        <TableCell>30 Days</TableCell>
                        <TableCell>60 Days</TableCell>
                        <TableCell>90 Days</TableCell>
                        <TableCell>DSupply</TableCell>
                        <TableCell>Invt Age</TableCell>
                        <TableCell>Available</TableCell>
                        <TableCell>Incoming</TableCell>
                        <TableCell>P.Removal</TableCell>
                        <TableCell>Units/case</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell><span className="font-bold">Sales Values</span></TableCell>
                        <TableCell>{showDetailItem.data?.translatedData?.unitsShippedLast7Days}</TableCell>
                        <TableCell>{showDetailItem.data?.translatedData?.unitsShippedLast30Days}</TableCell>
                        <TableCell>{showDetailItem.data?.translatedData?.unitsShippedLast60Days}</TableCell>
                        <TableCell>{showDetailItem.data?.translatedData?.unitsShippedLast90Days}</TableCell>
                        <TableCell>{showDetailItem.data?.translatedData?.daysOfSupply}</TableCell>
                        <TableCell>{Math.max(showDetailItem.data?.translatedData?.inventoryAge1, showDetailItem.data?.translatedData?.inventoryAge2, showDetailItem.data?.translatedData?.inventoryAge3, showDetailItem.data?.translatedData?.inventoryAge4)}</TableCell>
                        <TableCell>{showDetailItem.data?.translatedData?.existing}</TableCell>
                        <TableCell>{showDetailItem.data?.translatedData?.incoming}</TableCell>
                        <TableCell>{showDetailItem.data?.translatedData?.pendingRemoval}</TableCell>
                        <TableCell>{showDetailItem.item?.qtyPerUnit}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <span className="font-bold">Rec Formula</span>
                        </TableCell>
                        <TableCell>{ formatter.numberToCommaString(rec1) }</TableCell>
                        <TableCell>{ formatter.numberToCommaString(rec2) }</TableCell>
                        <TableCell>{ formatter.numberToCommaString(rec3) }</TableCell>
                        <TableCell>{ formatter.numberToCommaString(rec4) }</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <span className="font-bold">% Change</span>
                        </TableCell>
                        <TableCell>{ Math.round(showDetailItem.analysisResult?.calculatedData?.rec2Change * 100 * 100) / 100 }%</TableCell>
                        <TableCell>{ Math.round(showDetailItem.analysisResult?.calculatedData?.rec3Change * 100 * 100) / 100 }%</TableCell>
                        <TableCell>{ Math.round(showDetailItem.analysisResult?.calculatedData?.rec4Change * 100 * 100) / 100 }%</TableCell>
                        <TableCell>-</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
                <Grid item md={12} lg={12} xl={12} sm={12} mb={2}>
                  {
                    showDetailItem.analysisResult.changes300Percent && <div>
                      <div className="font-bold">How it was calculated: </div>
                      <div>Rec{showDetailItem.analysisResult.changes300PercentIndex} {'>'} 2 x Rec{showDetailItem.analysisResult.changes300PercentIndex - 1}. Use Rec{showDetailItem.analysisResult.changes300PercentIndex} as recommendation value.</div>
                    </div>
                  }
                  {
                    showDetailItem.analysisResult.changesIncreaseResonable && <div>
                      <div className="font-bold">How it was calculated: </div>
                      <div>Recommendation Value = {Math.round(showDetailItem.analysisResult.rawUsedRecValue)} x (1 {showDetailItem.analysisResult.changeRate > 0 ? '+' : '-'} {Math.round(Math.abs(showDetailItem.analysisResult.changeRate) * 100 * 100) / 100}%) - {showDetailItem.data?.translatedData?.existing} - {showDetailItem.data?.translatedData?.incoming}</div>
                    </div>
                  }
                  {
                    showDetailItem.analysisResult.changesDecreaseResonable && <div>
                      <div className="font-bold">How it was calculated: </div>
                      <div>Recommendation Value = {Math.round(showDetailItem.analysisResult.rawUsedRecValue)} x (1 {showDetailItem.analysisResult.changeRate > 0 ? '+' : '-'} {Math.round(Math.abs(showDetailItem.analysisResult.changeRate) * 100 * 100) / 100}%)  - {showDetailItem.data?.translatedData?.existing} - {showDetailItem.data?.translatedData?.incoming}</div>
                    </div>
                  }
                  {
                    showDetailItem.analysisResult.changesNotSignificant && <div>
                      <div className="font-bold">How it was calculated: </div>
                      <div>Movements were stable, Recommendation value = {Math.round(showDetailItem.analysisResult.rawUsedRecValue)}  - {showDetailItem.data?.translatedData?.existing} - {showDetailItem.data?.translatedData?.incoming}</div>
                    </div>
                  }
                  
                  {/* {showDetailItem.analysisResult.issues.highVariance && <div style={{marginTop: '20px'}}><strong>Check High Variance Thresholds</strong></div>}
                  {showDetailItem.analysisResult.issues.highVariance && showDetailItem.analysisResult.percentThreshold && <div style={{marginTop: '10px'}}>High Variance Threshold Rec 2: {Math.round(showDetailItem.analysisResult.percentThreshold * 100 * 100) / 100}%</div>}
                  {showDetailItem.analysisResult.issues.highVariance && showDetailItem.analysisResult.percentThreshold3 && <div style={{marginTop: '10px'}}>High Variance Threshold Rec 3: {Math.round(showDetailItem.analysisResult.percentThreshold3 * 100 * 100) / 100}%</div>}
                  {showDetailItem.analysisResult.issues.highVariance && showDetailItem.analysisResult.percentThreshold4 && <div style={{marginTop: '10px'}}>High Variance Threshold Rec 4: {Math.round(showDetailItem.analysisResult.percentThreshold4 * 100 * 100) / 100}%</div>} */}
                  
                  {(showDetailItem.analysisResult.issues.highSalesWarning || showDetailItem.analysisResult.issues.lowSalesWarning) && <div style={{marginTop: '20px'}}><strong>Check High Trend Threshold</strong></div>}
                  {(showDetailItem.analysisResult.issues.highSalesWarning || showDetailItem.analysisResult.issues.lowSalesWarning) && showDetailItem.analysisResult.salesWarningThreshold2 && <div style={{marginTop: '10px'}}>Sales Trend Warning Threshold Rec 2: {Math.round(showDetailItem.analysisResult.salesWarningThreshold2 * 100 * 100) / 100}%</div>}
                  {(showDetailItem.analysisResult.issues.highSalesWarning || showDetailItem.analysisResult.issues.lowSalesWarning) && showDetailItem.analysisResult.salesWarningThreshold3 && <div style={{marginTop: '10px'}}>Sales Trend Warning Threshold Rec 3: {Math.round(showDetailItem.analysisResult.salesWarningThreshold3 * 100 * 100) / 100}%</div>}
                  {(showDetailItem.analysisResult.issues.highSalesWarning || showDetailItem.analysisResult.issues.lowSalesWarning) && showDetailItem.analysisResult.salesWarningThreshold4 && <div style={{marginTop: '10px'}}>Sales Trend Warning Threshold Rec 4: {Math.round(showDetailItem.analysisResult.salesWarningThreshold4 * 100 * 100) / 100}%</div>}
                  
                  <div>Trend: {showDetailItem.analysisResult.trend}</div>

                  {/* <div>{JSON.stringify(showDetailItem)}</div> */}
                </Grid>
              </Grid>
            </MDBox>
            })()
          }
        />
      }
      {reviewPlanItemForm !== null && (
        <InventoryReportItemForm reviewPlan={report} data={reviewPlanItemForm} onClose={() => setReviewPlanItemForm(null)} onChange={() => {}} />
      )}
    </DialogContent>
  </Dialog>
}

export default InventoryReportDetailPage