// @ts-nocheck
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

// react-router-dom components

// @mui material components
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';


// Material Dashboard 2 React components
import MDTablePagination from "../../components/MDTablePagination";
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import MDTypography from "../../components/MDTypography";
import MDSnackbar from "../../components/MDSnackbar";
import MDActionMenu from "../../components/MDActionMenu";
import MDAlertDialog from "../../components/MDAlertDialog";

import { resetAll as resetAllReviewPlanItems, deleteById as deleteReviewPlanItemById, getAll as getAllReviewPlanItems } from "../../reducers/reviewPlanItem";
import { hasAccess } from "../../helpers/access";
import { formatDateTime, formatter } from "../../helpers";
import { FINAL_STATUSES } from "../../constants/reviewplan.constant";
import ItemDetail from "pages/Item/ItemDetail";
import ReviewPlanItemForm from "./ReviewPlanItemForm";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ReviewPlanItems({ reviewPlan, onClose, onChange }) {
  let dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const user = useSelector(state => state.auth.user)
  const data = useSelector(state => state.reviewPlanItem.list)

  const [openForm, setOpenForm] = useState(null)
  const [openDelete, setOpenDelete] = useState(null)
  const [viewItem, setViewItem] = useState(null)

  const { limit, currentPage } = data.paginator
  const getData = useCallback((page = currentPage, newLimit = limit) => {
    let params = {
      limit: newLimit,
      page,
    }
    setLoading(true)
    dispatch(getAllReviewPlanItems(reviewPlan.id, params))
    setLoading(false)
  }, [reviewPlan.id, limit, currentPage, dispatch])
  useEffect(() => {
    let params = {
      limit,
      page: 1,
    }
    setLoading(true)
    dispatch(getAllReviewPlanItems(reviewPlan.id, params))
    setLoading(false)
  }, [reviewPlan.id, limit, dispatch])
  useEffect(() => {
    dispatch(resetAllReviewPlanItems())
  }, [reviewPlan.id, dispatch])

  const handleChange = () => {
    getData()
    if (onChange) {
      onChange()
    }
  }
  const handleChangePaginator = (paginator) => {
    getData(paginator.currentPage, paginator.limit)
  }
  const handleClickUpdate = (data) => () => {
    setOpenForm(data)
  }
  const handleClickDelete = (data) => () => {
    setOpenDelete(data)
  }
  const handleSubmitDelete = () => {
    if (openDelete && openDelete.id) {
      return dispatch(deleteReviewPlanItemById(reviewPlan.id, openDelete.id))
    }
    return null
  }

  const handleClose = (e, reason = '') => {
    if (reason === 'escapeKeyDown' || reason === 'backdropClick') {
      return
    }
    onClose();
  };

  const isFinalReviewPlan = FINAL_STATUSES.includes(reviewPlan.status)

  return (
    <React.Fragment>
      <Dialog onClose={handleClose} open fullWidth fullScreen disableEscapeKeyDown TransitionComponent={Transition}>
        {errorMessage && (
          <MDSnackbar
            color="error"
            title={errorMessage}
            open
            close={() => setErrorMessage(null)}
          />
        )}
        <DialogTitle display="flex" justifyContent="space-between" alignItems="center">
          <MDTypography variant="h6" component="div">
            Review Plan #{reviewPlan.id} Items
          </MDTypography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <MDBox mt={1} component="form" role="form" autoComplete="off" noValidate>
            <MDTablePagination paginator={data.paginator} onChange={handleChangePaginator}>
              <Table stickyHeader sx={{ minWidth: 450 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <MDTypography variant="button" fontWeight="bold">Name</MDTypography>
                    </TableCell>
                    <TableCell>
                      <MDTypography variant="button" fontWeight="bold">Qty</MDTypography>
                    </TableCell>
                    <TableCell>
                      <MDTypography variant="button" fontWeight="bold">Price/unit</MDTypography>
                    </TableCell>
                    <TableCell>
                      <MDTypography variant="button" fontWeight="bold">Total Price</MDTypography>
                    </TableCell>
                    <TableCell>
                      <MDTypography variant="button" fontWeight="bold">Disc (%)</MDTypography>
                    </TableCell>
                    <TableCell>
                      <MDTypography variant="button" fontWeight="bold">Fixed Disc.</MDTypography>
                    </TableCell>
                    <TableCell>
                      <MDTypography variant="button" fontWeight="bold">Price after Disc</MDTypography>
                    </TableCell>
                    <TableCell>
                      <MDTypography variant="button" fontWeight="bold">Notes</MDTypography>
                    </TableCell>
                    {hasAccess(["reviewPlanItem.update", "reviewPlanItem.delete"], user) && !isFinalReviewPlan && (
                      <TableCell align="right" />
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.data.map(d => {
                    const totalPrice = d.quantity * d.pricePerUnit
                    let totalDisc = 0
                    if (d.discountPercentage) {
                      totalDisc = totalPrice * d.discountPercentage / 100
                    }

                    return (
                      <TableRow key={`data-reviewplan-item-${d.id}`} sx={{ verticalAlign: "top" }}>
                        <TableCell>
                          {d.item && (
                            <React.Fragment>
                              <div>
                                <MDTypography variant="body2">{d.item.name}</MDTypography>
                              </div>
                              <div>
                                <MDTypography variant="body2">{d.item.merchantSku}</MDTypography>
                              </div>
                              <div className="p0">
                                <MDButton variant="text" size="small" color="primary" onClick={() => setViewItem(d.item)}>
                                  View detail
                                </MDButton>
                              </div>
                            </React.Fragment>
                          )}
                        </TableCell>
                        <TableCell size="small" align="right">
                          <div>
                            <MDTypography variant="body2">{formatter.numberToCommaString(d.quantity)}</MDTypography>
                          </div>
                          <div style={{ lineHeight: 1, marginTop: 4 }}>
                            <MDTypography variant="caption">Expired:<br />{formatDateTime(d.expirationDate, true, false)}</MDTypography>
                          </div>
                        </TableCell>
                        <TableCell size="small" align="right">
                          <MDTypography variant="body2">{formatter.fullCurrency(d.pricePerUnit)}</MDTypography>
                        </TableCell>
                        <TableCell size="small" align="right">
                          <MDTypography variant="body2">{formatter.fullCurrency(totalPrice)}</MDTypography>
                        </TableCell>
                        <TableCell size="small" align="right">
                          {d.discountPercentage && (
                            <React.Fragment>
                              <div>
                                <MDTypography variant="body2">{formatter.numberToCommaString(d.discountPercentage)}%</MDTypography>
                              </div>
                              <div style={{ lineHeight: 1, marginTop: 4 }}>
                                <MDTypography variant="caption">{formatter.fullCurrency(totalPrice - totalDisc)}</MDTypography>
                              </div>
                            </React.Fragment>
                          )}
                        </TableCell>
                        <TableCell size="small" align="right">
                          {d.discount && (
                            <MDTypography variant="body2">{formatter.fullCurrency(d.discount)}</MDTypography>
                          )}
                        </TableCell>
                        <TableCell size="small" align="right">
                          <MDTypography variant="body2">{formatter.fullCurrency(totalPrice - totalDisc - d.discount)}</MDTypography>
                        </TableCell>
                        <TableCell>
                          <MDTypography variant="body2" sx={{ whiteSpace: 'pre' }}>{d.notes}</MDTypography>
                          <div style={{ lineHeight: 1, marginTop: 4 }}>
                            <MDTypography variant="caption">Last update:<br />{formatDateTime(d.updatedAt)}</MDTypography>
                          </div>
                        </TableCell>
                        {hasAccess(["reviewPlanItem.update", "reviewPlanItem.delete"], user) && !isFinalReviewPlan && (
                          <TableCell align="right" padding="checkbox" size="small">
                            <MDActionMenu
                              handleUpdate={hasAccess(["reviewPlanItem.update"], user) ? handleClickUpdate(d) : null}
                              handleDelete={hasAccess(["reviewPlanItem.delete"], user) ? handleClickDelete(d) : null}
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </MDTablePagination>
          </MDBox>
        </DialogContent>
        <DialogActions>
          {hasAccess(["reviewPlanItem.create"], user) && !isFinalReviewPlan && (
            <MDButton variant="gradient" color="primary" onClick={() => setOpenForm({})}>
              Add Item
            </MDButton>
          )}
          <MDButton variant="gradient" disabled={loading} color="secondary" onClick={handleClose}>
            Close
          </MDButton>
        </DialogActions>
      </Dialog>
      {openDelete && (
        <MDAlertDialog onClose={() => setOpenDelete(null)} title="Delete Item on Review Plan?" content={
          <>
            <MDBox>
              <MDTypography variant="h5">Item: {openDelete.item?.name || ""}</MDTypography>
              <MDTypography variant="body2">with price per unit is {formatter.fullCurrency(openDelete.pricePerUnit)} and expired at {formatDateTime(openDelete.expirationDate, true, false)}</MDTypography>
              <MDTypography variant="body2">will be deleted and review plan value will be re-calculated.</MDTypography>
            </MDBox>
          </>
        }
          onSubmit={handleSubmitDelete}
          submitText="Delete"
        />
      )}
      {viewItem && (
        <ItemDetail data={viewItem} onClose={() => setViewItem(null)} />
      )}
      {openForm !== null && (
        <ReviewPlanItemForm reviewPlan={reviewPlan} data={openForm} onClose={() => setOpenForm(null)} onChange={handleChange} />
      )}
    </React.Fragment>
  );
}

ReviewPlanItems.defaultProps = {
  reviewPlan: {
    id: null,
    userId: null,
    status: "",
  }
};

ReviewPlanItems.propTypes = {
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  reviewPlan: PropTypes.object.isRequired,
}

export default ReviewPlanItems