import MDAlertDialog from 'components/MDAlertDialog'
import MDBox from 'components/MDBox'
import TextField from "@mui/material/TextField";
import { checkOverflow } from 'helpers'
import React, { memo, useEffect, useRef, useState } from 'react'


const SeeMoreText = ({ text, onSubmit, editLabel = "Edit", maxWidth=undefined, prefix }) => {
  const ref = useRef(null)
  const [isOverflow, setIsOverFlow] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [value, setValue] = useState(text)

  useEffect(() => {
    if (showEditModal) {
      setValue(text)
    }
  }, [showEditModal, setValue, text])

  useEffect(() => {
    if (ref.current) {
      if (checkOverflow(ref.current)) {
        setIsOverFlow(true)
      }
    }
  }, [text, setIsOverFlow])

  return (
    <>
      <div
        ref={ref}
        className="text-area-text div-2-lines"
        style={{ maxWidth: maxWidth }}
      >
        <strong>{prefix ?? ''}</strong>{text}
      </div>
      <div className='flex gap-4'>
        {isOverflow && <div
          onClick={() => {
            setShowModal(true)
          }}
          className="f-sm color-primary cursor-pointer"
        >
          Read More
        </div>}
        {!!onSubmit && <div
          onClick={() => {
            setShowEditModal(true)
          }}
          className="f-sm color-primary cursor-pointer"
        >
          {editLabel}
        </div>}
      </div>
      {showModal && (
          <MDAlertDialog
          onClose={() => {
            setShowModal(false)
          }}
          maxWidth="lg"
          closeText="Close"
          content={
            <MDBox>
              <div className='text-area-text'>{text}</div>
            </MDBox>
          }
        />
      )}
      {showEditModal && (
          <MDAlertDialog
            onClose={() => {
              setShowEditModal(false)
            }}
            submitText="Save"
            onSubmit={() => onSubmit(value)}
            maxWidth="lg"
            closeText="Close"
            content={
              <MDBox>
                <TextField value={value} onChange={(e) => {
                  console.log(e.target.value)
                  setValue(e.target.value)
                }} rows={3} fullWidth multiline />
              </MDBox>
            }
          />
      )}
    </>
  )
}

export default memo(SeeMoreText)
