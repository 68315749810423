import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import MDInputTimeout from "../MDInputTimeout";
import MDBox from "../MDBox";
import MDAutocomplete from "../MDAutocomplete";
import { displayName } from '../../helpers';
import { resetDropdown, getAll as getAllUser, getByIdDropdown as getUserById } from '../../reducers/user';

function UserSelector({ label, name, onChange, value, roles, ...rest }) {
  let dispatch = useDispatch()

  const data = useSelector(state => state.user.dropdown)
  const [inputValue, setInputValue] = useState('')
  const [dataValue, setDataValue] = useState(null)

  useEffect(() => {
    async function fetchData() {
      try {
        const client = await getUserById(value)
        setDataValue(client)
      } catch (e) {
        setDataValue(null)
      }
    }
    if (value) fetchData();
  }, [value, dispatch])
  useEffect(() => {
    let params = {
      q: inputValue,
      status: "1",
      page: 1,
      limit: 100,
    }
    if (roles) {
      params = {
        ...params,
        roles: roles,
      }
    }
    dispatch(resetDropdown())
    dispatch(getAllUser(params, true))
  }, [inputValue, roles, dispatch]);

  const handleChange = (e, result) => {
    setDataValue(result)
    if (typeof onChange !== 'undefined') {
      onChange({
        target: {
          name,
          value: result?.id,
          data: result,
        }
      })
    }
  }

  let options = data.data
  if (dataValue && dataValue.id) {
    options = options.filter(o => o.id !== dataValue.id)
    options = [dataValue, ...options]
  }
  return (
    <MDAutocomplete
      options={options}
      filterOptions={(x) => x}
      autoComplete
      includeInputInList
      filterSelectedOptions
      autoHighlight
      getOptionLabel={(option) => displayName(option) + " - " + option.email}
      renderOption={(props, option) => (
        <MDBox component="li" {...props}>
          {displayName(option)} ({option.email})
        </MDBox>
      )}
      value={dataValue}
      onChange={handleChange}
      isOptionEqualToValue={(option, selected) => {
        return option.id === selected.id
      }}
      renderInput={(params) => (
        <MDInputTimeout
          {...params}
          label={label}
          name={name}
          fullWidth
          disabled={!!rest.disabled}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
      {...rest}
    />
  );
}

UserSelector.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  roles: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.number,
  onChange: PropTypes.func,
}

export default UserSelector