import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupIcon from '@mui/icons-material/Group';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import CategoryIcon from '@mui/icons-material/Category';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

import SignIn from "../pages/SignIn"
import SignUp from "../pages/SignUp"
import ForgotPassword from "../pages/ForgotPassword"

import Dashboard from "../pages/Dashboard"
import Profile from "../pages/Profile"
import Shipper from "../pages/Shipper"
import User from "../pages/User"
import Client from "../pages/Client"
import Receiver from "../pages/Receiver"
import Item from "../pages/Item"
import ReviewPlan from "../pages/ReviewPlan"
import PackingList from "../pages/PackingList"
import Invoice from "../pages/Invoice"
import { AnalyticsSharp, Report, Search } from '@mui/icons-material';
import SearchPage from 'pages/Search';
import InventoryReportPage from 'pages/InventoryReport';

export const UNAUTHENTICATED_ROUTE = {
  signIn: {
    type: "collapse",
    name: "signIn",
    key: "signIn",
    route: "/sign-in",
    component: <SignIn />,
  },
  forgotPassword: {
    type: "collapse",
    name: "signIn",
    key: "signIn",
    route: "/forgot-password",
    component: <ForgotPassword />,
  },
  setPassword: {
    type: "collapse",
    name: "signIn",
    key: "signIn",
    route: "/set-password",
    component: <ForgotPassword isSet />,
  },
  signUp: {
    type: "collapse",
    name: "signUp",
    key: "signUp",
    route: "/sign-up",
    component: <SignUp />,
  },
};

export const AUTHENTICATED_ROUTE = {
  dashboard: {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <DashboardIcon />,
    component: <Dashboard />,
    userRoleAbilityKeys: [],
  },
  clients: {
    type: "collapse",
    name: "Clients",
    key: "clients",
    route: "/clients",
    icon: <GroupIcon />,
    component: <Client />,
    userRoleAbilityKeys: ["client"], // look the key in at user.js
  },
  receivers: {
    type: "collapse",
    name: "Receivers",
    key: "receivers",
    route: "/receivers",
    icon: <ContactMailIcon />,
    component: <Receiver />,
    userRoleAbilityKeys: ["receiver"], // look the key in at user.js
    hidden: true,
  },
  items: {
    type: "collapse",
    name: "Items",
    key: "items",
    route: "/items",
    icon: <CategoryIcon />,
    component: <Item />,
    userRoleAbilityKeys: ["item"], // look the key in at user.js
    hidden: true,
  },
  search: {
    type: "collapse",
    name: "Search",
    key: "search",
    route: "/search",
    icon: <Search />,
    component: <SearchPage />,
    userRoleAbilityKeys: ["item"], // look the key in at user.js
  },
  // inventoryReport: {
  //   type: "collapse",
  //   name: "Review Plans",
  //   key: "inventoryReport",
  //   route: "/inventory-report",
  //   icon: <AnalyticsSharp />,
  //   component: <InventoryReportPage />,
  //   userRoleAbilityKeys: ["item"], // look the key in at user.js
  // },
  reviewPlans: {
    type: "collapse",
    name: "Review Plans",
    key: "review-plans",
    route: "/review-plans",
    icon: <AssignmentIcon />,
    component: <ReviewPlan />,
    userRoleAbilityKeys: ["reviewPlan"], // look the key in at user.js
    hidden: false,
  },
  packingLists: {
    type: "collapse",
    name: "Packing Lists",
    key: "packing-lists",
    route: "/packing-lists",
    icon: <Inventory2Icon />,
    component: <PackingList />,
    userRoleAbilityKeys: ["packingList"], // look the key in at user.js
    hidden: true,
  },
  invoices: {
    type: "collapse",
    name: "Invoices",
    key: "invoices",
    route: "/invoices",
    component: <Invoice />,
    icon: <ReceiptIcon />,
    userRoleAbilityKeys: ["invoice"], // look the key in at user.js
    hidden: true,
  },
  users: {
    type: "collapse",
    name: "Internal Users",
    key: "users",
    route: "/users",
    component: <User />,
    icon: <SupportAgentIcon />,
    userRoleAbilityKeys: ["user"], // look the key in at user.js
  },
};

export const AUTHENTICATED_OTHER_ROUTE = {
  profile: {
    type: "collapse",
    name: "Profile",
    key: "profile",
    route: "/profile",
    component: <Profile />,
    userRoleAbilityKeys: [],
  },
  shippers: {
    type: "collapse",
    name: "Shipper",
    key: "shippers",
    route: "/shippers",
    component: <Shipper />,
    userRoleAbilityKeys: ["shipper"],
  },
}