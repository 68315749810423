// @ts-nocheck
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import parsePhoneNumber from 'libphonenumber-js'

// react-router-dom components

// @mui material components
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import AddIcon from '@mui/icons-material/Add';

// Material Dashboard 2 React components
import MDTablePagination from "../../components/MDTablePagination";
import MDInputTimeout from "../../components/MDInputTimeout";
import MDBox from "../../components/MDBox";
import MDFab from "../../components/MDFab";
import MDTypography from "../../components/MDTypography";
import MDActionMenu from "../../components/MDActionMenu";
import MDAlertDialog from "../../components/MDAlertDialog";

import { deleteById as deleteReceiverById, getAll as getAllReceiver } from "../../reducers/receiver";
import { getCountryName, getStateName } from "../../helpers/country-state";
import { hasAccess } from "../../helpers/access";
import ReceiverForm from "./ReceiverForm";
import ClientSelector from "../../components/DataSelector/ClientSelect";
import { displayName } from "../../helpers";
import ClientName from "components/ClientName/ClientName";


function Receiver() {
  let dispatch = useDispatch()
  const user = useSelector(state => state.auth.user)
  const data = useSelector(state => state.receiver.list)

  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState("")
  const [userId, setUserId] = useState(null)
  const [openForm, setOpenForm] = useState(null)
  const [openDelete, setOpenDelete] = useState(null)

  const { limit, currentPage } = data.paginator
  const getData = useCallback((page = currentPage, newLimit = limit) => {
    let params = {
      q: search,
      limit: newLimit,
      page,
    }
    if (userId) {
      params = {
        ...params,
        userId
      }
    }
    setLoading(true)
    dispatch(getAllReceiver(params))
    setLoading(false)
  }, [search, limit, currentPage, userId, dispatch])
  useEffect(() => {
    let params = {
      q: search,
      limit,
      page: 1,
    }
    if (userId) {
      params = {
        ...params,
        userId
      }
    }
    setLoading(true)
    dispatch(getAllReceiver(params))
    setLoading(false)
  }, [search, limit, userId, dispatch])

  const handleCreate = () => {
    getData()
  }

  const handleChangePaginator = (paginator) => {
    getData(paginator.currentPage, paginator.limit)
  }
  const handleClickUpdate = (data) => () => {
    setOpenForm(data)
  }
  const handleClickDelete = (data) => () => {
    setOpenDelete(data)
  }
  const handleSubmitDelete = () => {
    if (openDelete && openDelete.id) {
      return dispatch(deleteReceiverById(openDelete.id))
    }
    return null
  }

  return (
    <MDBox p={2} bgColor="white">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
          <MDInputTimeout disabled={loading} value={search} onChange={(e) => setSearch(e.target.value)} label="Search" fullWidth />
        </Grid>
        {hasAccess(["receiver.setUser"], user) && (
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <ClientSelector disabled={loading} value={userId} onChange={(e) => setUserId(e.target.value)} label="Client" fullWidth />
          </Grid>
        )}
        <Grid item xs={12}>
          <MDTablePagination paginator={data.paginator} onChange={handleChangePaginator}>
            <Table stickyHeader sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  {hasAccess(["receiver.setUser"], user) && (
                    <TableCell>
                      <MDTypography variant="button" fontWeight="bold">Client</MDTypography>
                    </TableCell>
                  )}
                  <TableCell>
                    <MDTypography variant="button" fontWeight="bold">Name</MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="button" fontWeight="bold">Phone</MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="button" fontWeight="bold">Address</MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="button" fontWeight="bold">City</MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="button" fontWeight="bold">District</MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="button" fontWeight="bold">State</MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="button" fontWeight="bold">Country</MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="button" fontWeight="bold">Postal Code</MDTypography>
                  </TableCell>
                  {hasAccess(["receiver.update", "receiver.delete"], user) && (
                    <TableCell align="right" />
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.data.map(d => {
                  return (
                    <TableRow key={`data-${d.id}`}>
                      {hasAccess(["receiver.setUser"], user) && (
                        <TableCell>
                          {d.user ? (
                            <React.Fragment>
                              <ClientName client={d.user} showEmail />
                            </React.Fragment>
                          ) : "-"}
                        </TableCell>
                      )}
                      <TableCell>
                        <MDTypography variant="body2">{d.name || ""}</MDTypography>
                      </TableCell>
                      <TableCell>
                        {d.phone && (
                          <MDTypography variant="body2">
                            {parsePhoneNumber("+" + d.phone).formatInternational()}
                          </MDTypography>
                        )}
                      </TableCell>
                      <TableCell>
                        <MDTypography variant="body2">{d.addressFieldOne || ""}</MDTypography>
                        <MDTypography variant="body2">{d.addressFieldTwo || ""}</MDTypography>
                      </TableCell>
                      <TableCell>
                        <MDTypography variant="body2">{d.city || ""}</MDTypography>
                      </TableCell>
                      <TableCell>
                        <MDTypography variant="body2">{d.district || ""}</MDTypography>
                      </TableCell>
                      <TableCell>
                        <MDTypography variant="body2">{getStateName(d.country, d.state) || ""}</MDTypography>
                      </TableCell>
                      <TableCell>
                        <MDTypography variant="body2">{getCountryName(d.country) || ""}</MDTypography>
                      </TableCell>
                      <TableCell>
                        <MDTypography variant="body2">{d.postalCode || ""}</MDTypography>
                      </TableCell>
                      {hasAccess(["receiver.update", "receiver.delete"], user) && (
                        <TableCell align="right" padding="checkbox" size="small">
                          <MDActionMenu
                            handleUpdate={hasAccess(["receiver.update"], user) ? handleClickUpdate(d) : null}
                            handleDelete={hasAccess(["receiver.delete"], user) ? handleClickDelete(d) : null}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </MDTablePagination>
          {hasAccess(["receiver.create"], user) && (
            <MDFab color="primary" onClick={() => setOpenForm({})}>
              <AddIcon />
            </MDFab>
          )}
          {openForm !== null && (
            <ReceiverForm data={openForm} onClose={() => setOpenForm(null)} onCreate={handleCreate} />
          )}
          {openDelete && (
            <MDAlertDialog onClose={() => setOpenDelete(null)} title="Delete Receiver?" content={
              <>
                <MDBox>
                  <MDTypography variant="h5">{openDelete.name || ""}</MDTypography>
                  <MDTypography variant="body2">will be deleted permanently. You need to reassign receiver to any active packing lists that use this.</MDTypography>
                </MDBox>
              </>
            }
              onSubmit={handleSubmitDelete}
              submitText="Delete"
            />
          )}
        </Grid>
      </Grid>
    </MDBox>
  );
}

Receiver.propTypes = {
}

export default Receiver