import MDAlertDialog from 'components/MDAlertDialog'
import MDBox from 'components/MDBox'
import TextField from "@mui/material/TextField";
import React, { memo, useEffect, useState } from 'react'
import MDButton from "../../components/MDButton";
import MDInput from 'components/MDInput';

const EditButton = ({ defaultValue, onSubmit, deleteLabel = 'Delete',  numberOnly = false }) => {
  const [showModal, setshowModal] = useState(false)

  return (
    <>
      <div className=''>
        {!!onSubmit && <MDButton variant="text" size="small" color="primary" onClick={() => {
          setshowModal(true)
        }}>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>{deleteLabel}</div>
        </MDButton>}
      </div>
      {showModal && (
          <MDAlertDialog
            onClose={() => {
              setshowModal(false)
            }}
            submitText="Delete"
            onSubmit={() => onSubmit(true)}
            maxWidth="sm"
            closeText="Cancel"
            content={
              <MDBox>
                <div>Are you sure to delete this item?</div>
              </MDBox>
            }
          />
      )}
    </>
  )
}

export default memo(EditButton)
