// @ts-nocheck
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// react-router-dom components

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import { USER_TO_DO_DASHBOARD } from "../../constants/user.constant";
import { hasTodoDashboardAccess } from "../../helpers/access";
import { STATUS as RP_STATUS } from "../../constants/reviewplan.constant";
import DashboardReviewPlan from "./DashboardReviewPlan";
import {getAll as getAllReviewPlan} from '../../reducers/reviewPlan'

function Dashboard() {
  const user = useSelector(state => state.auth.user)
  const dispatch = useDispatch()

  const getData = useCallback((status) => {
    const params = {
      limit: 5,
      page: 1,
      status: status,
      order: 'updatedAt'
    }
    dispatch(getAllReviewPlan(params, 'dashboard'))
  }, [dispatch])

  const onStatusChanged = () => {
    getData(RP_STATUS.new.value)
    getData(RP_STATUS.clientReview.value)
    getData(RP_STATUS.approved.value)
  }

  return (
    <React.Fragment>
      <Grid container spacing={1} justifyContent="start" alignItems="start" height="100%" sx={{ alignItems: 'stretch' }}>
        {Object.values(USER_TO_DO_DASHBOARD).map(d => {
          if (!hasTodoDashboardAccess(d, user)) {
            return null
          }
          return (
            <Grid item sm={12} md={6} lg={6} xl={6} key={`usertodo-${d.key}`}>
              <MDBox p={2} bgColor="white" height="100%">
                {d.value === USER_TO_DO_DASHBOARD.newRP.value && (
                  <DashboardReviewPlan onStatusUpdated={onStatusChanged} dashboardType={USER_TO_DO_DASHBOARD.newRP.value} status={RP_STATUS.new.value} />
                )}
                {d.value === USER_TO_DO_DASHBOARD.reviewRP.value && (
                  <DashboardReviewPlan onStatusUpdated={onStatusChanged} dashboardType={USER_TO_DO_DASHBOARD.reviewRP.value} status={RP_STATUS.clientReview.value} />
                )}
                {d.value === USER_TO_DO_DASHBOARD.approvedRP.value && (
                  <DashboardReviewPlan onStatusUpdated={onStatusChanged} dashboardType={USER_TO_DO_DASHBOARD.approvedRP.value} status={RP_STATUS.approved.value} />
                )}
                {/* {d.value === USER_TO_DO_DASHBOARD.reviewPL.value && (
                  <DashboardPackingList status={PL_STATUS.review.value} />
                )} */}
                {/* {d.value === USER_TO_DO_DASHBOARD.approvedPL.value && (
                  <DashboardPackingList status={PL_STATUS.approved.value} />
                )} */}
                {/* {d.value === USER_TO_DO_DASHBOARD.newInvoice.value && (
                  <DashboardInvoice dateMax={null} />
                )} */}
                {/* {d.value === USER_TO_DO_DASHBOARD.dueInvoice.value && (
                  <DashboardInvoice dateMax={moment().format("YYYY-MM-DD")} />
                )} */}
              </MDBox>
            </Grid>
          )
        })}
      </Grid>
    </React.Fragment>
  );
}

Dashboard.propTypes = {
}

export default Dashboard