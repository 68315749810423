// @ts-nocheck
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

// react-router-dom components

// @mui material components
import Grid from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Divider from "@mui/material/Divider";
import Chip from '@mui/material/Chip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UnpublishedIcon from '@mui/icons-material/Unpublished';

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import MDTypography from "../../components/MDTypography";

import { getById } from "../../reducers/item"
import { displayDimension, displayName, formatDateTime, formatter } from "../../helpers";
import { hasAccess } from "../../helpers/access";

function ItemDetail({ data, onClose }) {
  let dispatch = useDispatch()

  const user = useSelector(state => state.auth.user)
  const item = useSelector(state => state.item.detail) || data

  useEffect(() => {
    if (data.id) dispatch(getById(data.id))
  }, [data.id, dispatch])

  if (!item.id) return null
  return (
    <Dialog onClose={onClose} open fullWidth maxWidth="xs" disableEscapeKeyDown>
      <DialogTitle>Item: {item.name}</DialogTitle>
      <DialogContent>
        <MDBox mt={1}>
          <MDBox mb={2}>
            <div style={{ lineHeight: 1 }}>
              <MDTypography variant="caption">Name:</MDTypography>
            </div>
            <MDTypography variant="body2">{item.name}</MDTypography>
          </MDBox>
          <MDBox mb={2}>
            <div style={{ lineHeight: 1 }}>
              <MDTypography variant="caption">SKU:</MDTypography>
            </div>
            <MDTypography variant="body2">{item.merchantSku}</MDTypography>
          </MDBox>
          <Grid container spacing={1} justifyContent="center" alignItems="start" height="100%">
            <Grid item md={12} lg={6} xl={6} sm={12} mb={2}>
              <Chip
                size="small"
                color={item.isActive ? "success" : "error"}
                label={item.isActive ? "Active" : "Inactive"}
                icon={item.isActive ? <CheckCircleIcon /> : <UnpublishedIcon />}
              />
            </Grid>
            <Grid item md={12} lg={6} xl={6} sm={12} mb={2}>
              <div style={{ lineHeight: 1 }}>
                <MDTypography variant="caption">Weight:</MDTypography>
              </div>
              <MDTypography variant="body2">{formatter.numberToCommaString(item.weight)} gram</MDTypography>
            </Grid>
          </Grid>
          <Grid container spacing={1} justifyContent="center" alignItems="start" height="100%">
            <Grid item md={12} lg={6} xl={6} sm={12} mb={2}>
              <div style={{ lineHeight: 1 }}>
                <MDTypography variant="caption">Unit:</MDTypography>
              </div>
              <MDTypography variant="body2">{item.unit}</MDTypography>
            </Grid>
            <Grid item md={12} lg={6} xl={6} sm={12} mb={2}>
              <div style={{ lineHeight: 1 }}>
                <MDTypography variant="caption">Qty per unit:</MDTypography>
              </div>
              <MDTypography variant="body2">1 {item.unit} = {formatter.numberToCommaString(item.qtyPerUnit)}</MDTypography>
            </Grid>
          </Grid>
          <MDBox mb={2}>
            <div style={{ lineHeight: 1 }}>
              <MDTypography variant="caption">Dimension:</MDTypography>
            </div>
            {item.dimension && (
              <MDTypography variant="body2">{displayDimension(item.dimension)}</MDTypography>
            )}
          </MDBox>
          {item.price && (
            <MDBox mb={2}>
              <div style={{ lineHeight: 1 }}>
                <MDTypography variant="caption">Current price:</MDTypography>
              </div>
              <div>
                <MDTypography variant="body2">{formatter.fullCurrency(item.price.price)}</MDTypography>
              </div>
              <div style={{ lineHeight: 1 }}>
                <MDTypography variant="caption">Effective since</MDTypography>
              </div>
              <div style={{ lineHeight: 1 }}>
                <MDTypography variant="caption">{formatDateTime(item.price.effectiveDate, true, false)}</MDTypography>
              </div>
            </MDBox>
          )}
          {hasAccess(["item.setUser"], user) && item.user && (
            <React.Fragment>
              <Divider light={false} />
              <MDBox mb={2}>
                <div style={{ lineHeight: 1 }}>
                  <MDTypography variant="caption">Client:</MDTypography>
                </div>
                <MDTypography variant="body2">{displayName(item.user) || ""}</MDTypography>
              </MDBox>
            </React.Fragment>
          )}
        </MDBox>
      </DialogContent>
      <DialogActions>
        <MDButton variant="gradient" color="secondary" onClick={onClose}>
          Close
        </MDButton>
      </DialogActions>

    </Dialog>
  );
}

ItemDetail.defaultProps = {
  data: {
    id: null,
    userId: "",
    name: "",
    merchantSku: "",
    unit: "",
    qtyPerUnit: "",
    weight: "",
    dimension: {
      width: "",
      height: "",
      length: "",
    },
    isActive: "1",
  },
};

ItemDetail.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object,
}

export default ItemDetail